import React from "react";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Form from 'react-bootstrap/Form';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import aretsDarkLogo from "../../Images/aretsDarkLogo.png";
import adminImage from "../../Images/adminImage.webp";
import headIcon from "../../Images/headIcon.png";
import toggleIcon from "../../Images/toggleIcon.png";
import "../../Styles/header.css";
import { NavLink } from "react-router-dom";
// import authService from '../../services/authService';
import { connect } from "react-redux";
import get_userdetails from "../../redux/actions/getuserDetailsAction";

function UserHeader(props) {
  // const count = useSelector(state => state.userdetails);
  // console.log(count,'count');
  const [user, setUserdata] = useState({});
  useEffect(() => {
    // getuserdetails();
    get_userdetails();
  }, []);
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);

  const getuserdetails = async () => {
    setUserdata(props.userdata.user);
    console.log(props.userdata, "prfops");

    // const dert = await authService.getuserdetails();
    // if(dert.data.status === 'success'){
    //   setUserdata(dert.data.user)
    //   console.log(dert.data,'dert.data');
    // }
  };
  return (
    <Navbar className="d-flex" expand="lg shadow" sticky="top" bg="light">
      <Container fluid>
        <Row className="d-flex w-100">
          <Col
            lg={12}
            sm={12}
            md={6}
            xs={12}
            col={12}
            className="mb-1 datacenter"
          >
            <Row className="d-flex">
              <div className="d-flex justify-content-between">
                {/* <Col lg={1} sm={2}>
                  </Col> */}
                <Col lg={4} sm={2} className="text-lg-start ms-2">
                  <Button
                    as={NavLink}
                    to="/newuserordertracking"
                    variant="light"
                    className="p-0 m-0 rounded-4"
                  >
                    <img
                      src={aretsDarkLogo}
                      width="50px"
                      height="50px"
                      className="d-inline-block align-top"
                      alt="Arets logo"
                    />
                  </Button>
                </Col>
                {/* categories starts */}
                <Col lg={7} sm={10} className="text-center">
                  <Row>
                    <Col lg={3}></Col>
                    <Col lg={5}>
                      <Nav className="headNav">
                        <Nav.Link
                          className="mt-2 d-none d-md-block"
                          as={NavLink}
                          to="/newuserordertracking"
                        >
                          Order Tracking
                        </Nav.Link>
                        <Nav.Link
                          className="ms-4 mt-2 d-none d-md-block"
                          href="#action1"
                        >
                          FAQ'S
                        </Nav.Link>
                      </Nav>
                    </Col>
                    {/* <Col lg={1} sm={2}>
                  </Col> */}
                    <Col lg={3} xs={12} className="d-flex ms-2 mt-2">
                      {/* <img
                      src={adminImage}
                      width="50px"
                      height="50px"
                      className="me-1"
                      alt="Profile Picture"
                    />
                    <div className='d-block mt-3'>
                      <h3 className='fs-6 fw-bold'>{user ? user.username : null}</h3>
                      <h6 className='text-muted'>{user ? user.email !== undefined ? user.email : null : null}</h6>
                    </div> */}
                      {/* <Button
                        as={NavLink}
                        to="/"
                        className=" SubmitButton w-100 rounded-4"
                        variant="primary"
                        type="submit"
                        size="md"
                      >
                        Sign In
                      </Button> */}
                    </Col>
                  </Row>
                </Col>
                {/* <Col lg={1} sm={1} className="mt-2">
                  <Button as={NavLink} to="/" variant="outline">
                    {" "}
                    <img
                      src={headIcon}
                      className="headIcon d-inline-block align-top"
                      alt="Logout"
                    />
                  </Button>
                </Col> */}
                <Col lg={1} sm={1} className="mt-2 ms-3 d-lg-none">
                  <Button as={NavLink} to="/sidebar" variant="outline">
                    {" "}
                    <img
                      src={toggleIcon}
                      className="headIcon d-inline-block align-top"
                      alt="Menu"
                    />
                  </Button>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(UserHeader);
