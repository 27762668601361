import httpService from "./httpService";


export async function getsuppliers(obj) {
	
	const data = await httpService.post('/masterData/suppliers/getAll',obj);
	return data;
}
export async function addsuplier(obj){
	
	const data = await httpService.post('/masterData/suppliers/add',obj);
	return data;
}
export async function updatesupplier(obj) {
	const data = await httpService.post('/masterData/suppliers/update', obj);
	return data;
}
export async function deletesupplier(obj) {
	const data = await httpService.post('/masterData/suppliers/delete', obj);
	return data;
}

export default {
	getsuppliers,
    addsuplier,
	updatesupplier,
	deletesupplier
}