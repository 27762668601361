import React, { useEffect, useState } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import "../../Styles/JobCards.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import shareIcon from "../../Images/shareIcon.png";
import sortIcon from "../../Images/sortIcon.png";
import calenderIcon from "../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Form from "react-bootstrap/Form";
import ClientsService from "../../services/ClientsService";
import axios from "axios";
import Moment from "moment";
import eyeIcon from "../../Images/eyeIcon.svg";
import editIcon from "../../Images/editIcon.svg";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import MasterService from "../../services/MasterService";
import deleteIconRed from '../../Images/deleteIconRed.svg';
import { connect } from "react-redux";
import get_userdetails from "../../redux/actions/getuserDetailsAction";


const Clients = (props) => {
  const [user, setUserdata] = useState();
  useEffect(() => {
    getuserdetails();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    console.log(props.userdata.user, "props.userdata.user");
    setUserdata(props.userdata.user);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clientscount, setClientCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getclients(dett, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getclients(0, event.target.value);
  };
  let navigate = useNavigate();
  function ClientDetails() {
    navigate("/clientdetails");
  }

  const [editmodal, setEditModalShow] = React.useState(false);
  const [clients, setclients] = useState([]);
  useEffect(() => {
    getclients(0, 10);
  }, []);

  const getclients = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: { name: searchtext },
      };
      const dert = await ClientsService.getclients(obj);
      if (dert.data.status === "success") {
        console.log(dert.data, "dertvalue");
        setclients(dert.data.value);
        setClientCount(dert.data.count);
      }
    } catch (ex) {}
  };
  //SearchAPI
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  const searchChange = (value) => {
    setSearchText(value)
    // getestimates(0,10)
  }
 
  const serachclick = async () => {
    // getestimates(0, 10)
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
      else{
 
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: { name: trimmedSearchText },
    };
    const dert = await ClientsService.getclients(obj);
    if (dert.data.status === 'success') {
        console.log(dert.data.value, 'values');
        setclients(dert.data.value);
        setClientCount(dert.data.count);
        setSearch(true);
    }
    } catch (ex) {}
    }
  };
  const resetclick = () => {
    setSearchText("");
    setSearch(false);
    getclients(0, rowsPerPage);
  };
  

  //SearchAPI Close

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  // const handleDownloadExcel = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await axios.post(
  //       "/masterData/customers/jsontoexcel",
  //       { data: [] },
  //       { responseType: "blob" }
  //     );
  //     const excelBlob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(excelBlob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "Clients.xlsx";
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // searchkeys : searchtext 
        searchkeys: { name: searchtext },     
      };
      const response = await axios.post(
        "/masterData/customers/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Clients.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };


  //  Download Excel Close

  //edit integration
  const [edmill, setedmill] = useState({});
  const [role, setRole] = useState("");
  const [cname, setCname] = useState("");
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [phonecode, setPhoneCode] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  // const [address, setAddress] = useState('');
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  // const [pincode, setPincode] = useState('');
  const [gstNo, setGstNo] = useState("");
  // const [status, setStatus] = useState('');
  const [sbtndis, setSBtndis] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const clickedit = (k) => {
    // console.log(k,'editmill');
    setedmill(k);
    setCname(k.name);
    setFname(k.firmname);
    setEmail(k.email);
    setPhoneNo(k.phoneNo);
    setCity(k.city);
    setStates(k.state);
    setGstNo(k.gstNo);
    // setStatus(k.active)
    setEditModalShow(true);
  };

  const handlesubmit = async () => {
    if (!cname) {
      await setSBtndis(false);
      toast.error("Enter Firm Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    } 
     else if (!phoneno) {
      await setSBtndis(false);
      toast.error("Enter Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phoneno.length != 10) {
      await setSBtndis(false);
      toast.error("Invalid Mobile Number", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      //  } else if (!address) {
      //      await setSBtndis(false)
      //      toast.error('Enter Address', {
      //          position: toast.POSITION.BOTTOM_LEFT
      //      });
    } else if (!city) {
      await setSBtndis(false);
      toast.error("Enter Area Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      //  } else if (!states) {
      //      await setSBtndis(false)
      //      toast.error('Select State', {
      //          position: toast.POSITION.BOTTOM_LEFT
      //      });
      //  } else if (!pincode) {
      //      await setSBtndis(false)
      //      toast.error('Enter Pincode', {
      //          position: toast.POSITION.BOTTOM_LEFT
      //      });

      //  } else if (!gstNo) {
      //      await setSBtndis(false)
      //      toast.error('Enter GST', {
      //          position: toast.POSITION.BOTTOM_LEFT
      //      });
      // } else if (!status) {
      //     await setSBtndis(false)
      //     toast.error('Select Status', {
      //         position: toast.POSITION.BOTTOM_LEFT
      //     });
    }
    try {
      setisLoading(true);

      const obj = {
        _id: edmill._id,
        //  "_id": "63d0cc20726397094ade2012",
        customerNo: 10,
        firmname: fname,
        name: cname,
        city: city,
        phoneNo: phoneno,
        state: states,
        email: email,
        gstNo: gstNo,
        details: "Nothing to Update!",
        // "active": status,
        __v: 0,
      };
      const dort = await ClientsService.updateclients(obj);
      if (dort.data.status === 'success') {
        console.log(dort, 'dort');
        await setSBtndis(false);
        navigate("/clients");
        getclients(rowsPerPage, rowsPerPage);
        setEditModalShow(false);
        toast.success("Client Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    finally{
      setisLoading(false);
    }
  };

  const Delete = async (item) => {
        
    try {
      const obj = {
       "_id":item._id,
      }
      const dert = await ClientsService.deleteClients(obj);
      console.log(dert, "dert");
      if (dert.data.message) {
        toast.success("Client Deleted Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        console.log(dert.data.value, "values");
      
          
      }
      
    } catch (ex) {

    }
  }
    
  //edit integration close

  const handlePhone = (event) => {
    const { value } = event.target;
    setPhoneNo(value.replace(/[^0-9.*!,-]/g, ''));
    };
    
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Clients</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Clients
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  as={NavLink}
                  to="/createclients"
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create New Client
                </Button>
              </div>
            </div>

            <Row className="d-flex ">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row className="d-flex flex-column flex-lg-row justify-content-between">
                      <Col className="text-lg-start ">
                        <h6 className="datahead text-muted">Customers</h6>
                      </Col>
                      {/* categories starts */}
                      <Col className="px-4 pb-2">
                        <Row className="d-flex  justify-content-end ">
                          <Col lg={8} xs={12}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Firm Name"
                                />
                              </div>
                              <div className="col-lg-4">
                              {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    id='cancel'
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  
                                  >
                                    Search
                                  </Button>
                                ) : (
                                    
                                  <Button 
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          {/* <Col lg={2} sm={6}>
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-popover"
                            >
                              {(popupState) => (
                                <div>
                                  <Button
                                    id="sortfilter"
                                    className="sortFilter1 mt-3"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                  >
                                    <img
                                      className="menuIcon mx-1"
                                      src={filterIcon}
                                    />
                                    Filter
                                  </Button>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <h6>Filter</h6>
                                      <h6>Order Type</h6>
                                      <Form>
                                        {["checkbox"].map((type) => (
                                          <div
                                            key={`inline-${type}`}
                                            className="mb-3"
                                          >
                                            <Form.Check
                                              inline
                                              label="Home Delivery"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-1`}
                                            />
                                            <Form.Check
                                              inline
                                              label="Pickup"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-2`}
                                            />
                                          </div>
                                        ))}
                                      </Form>
                                      <hr className="text-muted"></hr>{" "}
                                      <hr className="text-muted"></hr>
                                      <div>
                                        <h6>Status</h6>
                                        <Form.Select aria-label="Default select example">
                                          <option>All</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                        </Form.Select>
                                        <h6>Client</h6>
                                        <Form.Select aria-label="Default select example">
                                          <option>All</option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                        </Form.Select>
                                        <Form>
                                          <Row>
                                            <Col>
                                              <Form.Label>From</Form.Label>
                                              <Form.Control
                                                className="filterFields"
                                                placeholder="0.00"
                                              />
                                            </Col>
                                            <Col>
                                              <Form.Label>To</Form.Label>
                                              <Form.Control placeholder="0.00" />
                                            </Col>
                                          </Row>
                                        </Form>
                                        <Button
                                          id="pillStyle"
                                          className="w-100 mt-3"
                                          variant="primary"
                                        >
                                          Filter
                                        </Button>{" "}
                                      </div>
                                    </Typography>
                                  </Popover>
                                </div>
                              )}
                            </PopupState>
                          </Col> */}
                          {/* date filter */}
                          {/* <Col lg={2} sm={6}>
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-popover"
                            >
                              {(popupState) => (
                                <div>
                                  <Button
                                    id="sortfilter"
                                    className="sortFilter2 mt-3"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                  >
                                    <img
                                      className="menuIcon mx-1 mb-1"
                                      src={calenderIcon}
                                    />
                                    Filter
                                  </Button>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <h6 className="mb-3">By Date</h6>
                                      <Form>
                                        {["checkbox"].map((type) => (
                                          <div
                                            key={`inline-${type}`}
                                            className="mb-3"
                                          >
                                            <Form.Check
                                              inline
                                              label="This Week"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-1`}
                                            />
                                            <Form.Check
                                              inline
                                              label="Last Week"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-2`}
                                            />
                                          </div>
                                        ))}
                                      </Form>
                                      <Form>
                                        {["checkbox"].map((type) => (
                                          <div
                                            key={`inline-${type}`}
                                            className="mb-3"
                                          >
                                            <Form.Check
                                              inline
                                              label="This Month"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-1`}
                                            />
                                            <Form.Check
                                              inline
                                              label="Last Month"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-2`}
                                            />
                                          </div>
                                        ))}
                                      </Form>
                                      <Form>
                                        {["checkbox"].map((type) => (
                                          <div
                                            key={`inline-${type}`}
                                            className="mb-3"
                                          >
                                            <Form.Check
                                              inline
                                              label="This Year"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-1`}
                                            />
                                            &nbsp;
                                            <Form.Check
                                              inline
                                              label="Last Year"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-2`}
                                            />
                                          </div>
                                        ))}
                                      </Form>
                                      <hr className="text-muted"></hr>
                                      <Form>
                                        {["checkbox"].map((type) => (
                                          <div
                                            key={`inline-${type}`}
                                            className="mb-3"
                                          >
                                            <Form.Check
                                              inline
                                              label="Date Range"
                                              name="group1"
                                              type={type}
                                              id={`inline-${type}-1`}
                                            />
                                          </div>
                                        ))}
                                      </Form>
                                      <Nav
                                        id="navPill"
                                        variant="pills"
                                        defaultActiveKey="/home"
                                      >
                                        <Nav.Item>
                                          <Nav.Link id="pillStyle" href="/home">
                                            From
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link
                                            id="pillStyle"
                                            eventKey="link-1"
                                          >
                                            To
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                      <Col lg={2} sm={2} md={2}>
                                        <DateRange
                                          editableDateInputs={true}
                                          onChange={(item) =>
                                            setState([item.selection])
                                          }
                                          moveRangeOnFirstSelection={false}
                                          ranges={state}
                                        />
                                      </Col>
                                      <Button
                                        className="w-100 mt-3"
                                        id="pillStyle"
                                        variant="primary"
                                      >
                                        Filter
                                      </Button>{" "}
                                    </Typography>
                                  </Popover>
                                </div>
                              )}
                            </PopupState>
                          </Col> */}
                          {/* Export Excel */}
                          <Col lg={4} className="pe-1">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">Delete</option>
                                                            <option value="2">Cancel</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Firm Name</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Phone</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Client Since</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Status</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {clients && clients.length > 0
                          ? clients.map((k, index) => {
                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.name}</td>
                                  <td>{k.phoneNo}</td>
                                  <td>
                                    {Moment(k.updatedDate).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                  {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                  {/* <td onClick={() => ClientDetails()}>
                                    <img
                                      className="menuIcon mx-1"
                                      src={eyeIcon}
                                    />
                                    view
                                  </td> */}
                                  <td onClick={() => clickedit(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)} className="hand-cursor">
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                      
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={clientscount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={editmodal}
        onHide={() => setEditModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Client{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4 col-lg-12">
            <CardContent>
              <div className="row ">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="row">
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        <TextField
                          className="w-100"
                          value={cname}
                          onChange={(e) => setCname(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="Firm Name*"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        <TextField
                          className="w-100"
                          value={fname}
                          onChange={(e) => setFname(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="Client Name"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input-group mb-3">
                            <TextField
                              className="w-100"
                              value={phoneno}
                              onChange={(e) => handlePhone(e)}
                              id="inputcolor"
                              inputProps={{
                                maxLength: 10,
                              }}
                              type="text"
                              label="Phone*"
                              variant="outlined"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control text-muted required"
                                                    name="name"
                                                    value={address}
                                                    id='inputcolor'
                                                    placeholder="Address"
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div> */}

                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        <TextField
                          className="w-100"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="Area*"
                          variant="outlined"
                        />
                      </div>
                    </div>

                    {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control text-muted required"
                                                    name="name"
                                                    value={pincode}
                                                    id='inputcolor'
                                                    onChange={(e) => setPincode(e.target.value)}
                                                    placeholder="Pin Code"
                                                    maxLength={6}
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div> */}
                    <div className="form-group col-lg-12 col-md-12 col-12">
                      <div className="input-group mb-3">
                        {/* <input
                                                    type="text"
                                                    className="form-control text-muted required"
                                                    name="name"
                                                    value={gstNo}
                                                    id='inputcolor'
                                                    onChange={(e) => setGstNo(e.target.value)}
                                                    placeholder="GST Number"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                /> */}
                        <TextField
                          className="w-100"
                          value={gstNo}
                          onChange={(e) => setGstNo(e.target.value)}
                          id="inputcolor"
                          type="text"
                          label="GST Number"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                <option selected hidden>Status*</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>In Active</option>

                                            </select>
                                        </div>
                                    </div> */}
                  <div className="row mt-3 d-lg-flex">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => setEditModalShow(false)}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                    {!isLoading ? (
                      <Button
                        onClick={() => handlesubmit()}
                        id="pillStyle"
                        className="w-100"
                        variant="primary"
                      >
                        Update Client
                      </Button>
                        ) : (
                          <div className="loader mt-2" style={{marginLeft : '90px'}}></div>
                        )}
                    </div>
                  </div>
                </div>

                {/* Second Column */}

                {/* <div className="form-group col-lg-6 col-md-6 col-12">
                                        <div className="row"> */}

                {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={paymenttype} onChange={(e) => setPaymentType(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Payment Type</option>
                                                        <option value="1">Cash</option>
                                                        <option value="1">UPI</option>
                                                        <option value="2">Credit Card</option>
                                                        <option value="3">Debit Card</option>
                                                    </select>
                                                </div>
                                            </div> */}

                {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="email"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={bankname}
                                                        id='inputcolor'
                                                        onChange={(e) => setBankName(e.target.value)}
                                                        placeholder="Bank Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={accountnumber}
                                                        id='inputcolor'
                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                        placeholder="Account Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={IFSCcode}
                                                        id='inputcolor'
                                                        onChange={(e) => setIFSC(e.target.value)}
                                                        placeholder="IFSC Code"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={branchname}
                                                        id='inputcolor'
                                                        onChange={(e) => setBranchName(e.target.value)}
                                                        placeholder="Branch Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={pan}
                                                        id='inputcolor'
                                                        onChange={(e) => setPanNumber(e.target.value)}
                                                        placeholder="PAN Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                {/* </div>
                                    </div> */}
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(Clients);
