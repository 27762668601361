import React, { useState } from "react";
import "../../../Styles/Orders.css";
import Header from "../../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../../Sidenav/Sidebar";
import aretsLogo from "../../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import authService from "../../../services/authService";
import uploadCloudImage from "../../../Images/uploadCloudImage.png";
import UploadImageIcon from "../../../Images/UploadImageIcon.png";
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import showPwdImg from "../../../Images/show-password.svg";
import hidePwdImg from "../../../Images/hide-password.svg";
import validator from "validator";
import ModalSubmit from "../../modalSubmit";

export default function CreateNewUser() {
  const [modalShow, setModalShow] = React.useState(false);
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [role, setRole] = useState("");
  const [admin, setAdmin] = useState('');
  // const [sname, setSname] = useState('');
  const [usname, setusname] = useState("");
  const [order, setOrder] = useState("");
  const [password, setPassword] = useState("");
  const [reEnterpassword, setReEnterPassword] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [email, setEmail] = useState("");
  const [phonecode, setPhonecode] = useState("");
  const [phone, setPhone] = useState("");
  const [access, setAccess] = useState([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [sbtndis, setSBtndis] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  // const [status, setStatus] = useState('');

  // const [passvis, setpass] = useState(true)
  // const handleChangepass = async (e) => {
  //     await setPassword(e.target.value)
  //     await setReEnterPassword(e.target.value)
  // }

  let navigate = useNavigate();

  const create = async () => {
    if (!password != reEnterpassword) {
      await setSBtndis(false);
      toast.error("Password Do Not Match", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (phoneno.length != 10) {
      await setSBtndis(false);
      toast.error("Mobile Number Invalid", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    try {
      setIsLoading(true);
      const obj = {
        // "name": sname,
        username: usname,
        password: password,
        phoneNo: phoneno,
        role: role,
        admin: true,
        access: access,
      };
      console.log(obj, "obj");
      const dert = await authService.addusers(obj);
      console.log(dert.data, "dert.data");
      if (dert.data.status === "success") {
        toast.success("User Added Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/userManagement");
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }finally{
      setIsLoading(false);
    }
  };

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const handleaccess = (value) => {
    if (access.includes(value)) {
      const index = access.indexOf(value);
      if (index > -1) {
        access.splice(index, 1);
      }
    } else {
      access.push(value);
    }
  };

  function modalreset() {
    setusname("");
    setPassword("");
    setPhoneno("");
    setRole("");
    navigate("/userManagement");
    setModalShow(false);
  }

  const handlePhone = (event) => {
    const { value } = event.target;
    setPhoneno(value.replace(/[^0-9.*!,-]/g, ''));
    };

  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={6} className="p-3">
            <div>
              <h1 className="fs-3  mt-3">Create New User</h1>
              <Breadcrumb className=" fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/userManagement">
                  User Management{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Create New User</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4  pe-2">
              <CardContent>
                <div className="row">
                  <div className="form-group col-lg-6 col-md-6 col-12">
                    <div className="row">
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group">
                                                    <select value={role} onChange={(e) => setRole(e.target.value)} class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                                    <option selected hidden>Select User Role*</option>
                                                        <option value="Job Card">Job Card</option>
                                                        <option value="Cash Receipts">Cash Receipts</option>
                                                        <option value="3Material Indent">Material Indent</option>
                                                        <option value="Order Booking">Order Booking</option>
                                                        <option value="Master Data">Master Data</option>
                                                        <option value="Report">Report</option>
                                                    </select>
                                                </div>
                                            </div> */}
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={order} onChange={(e) => setOrder(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Select Orders*</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div> */}
                      {/* <div className="form-group col-lg-12 col-md-12 col-12 mb-3">
                                                <div className="input-group">
                                                    <input
                                                        type="Email"
                                                        className="form-control py-2 text-muted required"
                                                        name="name"
                                                        // value={email}
                                                        onChange={(e) => validateEmail(e)}
                                                        id='inputcolor'
                                                        placeholder="Email*"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                                <span style={{
                                                    fontSize: '10px',
                                                    color: 'red',
                                                }}>{emailError}</span>
                                            </div> */}
                      <div>
                        <h6 className="ms-1 mt-2 fs-14 fw-bold">User Name</h6>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control py-2 text-muted required"
                            name="text"
                            value={usname}
                            onChange={(e) => setusname(e.target.value)}
                            minLength={6}
                            maxLength={24}
                            id="inputcolor"
                            placeholder="User Name*"
                            aria-label="text"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <h6 className="ms-1 fs-14 fw-bold">Role</h6>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            class="form-select py-2 text-muted"
                            id="inputcolor"
                            aria-label="Default select example"
                          >
                            <option selected hidden>
                              Role*
                            </option>
                            <option value="ADMIN">Admin</option>
                            <option value="USER">User</option>
                          </select>
                        </div>
                      </div>
                      <h6 className="ms-1 fs-14 fw-bold">Mobile Number</h6>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-group">
                              <input
                                type="tel"
                                className="form-control py-2 required"
                                name="phone"
                                value={phoneno}
                                onChange={(e) => handlePhone(e)}
                                id="inputcolor"
                                maxLength={10}
                                placeholder="Mobile Number*"
                                aria-label="phone"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control py-2 text-muted required"
                                                        name="name"
                                                        value={sname}
                                                        onChange={(e) => setSname(e.target.value)}
                                                        id='inputcolor'
                                                        placeholder="Name*"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                      <h6 className="ms-1 mt-2 fs-14 fw-bold">Password</h6>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          {/* <input
                                                        type="password"
                                                        className="form-control py-2 required"
                                                        name="name"
                                                        value={password}
                                                        id='inputcolor'
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder="Create Password*"
                                                        aria-label="password"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    /> */}
                          <div className="col-lg-12">
                            <div
                              className="input-group form-group  pwd-container "
                              id="pwdWidth"
                            >
                              <input
                                name="pwd"
                                className="border-0 w-100 py-2 required"
                                id="inputcolor"
                                placeholder="Enter Password*"
                                type={isRevealPwd ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                minLength={6}
                                maxLength={24}
                              />
                              <img
                                title={
                                  isRevealPwd
                                    ? "Hide password"
                                    : "Show password"
                                }
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() =>
                                  setIsRevealPwd((prevState) => !prevState)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group">
                                                <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>In Active</option>
                                                    </select>
                                                </div>
                                            </div> */}
                    </div>
                  </div>

                  {/* second row */}

                  <div className="form-group col-lg-6 col-md-6 col-12">
                    <div className="row px-2 px-lg-0">
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Master Access
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("MD")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Admin Access
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("AA")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Order Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("OB")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Job Card Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("JC")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Indent Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("MI")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Printing Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("PM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Status Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("SM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group col-lg-12 col-md-12 col-12 mb-3" id='inputcolor'>
                                                <div className="row">
                                                    <div className="col-lg-9">
                                                        <div className="input-group">
                                                            <label class="form-check-label mt-1 mb-2" for="flexSwitchCheckDefault">Client Management</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input-group ">
                                                            <div class="form-check form-switch mt-2 mb-2" >
                                                                <input onChange={(e) => handleaccess('CM')} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                User Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("UM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Delivery Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("DM")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Receipt Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("CR")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="form-group col-lg-12 col-md-12 col-12 mb-3"
                        id="inputcolor"
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="input-group">
                              <label
                                class="form-check-label mt-1 mb-2"
                                for="flexSwitchCheckDefault"
                              >
                                Reports Management
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group ">
                              <div class="form-check form-switch mt-2 mb-2">
                                <input
                                  onChange={(e) => handleaccess("RT")}
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12 mb-3">
                        {/* <ModalSubmit
                          as={NavLink}
                          to="/userManagement"
                          submitOnclick={() => create()}
                          submitText={"Create User"}
                        /> */}
                         <div className="row mt-3">
                    <div className="col-lg-6">
                       <Button
                          as={NavLink}
                          to="/Clients"
                          id="pillStyle"
                          className="w-100"
                          variant="outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="col-lg-6">
                          {!isLoading ? (
                              <Button
                                onClick={() => create()}
                                id="pillStyle"
                                className="w-100"
                                variant="primary"
                              >
                                Create New User
                              </Button>
                               ) : (
                                <div className="loader mt-2" style={{marginLeft : '90px'}}></div>
                              )}
                              </div></div>
                      </div>
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
