import React,{useState} from 'react';
import '../../Styles/Orders.css'
import UserHeader from '../header/userHeader';
import Container from 'react-bootstrap/Container';
import Sidebar from '../Sidenav/Sidebar';
import aretsLogo from '../../Images/aretsLogo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import QrCode from '../../Images/QrCode.png'
import Button from 'react-bootstrap/Button';
import { NavLink,useLocation } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import estimateService from '../../services/estimateService';
import { useEffect } from 'react';
import Moment from "moment";


export default function UserOrderTracking() {
  const location = useLocation();
  const [ordData,setOrdData] = useState([]);
  // const [orderId,setOrderId] = useState(location.state?.item ? location.state?.item.data.orderId : "");
  const [orderId,setOrderId] = useState("");
  const [ordTrackdata,setOrdTrackdata] = useState([]);
  const steps = [
    'Order Confirmed Tue,17th Jan 23',
    'Job Card Created',
    'Printing',
    'Post Printing',
    'Packing',
    'Out for Delivery',
    'Delivered',
  ];
  useEffect(() => {
    getorddetails();
  },[])
  const getorddetails = async() => {
    try {
      const obj ={
        "orderId": location.state.item.value[0].orderId
      }
      const drtu = await estimateService.getOrdertracking(obj)
      if(drtu.data.status === 'success'){
        console.log(drtu.data,'drtu.data');
        setOrdData(drtu.data.orderDetails);
        setOrdTrackdata(drtu.data.orderTrackDetail);
      }
    } catch (ex) {
      
    }
  }
  console.log(location.state.item,'location.state.item');
  console.log(ordData, 'orderdata');
  return (
    <>
      <UserHeader />
      <Container fluid className='containerr'>
        <Row>
        <Col lg={1} id="sidebar-wrapper">
          </Col>
          <Col lg={6} className="p-3">
            <div>
              <h1 className='fs-3 mt-3 mb-5'>Order Number:<span className='orderNumColor'> {location.state.item.value[0].orderId}</span></h1>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4">
              <CardContent>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="row mt-3">
                      <div className="col-lg-8 text-muted fw-semibold">
                        <small>Firm Name</small>
                      </div>
                      <div className="col-lg-4 fw-semibold fs-14">
                      {location.state.item.value[0].customerName}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-8 text-muted fw-semibold">
                        <small>Address</small>
                      </div>
                      <div className="col-lg-4 fw-semibold fs-14">
                      {location.state.item.value[0].estimateId.address}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-8 text-muted fw-semibold">
                        <small>Phone</small>
                      </div>
                      <div className="col-lg-4 fw-semibold fs-14">
                        +91 {location.state.item.value[0].mobile}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-8 text-muted fw-semibold">
                        <small>Email</small>
                      </div>
                      <div className="col-lg-4 fw-semibold fs-14">
                      {location.state.item.value[0].email}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-8 text-muted fw-semibold">
                        <small>GST</small>
                      </div>
                      <div className="col-lg-4 fw-semibold fs-14">
                      {location.state.item.value[0].gstNo}
                      </div>
                    </div><hr></hr>
                    <div className="row mt-2">
                      <div className='col-lg-6 d-flex'>
                        <div className='col-lg-6 text-muted fw-semibold'><small>Job Name</small></div>
                        <div className='col-lg-6 fw-semibold'>{location.state.item.value[0].name}</div>
                      </div>
                      <div className='col-lg-6 d-flex'>
                        <div className='col-lg-9 text-muted fw-semibold'><small>Quantity</small></div>
                        <div className='col-lg-3 fw-semibold'>{location.state.item.value[0].quantity}</div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className='col-lg-6 d-flex'>
                        <div className='col-lg-6 text-muted fw-semibold'><small>Quote By</small></div>
                        <div className='col-lg-6 fw-semibold'>{location.state.item.value[0].firmname}</div>
                      </div>
                      <div className='col-lg-6 d-flex'>
                        <div className='col-lg-9 text-muted fw-semibold'><small>Sub Total</small></div>
                        <div className='col-lg-3 fw-semibold'> {location.state.item.value[0].sum_total}</div>
                      </div>
                    </div>
                    <hr></hr>
                   <div className='fs-13'> <h5>Orders Description</h5>
                   {location.state.item.value[0].jobDetails}
                    <p><span style={{ fontWeight: 700 }}>Print Type:</span> {location.state.item.value[0].printing}</p> 
                    <p><span style={{ fontWeight: 700 }}>Delivery Address:</span> {location.state.item.value[0].estimateId.address}</p></div>
                    <div className="row mt-2 mb-3">
                      <div className="col-lg-7 text-dark">
                        <h5>Order Status</h5>
                      </div>
                      <div className="col-lg-5 text-dark fw-semibold">
                        <p><span className='text-muted fs-13'>Order Created Date: </span> {Moment(location.state.item?.createDate).format(
                        "DD MMM YYYY"
                      )}</p>
                      </div>
                    </div>
                    <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      {/* <Stepper activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper> */}
                      {ordTrackdata ? ordTrackdata.length > 0 && ordTrackdata.length > 0 ?<Stepper activeStep={ordTrackdata.length} alternativeLabel>{ordTrackdata[0].trackingArr && ordTrackdata[0].trackingArr.length > 0 ? ordTrackdata[0].trackingArr.map((label) => (<Step><StepLabel>{label.status}</StepLabel></Step>)) : null}</Stepper> : null : null}
                    </div>
                    </div>
                      <Col lg={12} md={12} xs={12} className='d-flex'>
                        <Col lg={4} md={4} xs={4}>
                          <div className='d-flex mt-5'>
                            <img
                              src={aretsLogo}
                              alt='branding'
                              className='loginPage-brandImg-small'
                              onClick={() => window.location.reload()}
                            />
                            <h3 style={{ fontFamily: 'Yeseva One', fontWeight: '500', fontSize: '18px' }}>AReTS Graphics
                              <p style={{ fontFamily: 'Clash Display', fontSize: '12px', fontWeight: '300' }}>Everything in Printing</p>
                            </h3>
                          </div>
                        </Col>
                        <Col lg={6} md={3} xs={5} className='mt-3 ml-2'>
                          <h6 style={{fontSize: '8px'}} className='fw-bold'>Instructions for Tracking Your Order Using a QR Code:</h6>
                          <ol className='liData fw-bolder p-0'>
                            <li>Open the QR code scanning app on your smartphone or tablet.</li>
                            <li>Point your device's camera at the QR code on your order confirmation or shipping label.</li>
                            <li>Once the QR code is scanned, your device will automatically open the order tracking website or page.</li>
                            <li>You will be prompted to enter your order number and email address to view the status of your order.</li>
                            <li>You can also check the status of your order by clicking on the "Track Order" button on the website.</li>
                            <li>The status of your order will be displayed, including information such as the current location of your package and an estimated delivery date.</li>
                            <li>You can also receive updates on your order status via email or text message by subscribing to the order tracking service.</li>
                            <li>Track your order regularly and if there is any issue please contact customer service for assistance.</li>
                          </ol>
                          </Col>
                        <Col lg={2} md={3} xs={3}>
                        <img className="QrCodeIcon mt-3" src={QrCode} />  
                        </Col>
                      </Col>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Col>
          <Col lg={4} style={{ marginTop: '54px' }}>
            <Card className="mt-6 rounded-4" style={{ marginTop: '60px' }}>
              <CardContent>
                <div className='d-flex mb-4 mt-3'>
                  <img
                    src={aretsLogo}
                    alt='branding'
                    className='loginPage-brandImg'
                    onClick={() => window.location.reload()}
                  />
                  <h3 style={{ fontFamily: 'Yeseva One', fontWeight: '400' }}>AReTS Graphics
                    <p style={{ fontFamily: 'Clash Display', fontSize: '22px' }}>Everything in Printing</p>
                  </h3>
                </div>
                  <div className='h6FontSize'>
                <h6><small>15-4-64, Gowliguda Chaman, Hyderabad 500012</small></h6>
                <h6><small>Phone: 040-9393009990</small></h6>
                <h6><small>GSTIN: 36AEYPM2529L1ZR</small></h6>
                <h5 className='mt-3'>Client Details</h5>
                <h6><small>Firm Name: {location.state.item.value[0].customerName}, {location.state.item.value[0].estimateId.address}</small></h6>
                <h6><small>Phone: {location.state.item.value[0].mobile}</small></h6>
                <h6><small>GSTIN: {location.state.item.value[0].gstNo}</small></h6>
                <h5 className='mt-3'>Order Details</h5>
                {/* <h6><small>{location.state.item.value[0].cuttingSize}, {location.state.item.value[0].paperType} {location.state.item.value[0].gsm}, Color Laser, embossed, Laminated</small></h6> */}
                <h6><small>{location.state.item.value[0].jobDetails}</small></h6>
                <h6><small>{location.state.item.value[0].quantity} Copies</small></h6>
                <h5 className='mt-3'>Cost Estimate</h5>
                  </div>
                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Cost
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                  {location.state.item.value[0].totalAmount}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Delivery
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                  {location.state.item.value[0].packingAmount}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Misc %
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                  {location.state.item.value[0].miscAmount}
                  </div>
                </div>
                
                {/* <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Discount %
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    15%
                  </div>
                </div> */}
                <hr></hr>
                <div className="row mt-2">
                  <div className="col-lg-6 text-muted fs-14">
                    Total
                  </div>

                  <div className="col-lg-4 fw-semibold fs-14" style={{ textAlign: 'right' }}>
                  {/* {ordData && ordData?.totalAmount + ordData && ordData?.packingAmount} */}
                  {location.state.item.value[0].sum_total + location.state.item.value[0].packingAmount}
                  </div>
                  </div>
                
                <div className="row">
                  <div className="col-lg-8">
                    <span style={{ fontSize: '11px' }}>* 18% GST Extra</span>
                  </div>
                </div>
                {/* <div className="row mt-3">
                <div className="col-lg-1"></div>
                  <div className="col-lg-5">
                  <Button as={NavLink} to='/createOrders' id='pillStyle' className='' variant="outline-primary">Send Email</Button>
                  </div>
                  <div className="col-lg-6">
                  <Button as={NavLink} to='/createOrders' id='pillStyle' className='' variant="primary">Edit Order Status</Button>
                  </div>
                </div> */}
                <div className='text-center'>
                <Button as={NavLink} to='/createOrders' id='pillStyle' className='' variant="primary">Download Invoice</Button>
                </div>
                  
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
