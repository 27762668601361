import React, { useEffect, useState } from "react";
import "../../../Styles/JobCards.css";
import Header from "../../header/Header";
import Sidebar from "../../Sidenav/Sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../../Images/filterIcon.png";
import shareIcon from "../../../Images/shareIcon.png";
import sortIcon from "../../../Images/sortIcon.png";
import calenderIcon from "../../../Images/calenderIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import estimateService from "../../../services/estimateService";
import MasterService from "../../../services/MasterService";
import { toast } from "react-toastify";
import axios from "axios";
import editIcon from "../../../Images/editIcon.svg";
import { Table, Pagination } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import ModalSubmit from "../../modalSubmit";
import deleteIconRed from '../../../Images/deleteIconRed.svg';

const OrderType = () => {
  const [user, setUserdata] = useState();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordercount, setOrdercount] = useState(0);

  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const handleChangePage = (event, newPage) => {
    const dett = newPage * 10;
    setPage(newPage);
    getordertypes(dett, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getordertypes(0, event.target.value);
  };
  let navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);
  const [editmodal, setEditModalShow] = React.useState(false);

  const [ordertype, setOrderType] = useState([]);
  const [details, setDetails] = useState("");
  // const [status, setStatus] = useState('');
  useEffect(() => {
    getordertypes(0, 10);
  }, []);

  const getordertypes = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        // "searchkeys": { "orderType": searchtext }
      };
      const dert = await estimateService.getordertypes(obj);
      console.log(dert, "dert");
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setOrderType(dert.data.value);
        setOrdercount(dert.data.count);
      }
    } catch (ex) {}
  };
  //SearchAPI

  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };

  const serachclick = async () => {
    // getestimates(0, 10)
    try {
      const obj = {
        skip: 0,
        limit: 10,
        searchkeys: { orderType: searchtext },
        // "filterBy": {
        //     "status": filtstatus,
        //     "customerName": cname,
        //     "fromAmount": fromAmount,
        //     "toAmount": toAmount,
        //     "fromDate": "2022-07-17",
        //     "toDate": "2023-02-15"
        // }
      };
      console.log(obj, "objcutting");
      const dert = await estimateService.getordertypes(obj);
      if (dert.data.status === "success") {
        setSearch(true);
        console.log(dert.data.value, "values");
        setOrdercount(dert.data.count);
        setOrderType(dert.data.value);
      }
    } catch (ex) {}
  };

  //SearchAPI Close
  const create = async () => {
    if (!details) {
      await setSBtndis(false);
      toast.error("Enter Order Type Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    try {
      const obj = {
        orderType: details,
      };
      console.log(obj, "objjj");
      const dert = await MasterService.addordertypes(obj);
      console.log(dert.data, "dert.data");
      if (dert.data) {
        toast.success("OrderType Added SuccessFully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/ordertype");
        setDetails("");
        setModalShow(false);
      } else {
        toast.error(dert.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response.status === 400) console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const response = await axios.post(
        "/newMaster/orderType/jsontoexcel",
        { data: [] },
        { responseType: "blob" }
      );
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "OrderTypes.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  //  Download Excel Close

  //validations
  const [sbtndis, setSBtndis] = useState(false);

  //Validations close

  //pagination
  //     const [currentPage, setCurrentPage] = useState(1);
  //     const handlePageChange = (pageNumber) => {
  //         setCurrentPage(pageNumber);
  //     }

  //     const itemsPerPage = 5; // Change this as needed
  //     const totalItems = obj.length; // Replace myData with your data array
  //     const totalPages = Math.ceil(totalItems / itemsPerPage);

  //     const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentItems = obj.slice(startIndex, endIndex);

  //pagination close
  const [edmill, setedmill] = useState({});
  const [orderdetails, setOrderDetails] = useState("");
  // const [otstatus, setOTStatus] = useState('');
  const clickedit = (k) => {
    // console.log(k,'editmill');
    setedmill(k);
    setOrderDetails(k.orderType);
    // setOTStatus(k.active);
    setEditModalShow(true);
  };
  const handlesubmit = async () => {
    if (!orderdetails) {
      await setSBtndis(false);
      toast.error("Enter Order Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    try {
      const obj = {
        _id: edmill._id,
        orderType: orderdetails,
        // "_id":"64141d32363959b1846dc390"
      };
      const dort = await MasterService.updateordertype(obj);
      if (dort.status === 200) {
        getordertypes(0, 10);
        await setSBtndis(false);
        navigate("/ordertype");
        setEditModalShow(false);
        toast.success("Order Type Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  function modalreset() {
    setDetails("");
    setModalShow(false);
  }
  const resetclick = () => {
    setSearchText("");
    setSearch(false);
    getordertypes(0, 10);
  };
  const Delete = async (item) => {
        
    try {
      const obj = {
       "_id":item._id,
      }
   
      const dert = await MasterService.deletecutting(obj);
      console.log(dert, "dert");

      if (dert.data.status === "success") {
        getordertypes(0, 10);
        // setSearch(true)
        console.log(dert.data.value, "values");
      
           // setBreadth('');
           // setLength('');
           // setDetails('')
      }
    } catch (ex) {

    }
  }
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr  px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Order Type</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Order Type
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  onClick={() => setModalShow(true)}
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create Order Type
                </Button>
              </div>
            </div>
            <Row className="d-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row className="d-flex flex-column flex-lg-row justify-content-between">
                      <Col lg={4} sm={12} className="text-lg-start ">
                        <h6 className="datahead text-muted">Order Type List</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} className="px-4 pb-3 pb-lg-2">
                        <Row className="d-flex justify-content-lg-end">
                          <Col lg={5} sm={6}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 d-flex">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  className="form-control nosubmit"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Order Type"
                                />
                              </div>
                              <div className="col-lg-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i class="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          {/* <Col lg={2} sm={6} >
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6>Filter</h6>
                                                                            <h6>Order Type</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Home Delivery"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Pickup"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr> <hr className='text-muted'></hr>
                                                                            <div>
                                                                                <h6>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <h6>Customer</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="1">One</option>
                                                                                    <option value="2">Two</option>
                                                                                </Form.Select>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* date filter */}
                          {/* <Col lg={2} sm={6}>
                                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div>
                                                                    <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }}>
                                                                            <h6 className='mb-3'>By Date</h6>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Week"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Month"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="This Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />&nbsp;
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Last Year"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-2`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <hr className='text-muted'></hr>
                                                                            <Form>
                                                                                {['checkbox'].map((type) => (
                                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                                        <Form.Check
                                                                                            inline
                                                                                            label="Date Range"
                                                                                            name="group1"
                                                                                            type={type}
                                                                                            id={`inline-${type}-1`}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                            <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                                </Nav.Item>
                                                                                <Nav.Item>
                                                                                    <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                                </Nav.Item>
                                                                            </Nav>
                                                                            <Col lg={2} sm={2} md={2}>
                                                                                <DateRange
                                                                                    editableDateInputs={true}
                                                                                    onChange={item => setState([item.selection])}
                                                                                    moveRangeOnFirstSelection={false}
                                                                                    ranges={state}
                                                                                /></Col>
                                                                            <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState>
                                                    </Col> */}
                          {/* Export Excel */}
                          <Col lg={3} sm={2}>
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={3} sm={2}>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="Cancel">Cancel</option>
                                                            <option value="Delete">Delete</option>
                                                        </Form.Select>
                                                    </Col> */}
                        </Row>
                      </Col>
                      {/* categories starts */}
                    </Row>
                    <Table responsive borderless>
                      <thead>
                        <tr className="theadborder">
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <Form.Check aria-label="option 1" /></div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter ps-2">
                              <span>#</span>
                            </div>
                          </th>
                          <th>
                            <div className="table-headFilter">
                              <span>Order Type Details</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Status</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {ordertype && ordertype.length > 0
                          ? ordertype.map((k, index) => {
                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.orderType}</td>
                                  {/* <td>{k.isActive === true ? 'Active' : 'In Active'}</td> */}
                                  <td onClick={() => clickedit(k)}>
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>
                                  {user ? (
                                    user.access.includes("AA") ? (
                                      <td onClick={() => Delete(k)}>
                                          <img
                                            className="menuIcon mx-1"
                                            src={deleteIconRed}
                                          />
                                          Delete
                                      </td>
                                    ) : null
                                  ) : null}                                     </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        {/* <Pagination
                                                    className="justify-content-center"
                                                    size="sm"
                                                    activePage={currentPage}
                                                    itemsCountPerPage={itemsPerPage}
                                                    totalItemsCount={totalItems}
                                                    pageRangeDisplayed={5}
                                                    onChange={handlePageChange}
                                                /> */}
                        <TablePagination
                          component="div"
                          count={ordercount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Order Type{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      <TextField
                        className="w-100"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                        id="inputcolor"
                        type="text"
                        label="OrderType"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <select value={status} onChange={(e) => setStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                <option selected hidden>Status*</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>InActive</option>
                                            </select>
                                        </div>
                                    </div> */}

                  <ModalSubmit
                    cancelOnclick={() => modalreset()}
                    submitOnclick={() => create()}
                    submitText={"Confirm Order Type"}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        show={editmodal}
        onHide={() => setEditModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit order Type{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="input-group mb-3">
                      {/* <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={orderdetails}
                                                onChange={(e) => setOrderDetails(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Order Type*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            /> */}
                      <TextField
                        className="w-100"
                        value={orderdetails}
                        onChange={(e) => setOrderDetails(e.target.value)}
                        type="text"
                        id="inputcolor"
                        label="Order Type*"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <select value={otstatus} onChange={(e) => setOTStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                <option selected hidden>Status*</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>InActive</option>
                                            </select>
                                        </div>
                                    </div> */}
                  <div className="row gap-2 gap-lg-0 mt-3">
                    <div className="col-lg-6">
                      <Button
                        onClick={() => setEditModalShow(false)}
                        id="pillStyle"
                        className="w-100"
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-lg-6">
                      <Button
                        onClick={() => handlesubmit()}
                        id="pillStyle"
                        className="w-100"
                        variant="primary"
                      >
                        Update Order Type
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderType;
