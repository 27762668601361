import httpService from "./httpService";


export async function getreceipts(obj) {
	
	const data = await httpService.post('/cashReceipts/getAll	',obj);
	return data;
}

export async function addreceipts(obj) {
	
	const data = await httpService.post('/cashReceipts/add',obj);
	return data;
}


export default {
	getreceipts,
    addreceipts
}