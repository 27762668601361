import React from 'react';
import '../../Styles/Orders.css'
import Header from '../header/Header';
import Container from 'react-bootstrap/Container';
import Sidebar from '../Sidenav/Sidebar';
import aretsLogo from '../../Images/aretsLogo.png';
import blankPic from '../../Images/blankPic.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import DashOrdersIcon from '../../Images/DashOrdersIcon.png'
import PaymentsYellow from '../../Images/PaymentsYellow.svg'
import printpictureIcon from '../../Images/printpictureIcon.svg'

// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import { Nav } from 'react-bootstrap';

export default function ClientDetails() {
    const steps = [
        'Order Confirmed Tue,17th Jan 23',
        'Job Card Created',
        'Printing',
        'Post Printing',
        'Packing',
        'Out for Delivery',
        'Delivered',
    ];
    return (
        <>
            <Header />
            <Container fluid className='containerr'>
                <Row>
                    <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
                        <Sidebar />
                    </Col>
                    <Col lg={6} className="p-3">
                        <div>
                            <h1 className='fs-3 mx-5 mt-3'>Client Details</h1>
                            <Breadcrumb className='mx-5 fs-14'>
                                <Breadcrumb.Item active>Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>Clients</Breadcrumb.Item>
                                <Breadcrumb.Item active>Client Details</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <Card sx={{ minWidth: 275 }} className="mt-3 rounded-4">
                            <CardContent>
                                <Row>
                                    <Col lg={12} md={12} xs={12} className='d-flex'>
                                        <Col lg={3} md={3} xs={3}>
                                            <img className="QrCodeIcon mt-3" src={blankPic} />
                                        </Col>
                                        <Col lg={5} md={3} xs={3}></Col>
                                        <Col lg={4} md={3} xs={3}>
                                            <Button as={NavLink} to='/printing' id='pillStyle' className='mt-5' variant="primary">Edit Client Details</Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="row mt-2">
                                            <div className="col-lg-8 text-muted fw-semibold">
                                                <small>Firm Name</small>
                                            </div>
                                            <div className="col-lg-4 fw-semibold fs-14">
                                                SKS Creative Printers
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-lg-8 text-muted fw-semibold">
                                                <small>Phone</small>
                                            </div>
                                            <div className="col-lg-4 fw-semibold fs-14">
                                                +91 7894561230
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-lg-8 text-muted fw-semibold">
                                                <small>Email</small>
                                            </div>
                                            <div className="col-lg-4 fw-semibold fs-14">
                                                contact@skscreative.com
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-lg-8 text-muted fw-semibold">
                                                <small>GST</small>
                                            </div>
                                            <div className="col-lg-4 fw-semibold fs-14">
                                                56AFMCG3692L1XV
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-lg-5 text-muted fw-semibold">
                                                <small>Address</small>
                                            </div>
                                            <div className="col-lg-7 fw-semibold fs-14">
                                                1-43, Hanuman Nagar, Silpa Park, Kothaguda,
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-lg-8 text-muted fw-semibold">
                                                <small>City</small>
                                            </div>
                                            <div className="col-lg-4 fw-semibold fs-14">
                                                Hyderabad, Telangana, IN
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-lg-10 text-muted fw-semibold">
                                                <small>PIN Code</small>
                                            </div>
                                            <div className="col-lg-2 fw-semibold fs-14">
                                                506135
                                            </div>
                                        </div>

                                        <hr></hr>
                                        <div className="row mt-2">
                                            <div className='col-lg-5 d-flex'>
                                                <div className='col-lg-6 text-muted fw-semibold'><small>Bank Name</small></div>
                                                <div className='col-lg-6 fw-semibold'>ICICI Bank</div>
                                            </div>

                                            <div className='col-lg-7 d-flex'>
                                                <div className='col-lg-6 text-muted fw-semibold'><small>Branch Name</small></div>
                                                <div className='col-lg-6 fw-semibold'>Gachibowli,Telangana</div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className='col-lg-5 d-flex'>
                                                <div className='col-lg-6 text-muted fw-semibold'><small>IFSC</small></div>
                                                <div className='col-lg-6 fw-semibold'> ICIC00036</div>
                                            </div>
                                            <div className='col-lg-7 d-flex'>
                                                <div className='col-lg-6 text-muted fw-semibold'><small>Payment Type</small></div>
                                                <div className='col-lg-6 fw-semibold'> Bank</div>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <Row>
                                            <Col lg={6}>
                                                <Card className="mt-2 tabcenter rounded-4">
                                                    <CardContent>
                                                        <div className='row'>
                                                            <div className='col-lg-8 col-md-9'>
                                                                <img className="ClientCardIcon mx-1" src={DashOrdersIcon} />
                                                            </div>
                                                            <div className='col-lg-4 col-md-3'>
                                                                <div className="input-group">
                                                                    <select class="form-select" aria-label="Default select example">
                                                                        <option selected hidden>This Week</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='row mt-4'></div> */}
                                                        <div className='row mt-2'>
                                                            <div className='col-lg-4 col-md-4 fs-13 text-muted'>
                                                                All Orders
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-13 text-muted'>
                                                                Pending
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-13 text-muted'>
                                                                Completed
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-lg-4 col-md-4 fs-11'>
                                                                15
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-11'>
                                                                7
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-11'>
                                                                8
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Col>
                                            <Col lg={6}>
                                                <Card className="mt-2 mb-1 tabcenter rounded-4">
                                                    <CardContent>
                                                        <div className='row'>
                                                            <div className='col-lg-8 col-md-9'>
                                                                <img className="ClientCardIcon mx-1" src={PaymentsYellow} />
                                                            </div>
                                                            <div className='col-lg-4 col-md-3'>
                                                                <div className="input-group ">
                                                                    <select class="form-select " aria-label="Default select example">
                                                                        <option selected hidden>This week</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='row mt-4'></div> */}
                                                        <div className='row mt-2'>
                                                            <div className='col-lg-4 col-md-4 fs-13 text-muted'>
                                                                Total
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-13 text-muted'>
                                                                Pending
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-13 text-muted'>
                                                                Completed
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-lg-4 col-md-4 fs-11'>
                                                                ₹45,000.00
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-11'>
                                                                ₹ 20,000.00
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 fs-11'>
                                                                ₹ 25,000.00
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col lg={4} style={{ marginTop: '54px' }}>
                        <Card className="mt-6 rounded-4" style={{ marginTop: '60px' }}>
                            <CardContent>
                                <Row>
                                    <Col lg={12} md={12} xl={12}>
                                        <h5>Recent Orders</h5>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-3" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mt-3 mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5} className='mt-3'>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-danger fw-semibold'><small>Pending</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-success fw-semibold'><small>Completed</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className=' text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-warning fw-semibold'><small>Printing In Progress</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-primary fw-semibold'><small>Delivery In Progress</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-danger fw-semibold'><small>Pending</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-success fw-semibold'><small>Completed</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className='fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-warning fw-semibold'><small>Printing In Progress</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className=' fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-warning fw-semibold'><small>Printing In Progress</small></div>
                                            </Col>
                                        </div>
                                        <hr className='text-muted'></hr>
                                        <div className='d-flex'>
                                            <Col lg={2}>
                                                <img className="QrCodeIcon mt-1" src={printpictureIcon} />
                                            </Col>
                                            <Col lg={5} className='mx-2'>
                                                <div className=' fw-semibold'><small>Wedding Cards</small></div>
                                                <div className='fw-semibold'><small>₹40.00 x 400</small></div>
                                            </Col>
                                            <Col lg={5}>
                                                <div className='text-muted fw-semibold'><small>12 Sept 2022</small></div>
                                                <div className='text-primary fw-semibold'><small>Delivery In Progress</small></div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
