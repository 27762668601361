import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
// import Paper from '@mui/material/Paper';
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import UploadImageIcon from "../../Images/UploadImageIcon.png";
import DeleteIcon from "../../Images/DeleteIcon.png";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { connect } from "react-redux";
// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import JobcardsService from "../../services/JobcardsService";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import authService, { getorders } from "../../services/authService";
import { toast } from "react-toastify";
import IndentService from "../../services/IndentService";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";

function AddNewJobCard(props) {
  let location = useLocation();
  //addjobcardsIntegration
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState();
  const [client, setClient] = useState();
  const [gsmtypes, setGsmTypes] = useState([]);
  const [user, setUserdata] = useState({});
  const [cname, setCname] = useState(location.state?.item ? location.state?.item.customerName : "");
  const [id, setID] = useState(location.state?.item ? location.state?.item._id : "");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [jobCardNo, setJobCardNo] = useState("");
  const [jname, setJname] = useState("");
  const [callParty, setCallParty] = useState("");
  const [plateBy, setPlateBy] = useState("");
  const [colorProof, setColorProof] = useState("");
  const [noOfSets, setNoOfSets] = useState("");
  const [agAou, setAgAou] = useState("");
  const [machine, setMachine] = useState("");
  const [bmachinetype, setBMachineType] = useState({});
  const [gsmoptions, setGsmOptions] = useState([]);
  const [machines, setMachines] = useState([]);
  const [bpapertype, setBPaperType] = useState("");
  const [paperType, setPapertype] = useState("");
  const [paptype, setPapType] = useState("");
  const [paperTypes, setpaperTypes] = useState("");
  const [orderType, setOrdertype] = useState("");
  const [paperGsm, setpaperGsm] = useState("");
  const [paperSize, setPaperSize] = useState("");
  const [paperQuantity, setPaperQuantity] = useState("");
  const [paperCuttingSize, setPaperCuttingSize] = useState("");
  const [paperCuttingQty, setpaperCuttingQty] = useState("");
  const [machinetype, setMachineType] = useState("");
  const [modeofprinting, setModeofPrinting] = useState("");
  const [printing, setPrinting] = useState("");
  const [color, setColor] = useState("");
  const [totalImpressions, setTotalImpressions] = useState("");
  const [finalSheets, setFinalSheets] = useState("");
  const [printMachine, setPrintMachine] = useState("");
  const [printMedia, setPrintMedia] = useState("");
  const [printQuantity, setPrintQuantity] = useState("");
  const [printRate, setPrintRate] = useState("");
  const [printCost, setPrintCost] = useState("");
  const [lBreadth, setlBreadth] = useState("");
  const [lLength, setlLength] = useState("");
  const [lSheet, setlSheet] = useState("");
  const [lQuantity, setlQuantity] = useState("");
  const [lRate, setLRate] = useState("");
  const [orid, setorid] = useState("");
  const [lCost, setLCost] = useState("");
  const [pBreadth, setPBreadth] = useState("");
  const [pLength, setPLength] = useState("");
  const [pType, setPType] = useState("");
  const [punchingTypes, setPunchingTypes] = useState([]);
  const [orderNo, setOrderNo] = useState({});
  const [orderId, setOrderId] = useState("");
  const [estimateId, setEstimateId] = useState({});
  const [pQuantity, setPQuantity] = useState([]);
  const [pRate, setPRate] = useState([]);
  const [pCost, setpCost] = useState([]);
  const [outSouWorks, setOutSouWorks] = useState([]);
  const [transDetails, setTransDetails] = useState([]);
  const [cutCharge, setCutCharge] = useState([]);
  const [packCharge, setPackCharge] = useState([]);
  const [transCharge, setTransCharge] = useState([]);
  const [otherCharge1, setOtherCharge1s] = useState([]);
  const [otherCharge2, setOtherCharge2s] = useState([]);
  const [total, setTotal] = useState([]);
  const [Status, setStatus] = useState([]);
  const [createdByDate, setcreatedByDate] = useState([]);
  const [updatedDate, setupdatedDate] = useState([]);
  const [jobdetails, setJobdetails] = useState([]);
  const [firstMaterialIndentNo, setFirstMaterialIndentNos] = useState([]);
  const [transName, setTransName] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [paperVendor, setPaperVendor] = useState([]);
  const [sbtndis, setSBtndis] = useState(false);
  const [jobtype, setJobtype] = useState("");
  const [estmid, setEstmid] = useState("");
  const multiselectRef = useRef();
  const machinemultiselectRef = useRef();
  const papermultiselectRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    // getOrder();
    getclients();
    getmachines();
    getgsmtypes();
    getpapertypes();
    getpunchtypes();
    getselclient();
    // getfinishsizes()
  }, [props.userdata]);

  const handlemode = (e) => {
    setModeofPrinting(e.target.value);
    jobdetails.push(e.target.value);
  };

  const getselclient = async () => {
    let arr = [];
    if (clients && clients.length > 0) {
      const rty = clients.map((k) => {
        if (k.name === location.state.item.customerName) {
          arr.push(k);
        }
      });
      console.log(arr, "arredit");
      setClient(arr);

      setCname(arr[0].name);
      setCustomerId(arr[0]._id);
      try {
        const det = await JobcardsService.getordersById(arr[0]._id);
        if (det.data.status === "success") {
          let arrr = [];
          setOrders(det.data.value);
          const rty = det.data.value.map((k) => {
            console.log(location.state.item, 'location');
            if (k.orderId === location.state.item.orderId) {
              arrr.push(k);
            }
          });
          console.log(arrr[0].status, "arrr[0]");
          setOrderId(arrr[0].orderId);
          setorid(arrr[0]._id);
          setJname(arrr[0].name);
          setDate(moment.utc(arrr[0].createDate).format("YYYY-MM-DD"));
          setTime(moment.utc(arrr[0].createDate).format("HH:MM"));
          setJobtype(arrr[0].orderType);
          setStatus(arrr[0].status);
          setPrintMachine(arrr[0].machine);
          console.log(
            `${arrr[0].paperType}${arrr[0].gsm}`,
            "`${arrr[0].paperType}${arrr[0].gsm}`"
          );
          setpaperGsm(`${arrr[0].paperType}${arrr[0].gsm}`);
          setGsm(arrr[0].gsm);
          setPaperType(arrr[0].paperType);
          setPapType(arrr[0].paperType);
          setPaperSize(`${arrr[0].millerLength}*${arrr[0].millerBreadth}`);
          setPaperQuantity(arrr[0].quantity);
          setPaperCuttingSize(arrr[0].cuttingSize);
          setpaperCuttingQty(arrr[0].cuttingQuantity);
          setPrinting(arrr[0].printingside);
          setFinalSheets(arrr[0].finalsheetforperMillisheet);
          setJobdetails(arrr[0].jobDetails);
          setModeofPrinting(arrr[0].machine);
          setlBreadth(arrr[0].laminationBreadth);
          setlLength(arrr[0].laminationLength);
          setlQuantity(arrr[0].punchingQuantity);
          setLRate(arrr[0].laminationRate);
          setPType(arrr[0].punching);
          setPQuantity(arrr[0].punchingQuantity);
          setPRate(arrr[0].punchingRate);
          setEstmid(arrr[0].estimateId._id);
          //  setTimeout(() => {
          //    selectorder(location.state.item.orderId)
          //  }, 6000);
        }
      } catch (ex) {}
      // setCid(arr[0]._id)
    }
  };

  const selectemps = async (e) => {
    console.log(e.target.selectedOptions[0].dataset.index, "value");
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = clients[selindex];
    setCname(selclient.name);
    setCustomerId(selclient._id);
    console.log(selclient, "selclient");
    try {
      const det = await JobcardsService.getordersById(selclient._id);
      if (det.data.status === "success") {
        setOrders(det.data.value);
      }
    } catch (ex) {}
  };
  const getpunchtypes = async () => {
    try {
      const der = await estimateService.getpunchingtypes();
      if (der.data.status === "success") {
        setPunchingTypes(der.data.value);
      }
    } catch (ex) {}
  };
  const selectorder = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    const selclient = orders[selindex];
    console.log(selclient, "selorder");
    setOrderId(selclient.orderId);
    setorid(selclient._id);
    setJname(selclient.name);

    setDate(moment.utc(selclient.createDate).format("YYYY-MM-DD"));
    setTime(moment.utc(selclient.createDate).format("HH:MM"));
    setJobtype(selclient.orderType);
    setStatus(selclient.status);
    setPrintMachine(selclient.machine);
    console.log(
      `${selclient.paperType}${selclient.gsm}`,
      "`${selclient.paperType}${selclient.gsm}`"
    );
    setpaperGsm(`${selclient.paperType}${selclient.gsm}`);
    setGsm(selclient.gsm);
    setPaperType(selclient.paperType);
    setPapType(selclient.paperType);
    setPaperSize(`${selclient.millerLength}*${selclient.millerBreadth}`);
    setPaperQuantity(selclient.quantity);
    setPaperCuttingSize(selclient.cuttingSize);
    setpaperCuttingQty(selclient.cuttingQuantity);
    setPrinting(selclient.printingside);
    setFinalSheets(selclient.finalsheetforperMillisheet);
    setJobdetails(selclient.jobDetails);
    setModeofPrinting(selclient.machine);
    setlBreadth(selclient.laminationBreadth);
    setlLength(selclient.laminationLength);
    setlQuantity(selclient.punchingQuantity);
    setLRate(selclient.laminationRate);
    setPType(selclient.punching);
    setPQuantity(selclient.punchingQuantity);
    setPRate(selclient.punchingRate);
    setEstmid(selclient.estimateId._id);
  };

  const selectpapertype = (value) => {
    setPapertype(value);
    setBPaperType(value[0]);
  };

  const getOrder = async () => {
    console.log("calling orders");
    try {
      const dert = await estimateService.getOrders();
      console.log(dert, "orderssss");
      if (dert.data.status === "success") {
        console.log(dert.data.value, "orders");
        setOrders(dert.data.value);
      }
    } catch (ex) {}
  };
  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };

  const getgsmtypes = async () => {
    try {
      const dert = await estimateService.getpapergsmAll();
      if (dert.data.status === "success") {
        if (dert.data.value?.length > 0) {
          let optionss = [];
          dert.data.value.map((k) => {
            const obj = {
              paperTypegsm: k.paperType + k.gsm,
              _id: k._id,
              paperType: k.paperType,
              perkgRate: k.perkgRate,
              gsm: k.gsm,
              printmincost: k.printmincost,
              printminImpressionquantity: k.printminImpressionquantity,
              printextraImpressioncost: k.printextraImpressioncost,
              printextraImpressionquantity: k.printextraImpressionquantity,
              isActive: k.isActive,
              isArchive: k.isArchive,
              updatedDate: k.updatedDate,
              createDate: k.createDate,
              __v: 0,
            };
            optionss.push(obj);
          });
          setGsmOptions(optionss);
          setGsmTypes(dert.data.value);
        }
      }
    } catch (ex) {}
  };

  const getpapertypes = async () => {
    try {
      const dty = await estimateService.papertypes();
      if (dty.data.status === "success") {
        console.log(dty.data.value, "dty.data.value");
        setpaperTypes(dty.data.value);
      }
    } catch (ex) {}
  };

  const getmachines = async () => {
    try {
      const dert = await estimateService.getmachines();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "dert.data.value");
        setMachines(dert.data.value);
      }
    } catch (ex) {}
  };

  const selectmachinetype = (value) => {
    setMachineType(value);
    setBMachineType(value[0]);
    jobdetails.push(value[0].details);
    console.log(value, "value");
  };
  // const [estimatecalc, setEstimatecalc] = useState()
  // const multiselectRef = useRef();
  // const papermultiselectRef = useRef();
  // const finishmultiselectRef = useRef();
  // const machinemultiselectRef = useRef();
  // const millimultiselectRef = useRef();
  // const laminationmultiselectRef = useRef();
  // const extraworksmultiselectRef = useRef();
  // const printjobmultiselectRef = useRef();
  const hiddenFileInput = useRef(null);
  const [imloader, setImloader] = useState(null);
  const [file, setFile] = useState(null);

  const submit = async () => {
    if (!orderId) {
      await setSBtndis(false)
      toast.error('Enter Firm Name', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!date) {
      await setSBtndis(false);
      toast.error("Select Date", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!time) {
      await setSBtndis(false);
      toast.error("Select Time", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jname) {
      await setSBtndis(false);
      toast.error("Select Job Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!orderType) {
      await setSBtndis(false);
      toast.error("Select Order Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!Status) {
      await setSBtndis(false);
      toast.error("Select Status", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printMachine) {
      await setSBtndis(false);
      toast.error("Select Print Machine", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!callParty) {
      await setSBtndis(false);
      toast.error("Select Call Party", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!plateBy) {
      await setSBtndis(false);
      toast.error("Select plateBy", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!noOfSets) {
      await setSBtndis(false);
      toast.error("Enter No of Sets", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!colorProof) {
      await setSBtndis(false);
      toast.error("Select Color Proof", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!agAou) {
      await setSBtndis(false);
      toast.error("Select Ag/Aou", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperType) {
      await setSBtndis(false);
      toast.error("Select Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperGsm) {
      await setSBtndis(false);
      toast.error("Select GSM", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperSize) {
      await setSBtndis(false);
      toast.error("Enter Paper Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperQuantity) {
      await setSBtndis(false);
      toast.error("Select Paper Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperCuttingSize) {
      await setSBtndis(false);
      toast.error("Enter Cutting Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperCuttingQty) {
      await setSBtndis(false);
      toast.error("Enter Cutting Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!color) {
      await setSBtndis(false);
      toast.error("Enter Color", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printing) {
      await setSBtndis(false);
      toast.error("Select Printing", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!totalImpressions) {
      await setSBtndis(false);
      toast.error("Enter Total Impressions", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!finalSheets) {
      await setSBtndis(false);
      toast.error("Enter Final Sheets", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jobdetails) {
      await setSBtndis(false);
      toast.error("Enter Job Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printMachine) {
      await setSBtndis(false);
      toast.error("Select Print Machine", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printMedia) {
      await setSBtndis(false);
      toast.error("Select Print Media", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printQuantity) {
      await setSBtndis(false);
      toast.error("Select Print Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printRate) {
      await setSBtndis(false);
      toast.error("Enter Print Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!printCost) {
      await setSBtndis(false);
      toast.error("Select Print Cost", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lBreadth) {
      await setSBtndis(false);
      toast.error("Enter Lamination Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lLength) {
      await setSBtndis(false);
      toast.error("Enter Lamination Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lSheet) {
      await setSBtndis(false);
      toast.error("Enter Lamination Sheet", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lQuantity) {
      await setSBtndis(false);
      toast.error("Enter Lamination Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lRate) {
      await setSBtndis(false);
      toast.error("Enter Lamination Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!lCost) {
      await setSBtndis(false);
      toast.error("Enter Lamination Cost", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pBreadth) {
      await setSBtndis(false);
      toast.error("Enter Punching Breadth", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pLength) {
      await setSBtndis(false);
      toast.error("Enter Punching Length", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pType) {
      await setSBtndis(false);
      toast.error("Select Punching Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pQuantity) {
      await setSBtndis(false);
      toast.error("Enter Punching Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pRate) {
      await setSBtndis(false);
      toast.error("Enter Punching Rate", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!pCost) {
      await setSBtndis(false);
      toast.error("Enter Punching Cost", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!cutCharge) {
      await setSBtndis(false);
      toast.error("Enter Cutting Charge", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!packCharge) {
      await setSBtndis(false);
      toast.error("Enter Packing Charge", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transDetails) {
      await setSBtndis(false);
      toast.error("Enter Transport Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transCharge) {
      await setSBtndis(false);
      toast.error("Enter Transport Charge", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!outSouWorks) {
      await setSBtndis(false);
      toast.error("Enter Out Source Works", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!otherCharge1) {
      await setSBtndis(false);
      toast.error("Enter Other Charges", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!otherCharge2) {
      await setSBtndis(false);
      toast.error("Enter Other Charges", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!total) {
      await setSBtndis(false);
      toast.error("Enter Total", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperVendor) {
      await setSBtndis(false);
      toast.error("Select Paper Vendor", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transName) {
      await setSBtndis(false);
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transDetails) {
      await setSBtndis(false);
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!outSouWorks) {
      await setSBtndis(false);
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperVendor) {
      await setSBtndis(false);
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      try {
      } catch (ex) {}
    }
  };
  const handleorderType = (e) => {
    setOrdertype(e);
    jobdetails.push(e);
  };

  const handlesubmit = async () => {
    try {
      const obj = {
        jobCardNo: jobCardNo,
        customerName: cname,
        jobName: jname,
        callParty: callParty,
        plateBy: plateBy,
        colorProof: colorProof,
        noOfSets: noOfSets,
        agAou: agAou,
        machine: machine,
        date: date,
        orderNo: orderNo,
        orderId: orderId,
        colors: color,
        printing: printing,
        totalImpressions: totalImpressions,
        finalSheets: finalSheets,
        paperType: bpapertype.details,
        paperGsm: paperGsm,
        paperSize: paperSize,
        paperQuantity: paperQuantity,
        paperCuttingSize: paperCuttingSize,
        paperCuttingQty: paperCuttingQty,
        printMachine: printMachine,
        printMedia: printMedia,
        printQuantity: printQuantity,
        printRate: printRate,
        printCost: printCost,
        lBreadth: lBreadth,
        lLength: lLength,
        lSheet: lSheet,
        lQuantity: lQuantity,
        lRate: lRate,
        lCost: lCost,
        pBreadth: pBreadth,
        pLength: pLength,
        pType: pType,
        pQuantity: pQuantity,
        pRate: pRate,
        pCost: pCost,
        transDetails: transDetails,
        outSouWorks: outSouWorks,
        cutCharge: cutCharge,
        packCharge: packCharge,
        transCharge: transCharge,
        otherCharge1: otherCharge1,
        otherCharge2: otherCharge2,
        total: total,
        status: Status,
        jobDetails: jobdetails,
        firstMaterialIndentNo: firstMaterialIndentNo,
        transName: transName,
        customerId: customerId,
        paperVendor: paperVendor,
        estimateId: estimateId,
        jobDetails: jobdetails,
        isActive: true,
        isArchive: false,
        _id: id,
        createdByDate: createdByDate,
        updatedDate: updatedDate,
        __v: 0,
      };
      const dort = await JobcardsService.addjobcards(obj);
      console.log(dort, "dort");
      if (dort.status === 200) {
        await setSBtndis(false);
        navigate("/jobcards");
        toast.success("Job Card Created Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      await setSBtndis(false);
      console.log(ex.response, "ex.response");
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  //addjobcardsIntegration close

  //add indent Integration
  const [role, setRole] = useState("");
  const [indentno, setIndentNo] = useState("");

  const [papertype, setPaperType] = useState("");
  const [gsm, setGsm] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [sname, setSname] = useState("");

  function editjob() {
    navigate("/editjob");
  }

  //Indent validation
  const modalsubmit = async () => {
    if (!indentno) {
      await setSBtndis(false);
      toast.error("Select Indent ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!date) {
      await setSBtndis(false);
      toast.error("Select Date", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!time) {
      await setSBtndis(false);
      toast.error("Select Time", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!orderNo) {
      await setSBtndis(false);
      toast.error("Select Order ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!jobCardNo) {
      await setSBtndis(false);
      toast.error("Select Job Card ID", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!Status) {
      await setSBtndis(false);
      toast.error("Select Status", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!sname) {
      await setSBtndis(false);
      toast.error("Select Supplier", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!papertype) {
      await setSBtndis(false);
      toast.error("Select Paper Type", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!gsm) {
      await setSBtndis(false);
      toast.error("Select GSM", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!quantity) {
      await setSBtndis(false);
      toast.error("Enter Quantity", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!size) {
      await setSBtndis(false);
      toast.error("Enter Size", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  //Indent Validations close

  const create = async () => {
    try {
      const obj = {
        indentNo: indentno,
        orderNo: orderNo,
        jobCardNo: jobCardNo,
        date: date,
        paperType: papertype,
        gsm: gsm,
        size: size,
        quantity: quantity,
        supplierName: sname,
        status: Status,
      };

      const dert = await IndentService.addindents(obj);
      if (dert.data.status === "success") {
        toast.success("Indent Added SuccessFully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/indent");
      } else {
      }
    } catch (ex) {}
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const changeHandler = async (event) => {
    console.log("calling");
    // await setImloader(true);

    const formData = new FormData();

    formData.append("image", event.target.files[0]);
    console.log(event.target.files[0], "event.target.files");
    let res = await axios.post(
      "https://aretsapi.azurewebsites.net/api/common/uploadfile",
      formData,

      {
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data; ",
          Accept: "*/*",
        },
      }
    );
    console.log(res.data, "res.data");
    if (res.data.url) {
      // await setImloader(false);

      await setFile(res.data.url);
    }
  };
  const delmg = async () => {
    var filename = file.substring(file.lastIndexOf("/") + 1);
    const obj = {
      url: filename,
    };
    const dete = await estimateService.delproimg(obj);
    if (dete.data.status === true) {
      setFile();
      toast.success("File Deleted SuccessFully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  //add indent Integration close

  const [modalShow, setModalShow] = React.useState(false);
  console.log(printMachine, "printMachine");
  const createjob = async () => {
    if (!callParty) {
      toast.error("Enter Call Party", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!plateBy) {
      toast.error("Enter Plate By", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!noOfSets) {
      toast.error("Enter noOfSets", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!colorProof) {
      toast.error("Enter Color Proof", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!agAou) {
      toast.error("Enter AG/AOU", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transDetails) {
      toast.error("Enter Transfer Details", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!outSouWorks) {
      toast.error("Enter Other Source Works", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!paperVendor) {
      toast.error("select Paper Vendor", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!transName) {
      toast.error("Enter Transport Name", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      try {
        const obj = {
          customerName: cname,
          jobName: jname,
          orderType: jobtype,
          callParty: callParty,
          plateBy: plateBy,
          colorProof: colorProof,
          noOfSets: noOfSets,
          agAou: agAou,
          machine: printMachine,
          date: moment.utc(date).format("YYYY-MM-DD"),
          orderId: orderId,
          colors: color,
          printing: printMachine,
          totalImpressions: totalImpressions,
          finalSheets: finalSheets,
          paperGsm: paperGsm,
          paperSize: paperSize,
          paperQuantity: paperQuantity,
          paperCuttingSize: paperCuttingSize,
          paperCuttingQty: paperCuttingQty,
          printMachine: printMachine,
          printMedia: printMedia,
          printQuantity: printQuantity,
          printRate: printRate,
          lBreadth: lBreadth,
          lLength: lLength,
          lSheet: lSheet,
          lQuantity: lQuantity,
          lRate: lCost,
          pBreadth: pBreadth,
          pLength: pLength,
          pType: pType,
          pQuantity: pQuantity,
          pRate: pRate,
          transDetails: transDetails,
          outSouWorks: outSouWorks,
          status: Status,
          jobDetails: jobdetails,
          transName: transName,
          paperVendor: paperVendor,
          orId: orid,
        };
        console.log(obj, "jobobj");
        const data = await JobcardsService.addjobcards(obj);
        if (data.data.status === "success") {
          const ob = {
            gsm: gsm,
            date: moment.utc(createdByDate).format("YYYY-MM-DD"),
            orderId: orderId,
            estimateId: estmid,
            jobCardId: data.data.value._id,
            paperType: paptype,
            size: paperSize,
            quantity: paperQuantity,
            supplierName: paperVendor,
            status: Status,
            path: "job",
          };
          console.log(ob, "obobob");
          const dert = await JobcardsService.addindent(ob);
          if (dert.data.status === "success") {
            toast.success("Job Card Created SuccessFully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            console.log(dert.data, "dert.data");
            navigate("/jobcards");
          }
        }
      } catch (ex) {}
    }
  };
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} className="p-3">
            <div>
              <h1 className="fs-3 mx-5 mt-3">Create New Job Card</h1>
              <Breadcrumb className="mx-5 fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/jobcards">Job Cards</Breadcrumb.Item>
                <Breadcrumb.Item active>Create New Job Card</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4">
              <CardContent>
                <Col lg={12} className="pe-3">
                <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex">
                      <div className="col-lg-2 mt-2">
                        <h6 className="fs-12 fw-bold text-center">Firm Name</h6>
                      </div>
                      <div className="col-lg-3">
                        <div className="input-group">
                        <input
                            type="text"
                            className="form-control text-muted required"
                            name="name"
                            value={cname} onChange={(e) => setCname(e.target.value)}
                            id='inputcolorr'
                            placeholder="Firm Name*"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 mt-2">
                      <h6 className="fs-12 fw-bold text-center">Order ID</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                          id="inputcolorr"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-center">
                        Booking Date
                      </h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <div className="col-lg-12">
                          <div className="input-group mb-3">
                            <input
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              type="date"
                              id="inputcolorr"
                              className="form-control text-muted required"
                              name="date "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex mt-2 dashedline">
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-center">Job Name</h6>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="name"
                          value={jname}
                          onChange={(e) => setJname(e.target.value)}
                          id="inputcolorr"
                          placeholder="Job Name*"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 mt-2">
                      <h6 className="fs-12 fw-bold text-center">Job Type</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <select
                          value={jobtype}
                          // onChange={(e) => setJobType(e.target.value)}

                          class="form-select text-muted"
                          id="inputcolorr"
                          aria-label="Default select example"
                        >
                          <option selected>Job Type</option>
                          <option value="Wedding Cards">Wedding Cards</option>
                          <option value="Art Cards">Art Cards</option>
                          <option value="ID Cards">ID Cards</option>
                          {/* <option value="Wedding Cards">Wedding Cards</option>
                          <option value="Art Cards">Art Cards</option>
                          <option value="ID Cards">ID Cards</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 mt-2">
                      <h6 className="fs-12 fw-bold text-center">Status</h6>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <select
                          value={Status}
                          onChange={(e) => setStatus(e.target.value)}
                          class="form-select text-muted"
                          id="inputcolorr"
                          aria-label="Default select example"
                        >
                          <option selected>Status*</option>
                          <option value="ESTIMATE">Estimate</option>
                          <option value="ORDER-CONFIRMED">
                            Order Confirmed
                          </option>
                          <option value="JOB-Create">Job Created</option>
                          <option value="PRINTED">Printed</option>
                          <option value="DELIVERED">Delivered</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 mt-2 d-flex dashedline">
                      {/* first row */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PAPER DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold ">paper & Gsm</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={paperGsm}
                                disabled
                                class="form-select text-muted"
                                placeholder="Mode of Printing"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Paper and GSM*</option>
                                {gsmoptions &&
                                  gsmoptions.map((k, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={k.paperTypegsm}
                                        data-index={index}
                                      >
                                        {k.paperTypegsm}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Mill Size</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                value={paperSize}
                                disabled
                                // value={paperSize} onChange={(e) => setPaperSize(e.target.value)}
                                id="inputcolorr"
                                placeholder="Size*"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Mill Quantity</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={paperQuantity}
                                disabled
                                id="inputcolorr"
                                placeholder="Quantity*"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Cutting Size</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Cut Size"
                                value={paperCuttingSize}
                                disabled
                                id="inputcolorr"
                                placeholder="Cut Size*"
                                aria-label="Cut Size"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Cutting Quantity</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Cut Quantity"
                                value={paperCuttingQty}
                                disabled
                                id="inputcolorr"
                                placeholder="Cut Quantity*"
                                aria-label="Cut Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Paper Vendor</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={paperVendor}
                                onChange={(e) => setPaperVendor(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Paper Vendor*</option>
                                <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PLATE DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold ">plate By</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={plateBy}
                                onChange={(e) => setPlateBy(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Plate By*</option>
                                <option value="Gayatri">Gayatri</option>
                                <option value="Hari Kisan Paper">
                                  Hari Kisan Paper
                                </option>
                                <option value="Jai Shankar Khagad bhandhar">
                                  Jai Shankar Khagad bhandhar
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">No.of Sets</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={noOfSets}
                                onChange={(e) => setNoOfSets(e.target.value)}
                                id="inputcolorr"
                                placeholder="Enter No.of Sets*"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Plate Vendor</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={agAou}
                                onChange={(e) => setAgAou(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>AG/AOU*</option>
                                <option value="AG">AG</option>
                                <option value="AOU">AOU</option>
                                <option value="AG/AOU">AG/AOU</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Third Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PRINTING DETAILS:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Mode Of Printing</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="quantity"
                                value={modeofprinting}
                                id="inputcolorr"
                                placeholder="Mode of Printing"
                                aria-label="quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">No.Of Colors</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                                id="inputcolorr"
                                placeholder="Color*"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Printing Side</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={printing}
                                disabled
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Printing Side*</option>
                                <option value="OS">OS</option>
                                <option value="BB">BB</option>
                                <option value="FB">FB</option>
                                <option value="OBBG">OBBG</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Total Impression</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                value={totalImpressions}
                                onChange={(e) =>
                                  setTotalImpressions(e.target.value)
                                }
                                id="inputcolorr"
                                placeholder="Total Impressions*"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Final Sheets</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="rate"
                                value={finalSheets}
                                onChange={(e) => setFinalSheets(e.target.value)}
                                id="inputcolorr"
                                placeholder="Final Sheets*"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Colour Proof</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={colorProof}
                                onChange={(e) => setColorProof(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Color Proof*</option>
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Call Party</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={callParty}
                                onChange={(e) => setCallParty(e.target.value)}
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Call Party*</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group col-lg-12 col-sm-12 col-md-12 col-12 mt-2 d-flex dashedline">
                      {/* first row */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">LAMINATION:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Length</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="length"
                                disabled
                                className="form-control required"
                                name="length"
                                value={lLength}
                                onChange={(e) => setlLength(e.target.value)}
                                id="inputcolorr"
                                placeholder="Length*"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Breadth</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="Breadth"
                                className="form-control required"
                                name="Breadth"
                                disabled
                                value={lBreadth}
                                onChange={(e) => setlBreadth(e.target.value)}
                                id="inputcolorr"
                                placeholder="Breadth*"
                                aria-label="Breadth"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Size</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control text-muted required"
                                name="name"
                                value={lSheet}
                                onChange={(e) => setlSheet(e.target.value)}
                                id="inputcolorr"
                                placeholder="Sq.in/Sheet*"
                                aria-label="name"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="Quantity"
                                value={lQuantity}
                                onChange={(e) => setlQuantity(e.target.value)}
                                id="inputcolorr"
                                placeholder="Quantity*"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-6 mt-2">
                          <h6 className="fs-16 fw-bolder">PUNCHING:</h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold ">Punching Type</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <select
                                value={pType}
                                disabled
                                class="form-select text-muted"
                                id="inputcolorr"
                                aria-label="Default select example"
                              >
                                <option selected>Punch Type*</option>
                                {punchingTypes &&
                                  punchingTypes.map((k, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={k.type}
                                        data-index={index}
                                      >
                                        {k.type}
                                      </option>
                                    );
                                  })}
                                {/* <option value="1">None</option>
                                <option value="2">Full Punching</option>
                                <option value="3">Half Punching</option> */}
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex mt-1">
                      <div className="col-lg-4 mt-2">
                        <h6 className="fs-12 fw-bold">Paper/Gsm</h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control text-muted required"
                            name="address"
                            id="inputcolorr"
                            value={''}
                            disabled
                            aria-label="Address"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      </div> */}
                        {/* <div className="d-flex mt-1">
                      <div className="col-lg-4 mt-2">
                        <h6 className="fs-12 fw-bold">Size</h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control text-muted required"
                            name="address"
                            id="inputcolorr"
                            value={''}
                            disabled
                            aria-label="Address"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      </div> */}
                        <div className="d-flex mt-1">
                          <div className="col-lg-4 mt-2">
                            <h6 className="fs-12 fw-bold">Quantity</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control required"
                                name="rate"
                                value={pQuantity}
                                onChange={(e) => setPQuantity(e.target.value)}
                                id="inputcolorr"
                                placeholder="Quantity*"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-flex mt-1">
                      <div className="col-lg-4 mt-2">
                        <h6 className="fs-12 fw-bold">Die By</h6>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control text-muted required"
                            name="address"
                            id="inputcolorr"
                            value={''}
                            disabled
                            aria-label="Address"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      </div> */}
                      </div>

                      {/* Third Column */}

                      <div className="col-lg-4">
                        <div className="col-lg-10 mt-2">
                          <h6 className="fs-16 fw-bolder">
                            BINDING / OUT SOURCE WORK:
                          </h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-8">
                            <div className="input-group">
                              <input
                                type="Quantity"
                                className="form-control required"
                                name="Quantity"
                                value={outSouWorks}
                                onChange={(e) => setOutSouWorks(e.target.value)}
                                id="inputcolorr"
                                placeholder="Outsource Works*"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-10 mt-2">
                          <h6 className="fs-16 fw-bolder">
                            TRANSPORT DETAILS:
                          </h6>
                        </div>
                        <div className="d-flex">
                          <div className="col-lg-8">
                            <div className="input-group">
                              <input
                                type="Quantity"
                                className="form-control required"
                                name="Quantity"
                                value={transDetails}
                                onChange={(e) =>
                                  setTransDetails(e.target.value)
                                }
                                id="inputcolorr"
                                placeholder="Transport Details*"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="input-group mt-3">
                          <Button
                            as={NavLink}
                            to="/jobcards"
                            id="pillStyle"
                            className="w-100"
                            variant="outline-primary"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="input-group mt-3">
                          <Button
                            onClick={() => createjob()}
                            id="pillStyle"
                            className="w-100"
                            variant="primary"
                          >
                            Create Job Card
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(AddNewJobCard);
