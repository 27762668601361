import React from 'react'
import '../../Styles/Orders.css';
import Header from '../header/Header';
import Sidebar from '../Sidenav/Sidebar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';



const Analytics = () => {

    return (
        <>
            <Header />
            <Container fluid className='containerr'>
                <Row>
                    <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
                        <Sidebar />
                    </Col>
                    <Col lg={10} sm={4} className='containerr'>
                        <div>
                            <h1 className='fs-3 mx-5 mt-3'>Analytics</h1>
                            <Breadcrumb className='mx-5'>
                                <Breadcrumb.Item active>Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>Analytics</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Analytics;
