import { GET_USER_DETAILS } from "../actions/getuserDetailsAction";

const getuserDetailsReducer = (state = {}, {type, payload}) => {
  switch (type) {
    case GET_USER_DETAILS:
      return payload;
    default:
      return state;
  }
};
export default getuserDetailsReducer;