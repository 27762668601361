import httpService from "./httpService";


export async function getindents(obj) {
	
	const data = await httpService.post('/materialIndents/getAll',obj);
	return data;
}
export async function addindents(obj) {
	
	const data = await httpService.post('/materialIndents/add',obj);
	return data;
}


export default {
	getindents,
    addindents
}