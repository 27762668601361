import React, { useState, useEffect, useRef } from 'react';
import '../../Styles/JobCards.css';
import Header from '../header/Header';
import Sidebar from '../Sidenav/Sidebar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import filterIcon from '../../Images/filterIcon.png';
import shareIcon from '../../Images/shareIcon.png';
import sortIcon from '../../Images/sortIcon.png';
import calenderIcon from '../../Images/calenderIcon.png';
import UploadImageIcon from '../../Images/UploadImageIcon.png'
import SaveIcon from '../../Images/SaveIcon.png'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import uploadCloudImage from '../../Images/uploadCloudImage.png'
import { toast } from 'react-toastify';
import ReceiptService, { getreceipts, addreceipts } from '../../services/ReceiptService';
import ClientsService from '../../services/ClientsService';
import Multiselect from 'multiselect-react-dropdown';
import get_userdetails from '../../redux/actions/getuserDetailsAction';
import TrashIcon from '../../Images/TrashIcon.svg'





const Printing = (props) => {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let navigate = useNavigate();
    function editjob  () {
        navigate ('/editjob');
    };
    const [modalShow, setModalShow] = React.useState(false);
    const [updateOrderModalShow, setUpdateOrderModalShow] = React.useState(false);
    const [updatePaymentModalShow, setUpdatePaymentModalShow] = React.useState(false);
    const [updateDeliveryModalShow, setUpdateDeliveryModalShow] = React.useState(false);

    

     //get clients integration
     const [client, setClient] = useState();
     const [clients, setClients] = useState([]);
     const [user, setUserdata] = useState({})
     const multiselectRef = useRef();
 
     useEffect(() => {
         getuserdetails();
         getclients()
     }, [props.userdata])
     useEffect(() => {
         get_userdetails();
     }, [])
 
     const getuserdetails = async () => {
         setUserdata(props.userdata.user)
     }
     const selectemps = (value) => {
         setCname(value[0].name);
     }
 
     const getclients = async () => {
 
         try {
 
             const dert = await ClientsService.getclients();
             if (dert.data.status === 'success') {
                 console.log(dert.data.value, 'values');
                 setClients(dert.data.value);
             }
         } catch (ex) {
 
         }
     }
     //get clients integration close

    //add cash receipt Integration
    const [cname, setCname] = useState('');
    const [Cid, setCid] = useState('');
    const [email, setEmail] = useState('');
    const [phonecode, setPhoneCode] = useState('');
    const [phone, setPhone] = useState('');
    const [paytype, setPayType] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [transid, setTransID] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [invoiceid, setInvoiceID] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [orderId, setOrderID] = useState('');
    const [sumtotal, setSumTotal] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [pendingAmount, setPendingAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [subTotalAmount, setSubTotalAmount] = useState('');
    const [sbtndis, setSBtndis] = useState(false);
    const [estimatecalc, setEstimatecalc] = useState()

    const create = async () => {
        try {
            const obj = {
                "customerName": cname,
                "customerId": Cid,
                "email": email,
                "phone": phone,
                "gstNo": gstNo,
                "orderList": [
                    {
                        "orderNo": orderId,
                        "sum_total": sumtotal,
                        "paidAmount": paidAmount
                    },
                    {
                        "orderNo": orderId,
                        "sum_total": sumtotal,
                        "paidAmount": paidAmount
                    }
                ],
                "subTotalAmount": subTotalAmount,
                "pendingAmount": pendingAmount,
                "paidAmount": paidAmount,
                "totalAmount": totalAmount
            }

            const dert = await ReceiptService.addreceipts(obj);
            if (dert.data.status === 'success') {
                toast.success('Cash Receipt Added SuccessFully', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                navigate('/receipts')
                setModalShow(false)
            } else {

            }
        } catch (ex) {

        }
    }


    const submit = async () => {
        if (!cname) {
            await setSBtndis(false)
            toast.error('Select Firm Name', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        
        } else if (!phone) {
            await setSBtndis(false)
            toast.error('Enter Mobile Number', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }else if (phone.length < 10 && phone.length > 10) {
            await setSBtndis(false)
            toast.error('Invalid Phone', {
              position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!email) {
            await setSBtndis(false)
            toast.error('Enter Email', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!gstNo) {
            await setSBtndis(false)
            toast.error('Enter GST Number', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!paytype) {
            await setSBtndis(false)
            toast.error('Select Payment type', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!transid) {
            await setSBtndis(false)
            toast.error('Enter Transaction ID', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!date) {
            await setSBtndis(false)
            toast.error('Select Date', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!time) {
            await setSBtndis(false)
            toast.error('Select Time', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!invoiceid) {
            await setSBtndis(false)
            toast.error('Enter Invoice ID', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!paidAmount) {
            await setSBtndis(false)
            toast.error('Enter Received Amount', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!invoiceid) {
            await setSBtndis(false)
            toast.error('Enter Invoice ID', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else if (!paidAmount) {
            await setSBtndis(false)
            toast.error('Enter Received Amount', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else {
            try {

            } catch (ex) {

            }
        }
    }


    //add cash receipt Integration close

    return (
        <>
            <Header />
            <Container fluid className='containerr'>
                <Row>
                    <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
                        <Sidebar />
                    </Col>
                    <Col lg={10} sm={4} className='containerr'>
                            <div>
                                <h1 className='fs-3 mx-5 mt-3'>Printing</h1>
                                <Breadcrumb className='mx-5'>
                                    <Breadcrumb.Item active>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Printing</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <Row className='d-flex ms-2 me-2'>
                        <Col lg={12} sm={12} md={6} xs={12} className=' mb-5 datacenter'>
                            <Paper className='col-lg-12 col-sm-8 rounded-4'>
                                <Box sx={{}}>
                                <Row>
                                        <Col lg={4} sm={12} className='text-lg-start '>
                                        <h6 className='datahead text-muted'>Job Cards</h6>
                                        </Col>
                                        {/* categories starts */}
                                        <Col lg={8} sm={12}  className='text-center'>
                                            <Row>
                                        <Col lg={2} sm={6}>
                                            <Form className='mt-3 searchField'>
                                            <input className="form-control nosubmit" type="search" placeholder="Search" />
                                            </Form>
                                        </Col>
                                        {/* sort filter */}
                                        <Col lg={2} sm={6} >
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div >
                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                        Filter
                                                    </Button>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ p: 2 }}>
                                                            <h6>Filter</h6>
                                                            <h6>Order Type</h6>
                                                            <Form>
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`} className="mb-3">
                                                                        <Form.Check
                                                                            inline
                                                                            label="Home Delivery"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-1`}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            label="Pickup"
                                                                            name="group1"
                                                                            type={type}
                                                                            id={`inline-${type}-2`}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Form>
                                                            <hr className='text-muted'></hr> <hr className='text-muted'></hr>
                                                            <div>
                                                                <h6>Status</h6>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>All</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                                <h6>Client</h6>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>All</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                </Form.Select>
                                                                <Form>
                                                                    <Row>
                                                                        <Col>
                                                                            <Form.Label>From</Form.Label>
                                                                            <Form.Control className='filterFields' placeholder="0.00" />
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Label>To</Form.Label>
                                                                            <Form.Control placeholder="0.00" />
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                            </div>
                                                        </Typography>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                        </Col>
                                        {/* date filter */}
                                        <Col lg={2} sm={6}>
                                            <PopupState variant="popover" popupId="demo-popup-popover">
                                                {(popupState) => (
                                                    <div>
                                                        <Button id='sortfilter' className='sortFilter2 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                            <img className="menuIcon mx-1 mb-1" src={calenderIcon} />
                                                            Filter
                                                        </Button>
                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2 }}>
                                                                <h6 className='mb-3'>By Date</h6>
                                                                <Form>
                                                                    {['checkbox'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="This Week"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-1`}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Last Week"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-2`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                                <Form>
                                                                    {['checkbox'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="This Month"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-1`}
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Last Month"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-2`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                                <Form>
                                                                    {['checkbox'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="This Year"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-1`}
                                                                            />&nbsp;
                                                                            <Form.Check
                                                                                inline
                                                                                label="Last Year"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-2`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                                <hr className='text-muted'></hr>
                                                                <Form>
                                                                    {['checkbox'].map((type) => (
                                                                        <div key={`inline-${type}`} className="mb-3">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Date Range"
                                                                                name="group1"
                                                                                type={type}
                                                                                id={`inline-${type}-1`}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </Form>
                                                                <Nav id='navPill' variant="pills" defaultActiveKey="/home">
                                                                    <Nav.Item>
                                                                        <Nav.Link id='pillStyle' href="/home">From</Nav.Link>
                                                                    </Nav.Item>
                                                                    <Nav.Item>
                                                                        <Nav.Link id='pillStyle' eventKey="link-1">To</Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>
                                                                <Col lg={2} sm={2} md={2}>
                                                                    <DateRange
                                                                        editableDateInputs={true}
                                                                        onChange={item => setState([item.selection])}
                                                                        moveRangeOnFirstSelection={false}
                                                                        ranges={state}
                                                                    /></Col>
                                                                <Button className='w-100 mt-3' id='pillStyle' variant="primary">Filter</Button>{' '}
                                                            </Typography>
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </Col>
                                        {/* Export Excel */}
                                        <Col lg={3} sm={2}>
                                        <Button id='shareIcon' variant="outlined"><img className="menuIcon mx-1 mb-1" src={shareIcon} />
                                            Export Excel</Button>
                                        </Col>
                                        {/* Bulk Select */}
                                        <Col lg={3} sm={2}>
                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                            <option hidden className='text-center'>Bulk Action</option>
                                            <option value="1">Delete</option>
                                            <option value="2">Cancel</option>
                                        </Form.Select>
                                        </Col>
                                        </Row>
                                        </Col>
                                        {/* categories starts */}

                                    </Row>
                                    <Table responsive borderless>
                                        <thead>
                                            <tr className='theadborder'>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <Form.Check aria-label="option 1" /></div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Firm Name</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Order Date</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Quantity</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Order Type</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Order ID</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Print Files</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Delivery Date</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='table-headFilter'>
                                                        <span>Status</span>
                                                        <img className="menuIcon mx-1" src={sortIcon} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='tdata'>
                                            <tr onClick=''>
                                                <Form.Check aria-label="option 1" />
                                                <td>Janet Adeboya</td>
                                                <td>12 Aug 2022</td>
                                                <td>1116</td>
                                                <td>Calender</td>
                                                <td>9348fjr73</td>
                                                <td>Printing File</td>
                                                <td>24 Aug 2022</td>
                                                <td onClick={() => setUpdateDeliveryModalShow(true)}>Pre Printing</td>
                                            </tr>
                                            <tr onClick=''>
                                                <Form.Check aria-label="option 1" />
                                                <td>Janet Adeboya</td>
                                                <td>12 Aug 2022</td>
                                                <td>1116</td>
                                                <td>Calender</td>
                                                <td>9348fjr73</td>
                                                <td>Printing File</td>
                                                <td>18 Aug 2022</td>
                                                <td>Printing</td>
                                            </tr>
                                            <tr onClick=''>
                                                <Form.Check aria-label="option 1" />
                                                <td>Janet Adeboya</td>
                                                <td>12 Aug 2022</td>
                                                <td>1116</td>
                                                <td>Calender</td>
                                                <td>9348fjr73</td>
                                                <td>Printing File</td>
                                                <td>20 Aug 2022</td>
                                                <td>Post Printing</td>
                                            </tr>
                                            <tr onClick=''>
                                                <Form.Check aria-label="option 1" />
                                                <td>Janet Adeboya</td>
                                                <td>12 Aug 2022</td>
                                                <td>1116</td>
                                                <td>Calender</td>
                                                <td>9348fjr73</td>
                                                <td>Printing File</td>
                                                <td>12 Aug 2022</td>
                                                <td>Delivery</td>
                                            </tr>
                                            <tr onClick=''>
                                                <Form.Check aria-label="option 1" />
                                                <td>Janet Adeboya</td>
                                                <td>12 Aug 2022</td>
                                                <td>1116</td>
                                                <td>Calender</td>
                                                <td>9348fjr73</td>
                                                <td>Printing File</td>
                                                <td>24 Aug 2022</td>
                                                <td>Printing</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Row className='d-flex'>
                                        <Col><TablePagination
                                            component="div"
                                            count={100}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        /></Col>
                                    </Row>
                                </Box>
                            </Paper>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Printing Status                   
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Job Card ID*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" id='inputcolor' className="form-control text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" className="form-control text-muted required" id='inputcolor' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Order ID*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Order Name*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Order Type*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Quantity*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div><div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Size*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Paper Type*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>GSM*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <button type="button" class="btn btn-outline-primary">
                                                <img className="menuIcon mb-1 mx-1" src={UploadImageIcon} />
                                                Printfile.TTF/.EPS/.AI/.PSD/PDF
                                                <img className="menuIcon mb-1 mx-2" src={SaveIcon} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Status*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-6">
                                            <Button onClick={() => setModalShow (false)} id='pillStyle' className='w-100' variant="outline-primary">Back</Button>
                                        </div>
                                        <div className="col-lg-6">
                                            <Button as={NavLink} to='/printing' id='pillStyle' className='w-100' variant="primary">Update Status</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
            <Modal
                show={updateOrderModalShow}
                onHide={() => setUpdateOrderModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Order Status                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Order Name*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Firm Name*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control required"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        id='inputcolor'
                                                        maxLength={10}
                                                        placeholder="8023456789*"
                                                        aria-label="phone"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Email"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div><div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="GST No*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <h6 className=""><small>Printing Status</small></h6>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option  selected hidden>Status</option>
                                            <option className='fw-bolder' value="Printing Completed">Printing Completed</option>
                                            <option className='fw-bolder' value="Printing pending">Printing pending</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Remarks"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                   
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <button type="button" className="btn btn-outline-primary w-100">
                                                <img className="menuIcon mb-1 me-2" src={UploadImageIcon} />
                                                <img className="menuIcon mb-1 me-2" src={uploadCloudImage} />
                                                Upload Printed copy Reference                                             </button>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-lg-6">
                                            <Button onClick={() => setUpdateOrderModalShow (false)} id='pillStyle' className='w-100' variant="outline-primary">Back</Button>
                                        </div>
                                        <div className="col-lg-6">
                                            <Button as={NavLink} to='/printing' id='pillStyle' className='w-100' variant="primary">Save</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
            <Modal
                show={updatePaymentModalShow}
                onHide={() => setUpdatePaymentModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Printing Status                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <Multiselect
                                                className='fs-14'
                                                placeholder='Select Firm Name*'
                                                options={clients}
                                                id='inputcolor'
                                                selectionLimit={1}
                                                singleSelect={true}
                                                emptyRecordMsg={'Client Not Found'}
                                                selectedValues={client}
                                                defaltValue={client}
                                                displayValue="name"
                                                ref={multiselectRef}
                                                onSelect={(value) => selectemps(value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            
                                            <div className="col-lg-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control required"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        id='inputcolor'
                                                        maxLength={10}
                                                        placeholder="9876543210*"
                                                        aria-label="phone"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Email*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                value={gstNo}
                                                onChange={(e) => setGstNo(e.target.value)}
                                                placeholder="GST No*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option value={paytype} onChange={(e) => setPayType(e.target.value)} selected hidden>Payment Type*</option>
                                            <option value="CASH">CASH</option>
                                            <option value="CHEQUE">CHEQUE</option>
                                            <option value="PHONEPE">PHONEPE</option>
                                            <option value="PHONEPE">BANK TRANSFER</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                value={transid}
                                                onChange={(e) => setTransID(e.target.value)}
                                                id='inputcolor'
                                                placeholder="Transaction ID*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} id='inputcolor' className="form-control text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" value={time} onChange={(e) => setTime(e.target.value)} className="form-control text-muted required" id='inputcolor' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    name="size"
                                                    id='inputcolor'
                                                    value={invoiceid} onChange={(e) => setInvoiceID(e.target.value)}
                                                    placeholder="Invoice ID*"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    name="size"
                                                    id='inputcolor'
                                                    value={paidAmount} onChange={(e) => setPaidAmount(e.target.value)}
                                                    placeholder="Amount Received*"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 mt-1">
                                            <img className="mt-2 mx-1" src={TrashIcon} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    name="size"
                                                    id='inputcolor'
                                                    value={invoiceid} onChange={(e) => setInvoiceID(e.target.value)}
                                                    placeholder="Invoice ID*"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    name="size"
                                                    id='inputcolor'
                                                    value={paidAmount} onChange={(e) => setPaidAmount(e.target.value)}
                                                    placeholder="Amount Received*"
                                                    aria-label="name"
                                                    aria-describedby="basic-addon1"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 mt-1">
                                            <img className="mt-2 mx-1" src={TrashIcon} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-group">
                                            <Nav.Item id="navAccord" className="navLink">
                                                <Nav.Link as={NavLink} to='' className="text-primary fs-6 mb-2 p-0">+Add New Invoice</Nav.Link>
                                            </Nav.Item>
                                        </div>
                                    </div>
                                    <h4 className='fs-5 mb-3'>Payment Summary</h4>
                                    <div className="row mt-2">
                                        <div className="col-lg-8 text-muted">
                                            Invoice ID
                                        </div>
                                        <div className="col-lg-4">
                                            ₹ 10,500.00
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-8 text-muted">
                                            Invoice ID
                                        </div>
                                        <div className="col-lg-4">
                                            ₹ 4,500.00
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-8 text-success">
                                            Sub Total
                                        </div>
                                        <div className="col-lg-4 text-success">
                                            ₹ 15,000.00
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-lg-8 text-danger">
                                            Pending Amount
                                        </div>
                                        <div className="col-lg-4 text-danger">
                                            ₹ 10,000.00
                                        </div>
                                    </div>
                                    <hr className='text-muted'></hr>
                                    <div className="row mt-2">
                                        <div className="col-lg-8 text-primary">
                                            Total
                                        </div>
                                        <div className="col-lg-4 text-primary">
                                            ₹ 25,000.00
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-5">
                                            <Button onClick={() => setModalShow(false)} id='pillStyle' className='w-100' variant="outline-primary">Back</Button>
                                        </div>
                                        <div className="col-lg-6">
                                            <Button onClick={() => create()} id='pillStyle' className='' variant="primary">Update Payment</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
            <Modal
                show={updateDeliveryModalShow}
                onHide={() => setUpdateDeliveryModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Delivery Status                   </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className="row">
                                <div className="form-group col-lg-12 col-md-12 col-12">
                                <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Order Name*</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Firm Name*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control required"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        id='inputcolor'
                                                        maxLength={10}
                                                        placeholder="8023456789*"
                                                        aria-label="phone"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Email"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div><div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="GST No*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <h6 className=""><small>Printing Status</small></h6>
                                    </div>
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option  selected hidden>Status</option>
                                            <option className='fw-bolder' value="Printing pending">Ready For Delivery</option>
                                            <option className='fw-bolder' value="Printing Completed">Printing Completed</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <h6 className=""><small>Delivery Details</small></h6>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="date" id='inputcolor' className="form-control text-muted required" name="date " />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input type="time" className="form-control text-muted required" id='inputcolor' name="time" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Address*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="input-group">
                                        <select class="form-select text-muted mb-3 " id='inputcolor' aria-label="Default select example">
                                            <option selected hidden>Status*</option>
                                            <option value="Completed">Completed</option>
                                            <option value="Pending">Pending</option>
                                            <option value="In Progress">In Progress</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <button type="button" className="btn btn-outline-primary w-100">
                                                <img className="menuIcon mb-1 me-2" src={UploadImageIcon} />
                                                <img className="menuIcon mb-1 me-2" src={uploadCloudImage} />
                                                Upload Delivery Picture                                             </button>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-lg-6">
                                            <Button onClick={() => setModalShow (false)} id='pillStyle' className='w-100' variant="outline-primary">Back</Button>
                                        </div>
                                        <div className="col-lg-6">
                                            <Button as={NavLink} to='/printing' id='pillStyle' className='w-100' variant="primary">Save</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Printing;
