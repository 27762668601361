import httpService from "./httpService";


export async function getlamination(obj) {
	
	const data = await httpService.post('/masterData/laminations/getAll',obj);
	return data;
}
export async function addlamination(obj) {
	
	const data = await httpService.post('/masterData/laminations/add',obj);
	return data;
}

export default {
	getlamination,
	addlamination
}