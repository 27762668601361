import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Sidebar from "../Sidenav/Sidebar";
import OrderTracking from "../OrderTracking/OrderTracking";
import printEstimate from "../OrderTracking/printEstimate";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import filterIcon from "../../Images/filterIcon.png";
import eyeIcon from "../../Images/eyeIcon.svg";
import editIcon from "../../Images/editIcon.svg";
import shareIcon from "../../Images/shareIcon.png";
import calenderIcon from "../../Images/calenderIcon.png";
import sortIcon from "../../Images/sortIcon.png";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Table from "react-bootstrap/Table";
import TablePagination from "@mui/material/TablePagination";
import { NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import aretsLogo from "../../Images/aretsLogo.png";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import authService from "../../services/authService";
import Moment from "moment";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import get_userdetails from "../../redux/actions/getuserDetailsAction";
import { connect } from "react-redux";
import ClientsService from "../../services/ClientsService";
import estimateService from "../../services/estimateService";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { data } from "react-phone-code";
import moment from "moment";
import { DayPicker } from "@mui/x-date-pickers/CalendarPicker/DayPicker";
import { DatePicker } from "@mui/x-date-pickers";

const Estimates = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [order, setOrder] = useState();
  const [upstatus, setUpstatus] = useState();
  let navigate = useNavigate();

  const editestimate = (item) => { 
    console.log(item, "estimTEITEM");
    navigate("/neweditestimate", { state: { item: item } });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [estcount, setEstcount] = useState(0);
  const [searchtext, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [filtstatus, setFiltStatus] = useState("");
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [otype, setOtype] = useState("");
  const [dates, setDates] = useState([]);
  const [datafilter, setDataFilter] = useState(false);
  const [datefilter, setDateFilter] = useState(false);
  const [selstatus, setSelstatus] = useState("");
  const [selcname, setSelCname] = useState("");
  const [datetab, setDateTab] = useState("From");

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    const dett = newPage * rowsPerPage;
    setPage(newPage);
    getestimates(dett, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getestimates(0, event.target.value);
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [printmodalShow, setPrintModalShow] = React.useState(false);

  const [estimates, setEstimates] = useState([]);
  useEffect(() => {
    getestimates(0, 10);
    resetorder(0,10)
  }, []);

  const resetorder = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: '',
      };
      const dert = await authService.getestimates(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setEstimates(dert.data.value);
        setEstcount(dert.data.count);
      }
    } catch (ex) {}
  };

  const getestimates = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: searchtext,
      };
  
      // Define the filter criteria
      const filterCriteria = {
        status: filtstatus,
        customerName: cname,
        fromAmount: fromAmount,
        toAmount: toAmount,
        fromDate: fromdate,
        toDate: todate
      };
  
      // Construct the filterBy object based on non-empty filter criteria
      const filterBy = Object.entries(filterCriteria).reduce((acc, [key, value]) => {
        if (value !== "") {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {});
  
      // Only include filterBy when it is not empty
      if (Object.keys(filterBy).length > 0) {
        obj.filterBy = filterBy;
      }
  
      const dert = await authService.getestimates(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setEstcount(dert.data.count);
        setEstimates(dert.data.value);
      }
    } catch (ex) {
      // Handle error
    }
  };

  
  // const filterBy = {
  //   status: filtstatus,
  //   customerName: cname,
  //   fromAmount: fromAmount,
  //   toAmount: toAmount,
  //   fromDate: fromdate,
  //   toDate: todate
  // };
  
  
  
  
  

  const [orders, setOrders] = useState([]);
  useEffect(() => {
    getorders(0, 10);
  }, []);

  const getorders = async (skps, rpp) => {
    try {
      const obj = {
        skip: skps,
        limit: rpp,
        searchkeys: "",
      };
      const dert = await authService.getorders(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setOrders(dert.data.value);
        // setOrderCount(dert.data.count)
      }
    } catch (ex) {}
  };
  const handleClick = (item) => {
    console.log(item, "orderitem");
    navigate("/printestimate", { state: { item: item } });
    // navigate('/editestimate', { state: { item: item } });
  };

  const [cname, setCname] = useState("");
  const [client, setClient] = useState();
  const [clients, setClients] = useState([]);
  const [user, setUserdata] = useState({});
  const multiselectRef = useRef();

  useEffect(() => {
    getuserdetails();
    getclients();
  }, [props.userdata]);
  useEffect(() => {
    get_userdetails();
  }, []);

  const getuserdetails = async () => {
    setUserdata(props.userdata.user);
  };
  const selectemps = (value) => {
    setCname(value[0].name);
  };

  const getclients = async () => {
    try {
      const dert = await ClientsService.getclients();
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setClients(dert.data.value);
      }
    } catch (ex) {}
  };

  //date Range
  const [isHidden, setIsHidden] = useState(true);
  //date Range close

  // download Excel

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadExcel = async () => {
    try {
      setIsDownloading(true);
      const requestData = {
        // Add your request object properties here
        // For example:
        searchkeys: searchtext,
        "filterBy": {
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          fromDate: fromdate,
          toDate: todate,
        }
      };
      const response = await axios.post(
        "/estimate/jsontoexcel",
        requestData,
        { responseType: "blob" }
      );
  
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(excelBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Estimates.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };
  

  //  Download Excel Close

  const confirmorder = async () => {
    try {
      const obj = {
        confirmedBy: props.userdata.user._id,
        estmateId: order._id,
      };
      console.log(obj, "obj");
      const drty = await estimateService.confirmorder(obj);

      if (drty.status === 200) {
        // navigate('/orders')
        setModalShow(false);
        getestimates();
        toast.success("Order Confirmed Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {}
  };
  const vieworder = (item) => {
    console.log("item==", item);
    setOrder(item);
    setModalShow(true);
  };
  // const handleClick = (item) => {
  //     console.log('item==', item)
  //     setOrder(item)
  // }
  // const handleStatus = (e,item) => {
  //     console.log(item,'e.target.value');
  //     try {
  //         const obj ={
  //             "orderId": "ORD222300034",
  //             "status": e.target.value
  //         }
  //         console.log(obj,'obj');
  //     } catch (ex) {

  //     }
  // }
  console.log("order============", order);

  // const handlePrint = () => {
  //     window.print([<OrderTracking />]);
  // }

  //pdf
  const payref = useRef();
  const [tab, setTab] = useState("Estimate Confirmation");
  const [payview, setpayview] = useState(true);
  // const [visdata,setVisData] = useState(false)
  const savepdf = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "in", [13, 30]);
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("Estimate Confirmation.pdf");
    });
  }; //pdf close
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      serachclick();
     
    }
  };
  const searchChange = (value) => {
    setSearchText(value);
    // getestimates(0,10)
  };
  const serachclick = async () => {
    // getestimates(0, 10)
    const trimmedSearchText = searchtext.trim(); 
    if (trimmedSearchText === "") {
      toast.error("Enter Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
   
    }
    else{
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,
        searchkeys: trimmedSearchText,
        // filterBy: {
        //   status: filtstatus,
        //   customerName: cname,
        //   fromAmount: fromAmount,
        //   toAmount: toAmount,
        //   fromDate: "2022-07-17",
        //   toDate: "2023-02-15",
        // },
      };
      const dert = await authService.getestimates(obj);
      if (dert.data.status === "success") {
        setSearch(true);
        console.log(dert.data.value, "values");
        setEstcount(dert.data.count);
        setEstimates(dert.data.value);
      }
    } catch (ex) {}
    }
  };
  const resetclick = () => {
    setSearchText("");
      setSearch(false)
      resetorder(0,rowsPerPage);
  }
  const resclick = () => {
    filterbydata("");
    setFiltStatus("");
    setCname('')
    setFromAmount("");
    setToAmount("");
    setFromDate("");
    setToDate("");
    setDataFilter(false);
    setDateFilter(false);
    resetorder(0,rowsPerPage);
  };
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     serachclick();
  //   }
  // };
  const handleordertype = (value) => {
    setOtype(value);
  };
  const filterbydata = async () => {
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,

        filterBy: {
          // "homeDelivery": true,
          status: filtstatus,
          customerName: cname,
          fromAmount: fromAmount,
          toAmount: toAmount,
          // "fromDate":"2022-07-17",
          // "toDate":"2023-02-15"
        },
      };
      const dert = await authService.getestimates(obj);
      if (dert.data.status === "success") {
        // setFiltStatus("");
        // setFromAmount("");
        // setToAmount("");
        setDataFilter(false);
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setEstcount(dert.data.count);
        setEstimates(dert.data.value);
        // getestimates(0, 10);
      }
    } catch (ex) {}
  };
  const clickfiltdate = async () => {
    try {
      const obj = {
        skip: 0,
        limit: rowsPerPage,

        filterBy: {
          fromDate: fromdate,
          toDate: todate,
        },
      };
      const dert = await authService.getestimates(obj);
      if (dert.data.status === "success") {
        // setFiltStatus("");
        // setFromDate("");
        // setToDate("");
        setDateFilter(false);
        // setSearch(true)
        // console.log(dert.data.value, 'values');
        setEstcount(dert.data.count);
        setEstimates(dert.data.value);
      }
    } catch (ex) {}
  };
  const filtdrop = () => {
    setDataFilter(!datafilter);
  };
  const datefiltdrop = () => {
    setDateFilter(!datefilter);
  };
  const onDateChange = (e) => {
    console.log(e, "datevalue");
    setFromDate(e);
  };
  const onToDateChange = (e) => {
    setToDate(e);
  };
  const activetabclick = (tab) => {
    setDateTab(tab);
  };
  console.log(fromdate,todate,);
  console.log(dates, "dates");
  return (
    <>
      <Header />
      <Container fluid className="containerr estimates">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={10} sm={4} className="containerr px-3 px-lg-4">
            <div className="pt-4 pb-2 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fs-3">Estimates</h1>
                <nav aria-label="breadcrumb" className="fs-14">
                  <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                      <a href="/dashboard">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Estimates
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="pt-sm-4">
                <Button
                  as={NavLink}
                  to="/newestimate"
                  id="pillStyle"
                  className="btnnew"
                  variant="primary"
                >
                  Create New Estimate
                </Button>
              </div>
            </div>
            <Row className="d-lg-flex">
              <Col lg={12} sm={12} md={6} xs={12} className="mb-5 datacenter">
                <Paper className="col-lg-12 col-sm-8 rounded-4">
                  <Box sx={{}}>
                    <Row>
                      <Col lg={4} sm={12} className="text-lg-start">
                        <h6 className="datahead text-muted">Customer Orders</h6>
                      </Col>
                      {/* categories starts */}
                      <Col lg={8} sm={12} className="px-4 pb-4">
                        <Row>
                          <Col lg={5} xs={12}>
                            <Form className="mt-3 d-flex gap-2 searchField">
                              <div className="col-lg-8 col-sm-8">
                                <input
                                  value={searchtext}
                                  onChange={(e) => searchChange(e.target.value)}
                                  onKeyDown={handleKeyDown}
                                  className="form-control"
                                  style={{ fontSize: "14px" }}
                                  type="search"
                                  placeholder="Search Firm Name"
                                />
                              </div>
                              <div className="col-lg-4 col-sm-4">
                                {!search ? (
                                  <Button
                                    onClick={() => serachclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Search
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => resetclick()}
                                    className="w-100 sortFilter1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                )}
                                {/* <span className="searchcont" onClick={() => serachclick()} style={{cursor:'pointer'}}><i className="fa fa-search empsearch"></i></span> */}
                              </div>
                            </Form>
                          </Col>
                          {/* sort filter */}
                          <Col lg={2} xs={4} className="px-1">
                          
                            <Button
                              onClick={() => filtdrop()}
                              id="sortfilter"
                              className="sortFilter1 w-100 mt-3"
                              variant="contained"
                            >
                              <img className="menuIcon mx-1" src={filterIcon} />
                              Filter
                            </Button>  
                                 
                                
                            {datafilter === true ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "40px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  zIndex:1 

                                }}
                              >
                              <Card>
                                <CardContent>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  <h6>Filter</h6>

                                  <div>
                                    <h6 className="mt-2 fs-13">Status</h6>
                                    <Form.Select
                                      value={filtstatus}
                                      onChange={(e) =>
                                        setFiltStatus(e.target.value)
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option selected hidden>All</option>
                                      <option value="Estimate-Created">
                                      Estimate Created
                                      </option>
                                      <option value="Order-Confirmed">
                                      Order Confirmed
                                      </option>
                                     
                                    </Form.Select>
                                    <h6 className="mt-2 fs-13">Customer</h6>
                                    <Multiselect
                                      placeholder="Select Firm Name"
                                      className="border rounded"
                                      options={clients}
                                      selectionLimit={1}
                                      singleSelect={true}
                                      emptyRecordMsg={"Customer Not Found"}
                                      selectedValues={client}
                                      defaltValue={client}
                                      displayValue="name"
                                      ref={multiselectRef}
                                      onSelect={(value) => selectemps(value)}
                                    />
                                    <h6 className="mt-2 fs-14">Amount</h6>
                                    <Form>
                                      <Row>
                                        <Col>
                                          <Form.Label>From</Form.Label>
                                          <Form.Control
                                            value={fromAmount}
                                            onChange={(e) =>
                                              setFromAmount(e.target.value)
                                            }
                                            type="number"
                                            className="filterFields"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                        <Col>
                                          <Form.Label>To</Form.Label>
                                          <Form.Control
                                            value={toAmount}
                                            onChange={(e) =>
                                              setToAmount(e.target.value)
                                            }
                                            type="number"
                                            placeholder="0.00"
                                          />
                                        </Col>
                                      </Row>
                                    </Form>
                                    <div className="d-flex">
                                    <Button
                                      onClick={() => filterbydata()}
                                      id="pillStyle"
                                      className="w-100 mt-3"
                                      variant="primary"
                                    >
                                      Filter
                                    </Button>{" "}
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                  </div>
                                </Typography>
                                </CardContent>
                                </Card>
                              </div>
                            ) : null}
                            {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                            {(popupState) => (
                                                                <div >
                                                                    <Button id='sortfilter' className='sortFilter1 mt-3' variant="contained" {...bindTrigger(popupState)}>
                                                                        <img className="menuIcon mx-1" src={filterIcon} />
                                                                        Filter
                                                                    </Button>
                                                                    <Popover
                                                                        {...bindPopover(popupState)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography sx={{ p: 2 }} className='col-lg-12'>
                                                                            <h6>Filter</h6>
                                                                         
                                                                            <div>
                                                                                <h6 className='mt-2 fs-13'>Status</h6>
                                                                                <Form.Select aria-label="Default select example">
                                                                                    <option>All</option>
                                                                                    <option value="ESTIMATE">ESTIMATE</option>
                                                                                    <option value="ORDER-CONFIRMED">ORDER-CONFIRMED</option>
                                                                                    <option value="JOB-Create">JOB-Create</option>
                                                                                    <option value="DELIVERED">DELIVERED</option>
                                                                                    
                                                                                </Form.Select>
                                                                                <h6 className='mt-2 fs-13'>Client</h6>
                                                                                <Multiselect
                                                                                    placeholder='Select Firm Name'
                                                                                    className='border rounded'
                                                                                    options={clients}
                                                                                    selectionLimit={1}
                                                                                    singleSelect={true}
                                                                                    emptyRecordMsg={'Client Not Found'}
                                                                                    selectedValues={client}
                                                                                    defaltValue={client}
                                                                                    displayValue="name"
                                                                                    ref={multiselectRef}
                                                                                    onSelect={(value) => selectemps(value)}
                                                                                />
                                                                                <h6 className='mt-2 fs-14'>Amount</h6>
                                                                                <Form>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>From</Form.Label>
                                                                                            <Form.Control type='number' className='filterFields' placeholder="0.00" />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Form.Label>To</Form.Label>
                                                                                            <Form.Control type='number' placeholder="0.00" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form>
                                                                                <Button id='pillStyle' className='w-100 mt-3' variant="primary">Filter</Button>{' '}
                                                                            </div>
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            )}
                                                        </PopupState> */}
                          </Col>
                          {/* date filter */}
                          <Col lg={2} xs={6} className="px-1">
                            <Button
                              onClick={() => datefiltdrop()}
                              id="sortfilter"
                              className="sortFilter1 w-100 mt-3"
                              variant="contained"
                            >
                              <img
                                className="menuIcon mx-1"
                                src={calenderIcon}
                              />
                              Date
                            </Button>
                            {datefilter ? (
                              <div
                                style={{
                                  position: "absolute",
                                  backgroundColor: "white",
                                  right: "40px",
                                  boxShadow: "1px 2px 9px #F4AAB9",
                                  zIndex:1
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "From"
                                          ? "#0d6efd"
                                          : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("From")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "From"
                                            ? "white"
                                            : "black",
                                      }}
                                      id="pillStyle"
                                      href="#dateRange"
                                    >
                                      From
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor:
                                        datetab === "To" ? "#0d6efd" : "white",
                                      cursor: "pointer",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => activetabclick("To")}
                                    className="col-lg-6 text-center"
                                  >
                                    <p
                                      style={{
                                        color:
                                          datetab === "To" ? "white" : "black",
                                      }}
                                      id="pillStyle"
                                      eventKey="link-2"
                                    >
                                      To
                                    </p>
                                  </div>
                                </div>
                                <Typography sx={{ p: 2 }} className="col-lg-12">
                                  {datetab === "From" ? (
                                    <Calendar
                                      onChange={onDateChange}
                                      value={fromdate}
                                    />
                                  ) : (
                                    <Calendar
                                      onChange={onToDateChange}
                                      value={todate}
                                    />
                                  )}
                                  <div className="d-flex">
                                  <Button
                                    onClick={() => clickfiltdate()}
                                    id="pillStyle"
                                    className="w-100 mt-3"
                                    variant="primary"
                                  >
                                    Filter
                                  </Button>
                                    <Button
                                    onClick={() => resclick()}
                                    id="pillStyle"
                                    className="w-100 mt-3 ms-1"
                                    variant="primary"
                                  >
                                    Reset
                                  </Button>
                                  </div>
                                </Typography>
                              </div>
                            ) : null}
                          </Col>
                          {/* Export Excel */}
                          <Col lg={3} xs={4} className="px-1">
                            <Button
                              onClick={handleDownloadExcel}
                              disabled={isDownloading}
                              id="shareIcon"
                              variant="outlined"
                              className="w-100"
                            >
                              <img
                                className="menuIcon mx-1 mb-1"
                                src={shareIcon}
                              />
                              {isDownloading ? "Downloading" : "Export Excel"}
                            </Button>
                          </Col>
                          {/* Bulk Select */}
                          {/* <Col lg={2} sm={2} className='ps-1 pe-1'>
                                                        <Form.Select id='selectBtn' aria-label="Default select example">
                                                            <option hidden className='text-center'>Bulk Action</option>
                                                            <option value="1">Delete</option>
                                                            <option value="2">Cancel</option>
                                                        </Form.Select>
                                                    </Col> */}
                                                </Row>
                                            </Col>
                                            {/* categories starts */}

                                        </Row>
                                        <Table responsive borderless>
                                            <thead>
                                                <tr className='theadborder'>
                                                    <th>
                                                        <div className='table-headFilter ps-2'>
                                                            <span>#</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Estimate ID</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Firm Name</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Area</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Estimate Date</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Quantity</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='table-headFilter'>
                                                            <span>Order Type</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th>

                          <th>
                            <div className="table-headFilter">
                              <span>Order Total</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Created By</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                          {/* <th>
                                                        <div className='table-headFilter'>
                                                            <span>Action</span>
                                                            <img className="menuIcon mx-1" src={sortIcon} />
                                                        </div>
                                                    </th> */}
                          <th>
                            <div className="table-headFilter">
                              <span>Status</span>
                              <img className="menuIcon mx-1" src={sortIcon} />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tdata">
                        {estimates && estimates.length > 0
                          ? estimates.map((k, index) => {
                              return (
                                <tr>
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  <td className="ps-3">{index + 1 + (page * rowsPerPage)}. </td>
                                  <td>{k.estimateId}</td>
                                  <td>{k.customerName}</td>
                                  <td>{k.address}</td>
                                  <td>
                                    {Moment(k.createDate).format("DD MMM YYYY")}
                                  </td>
                                  <td>{k.quantity + k.extraquantity}</td>
                                  <td>{k.orderType}</td>
                                  <td>
                                    {k.sum_total
                                      ? k.sum_total.toFixed(2)
                                      : k.sum_total}
                                  </td>
                                  {/* <td>{k.sum_total ? k.sum_total.toFixed(2) : k.sum_total}</td> */}
                                  {/* <td className='accordionsize'> <Form.Select value={k.status} onChange={(e) => handleStatus(e,k)} id='accordion-body' aria-label="Default select example">
                                                                    <option value="ESTIMATE">Estimate</option>
                                                                    <option value="ORDER-CONFIRMED">Order Confirmed</option>
                                                                    <option value="JOB-Create">Job Created</option>
                                                                    <option value="PRINTED">Printed</option>
                                                                    <option value="DELIVERED">Delivered</option>
                                                                </Form.Select></td> */}
                                  <td>{k.status === "Estimate-Created" ? 'Estimate Created' : 'Order Confirmed'}</td>
                                  <td onClick={() => handleClick(k, order)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={eyeIcon}
                                    />
                                    view
                                  </td>
                                  {k.status === "Estimate-Created" ? 
                                  (<td onClick={() => editestimate(k)} className="hand-cursor">
                                    <img
                                      className="menuIcon mx-1"
                                      src={editIcon}
                                    />
                                    Edit
                                  </td>) : null
                                  }
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </Table>
                    <Row className="d-flex">
                      <Col>
                        <TablePagination
                          component="div"
                          count={estcount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Col>
                    </Row>
                  </Box>
                </Paper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{ width: '35%', marginLeft: '30%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Estimate Confirmation                    </Modal.Title>
                </Modal.Header>
                {order && order ?
                <Modal.Body>
                    <Card className=" rounded-4">
                        <CardContent>
                            <div className='d-flex mb-4'>
                                <img
                                    src={aretsLogo}
                                    alt='branding'
                                    className='loginPage-brandImg'
                                    onClick={() => window.location.reload()}
                                />
                                <h3 style={{ fontFamily: 'Yeseva One', fontWeight: '400' }}>AReTS Graphics
                                    <p style={{ fontFamily: 'Clash Display', fontSize: '22px' }}>Everything in Printing</p>
                                </h3>
                            </div>
                            <div className='h6FontSize'>
                                <h6><small>15-4-64, Gowliguda Chaman, Hyderabad 500012</small></h6>
                                <h6><small>Phone: 040-9393009990</small></h6>
                                <h6><small>GSTIN: 36AEYPM2529L1ZR</small></h6>
                                <h5 className='mt-3'>Client Details</h5>
                                <h6><small>1-43, Hanuman Nagar, Silpa Park, Kothaguda, Telangana 506135</small></h6>
                                <h6><small>Phone: 040-9393009990</small></h6>
                                <h6><small>GSTIN: 56AFMCG3692L1XV</small></h6>
                                <h5 className='mt-3'>Order Details</h5>
                                <h6><small>30x20, GSM50, Color Laser, embossed, Laminated</small></h6>
                                <h6><small>2000 Copies</small></h6>
                                <h6><small>Delivery Type: Address</small></h6>
                                <h5 className='mt-3'>Cost Estimate</h5>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-8 text-muted fs-14">
                                    Cost
                                </div>
                                <div className="col-lg-4 fs-14 fw-semibold">
                                    ₹ 1,122.00
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-8 text-muted fs-14">
                                    Delivery
                                </div>
                                <div className="col-lg-4 fs-14 fw-semibold">
                                    ₹ 9,457.00
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-8 text-muted fs-14">
                                    Sub Total
                                </div>
                                <div className="col-lg-4 fs-14 fw-semibold">
                                    ₹ 28,563.00
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-8 text-muted fs-14">
                                    Discount %
                                </div>
                                <div className="col-lg-4 fs-14 fw-semibold">
                                    ₹ 5,713.00
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row mt-2">
                                <div className="col-lg-8 text-muted fs-14">
                                    Total
                                </div>

                                <div className="col-lg-4 fw-semibold fs-14" style={{ textAlign: 'right' }}>
                                    ₹ 34,276.00
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <span style={{ fontSize: '11px' }}>* 18% GST Extra</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-6">
                                    <Button as={NavLink} to='/estimates' id='pillStyle' className='w-100' variant="outline-primary">Cancel</Button>
                                </div>
                                <div className="col-lg-6">
                                    <Button onClick={() => confirmorder()}  id='pillStyle' className='w-100' variant="primary">Confirm Order</Button>
                                </div>
                            </div>
                            <div className="form-group col-lg-12 col-md-12 col-12">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="input-group">
                                            <Nav.Item id="navAccord" className="navLink">
                                                <Nav.Link as={NavLink} to='/OrderTracking' className="w-100 text-primary text-center mt-4 p-0">Print Order Confirmation</Nav.Link>
                                            </Nav.Item>                            </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="input-group">
                                            <Nav.Item id="navAccord" className="navLink">
                                                <Nav.Link as={NavLink} to='/createjob' className="w-100 text-primary text-center mt-4 p-0">Create Job Card</Nav.Link>
                                            </Nav.Item>                            </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Modal.Body>: null}    
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>  */}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Estimate Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
              {/* {tab === "Order Confirmation" ?
                                order ? */}
                            <div>
                                <div className='d-flex mb-4' >
                                    <img
                                        src={aretsLogo}
                                        alt='branding'
                                        className='loginPage-brandImg'
                                        onClick={() => window.location.reload()}
                                    />
                                    <h3 style={{ fontFamily: 'Yeseva One', fontWeight: '400' }}>AReTS Graphics
                                        <p style={{ fontFamily: 'Clash Display', fontSize: '22px' }}>Everything in Printing</p>
                                    </h3>
                                </div>
                                <div className='h6FontSize'>
                                    <h6><small>15-4-64, Gowliguda Chaman, Hyderabad 500012</small></h6>
                                    <h6><small>Phone: 040-9393009990</small></h6>
                                    <h6><small>GSTIN: 36AEYPM2529L1ZR</small></h6>
                                    <h5 className='mt-3'>Client Details</h5>
                                    <h6><small>Firm Name: {order ? order.customerName : null} </small></h6>
                                    <h6><small>Phone: {order ? order.customerId.phoneNo : null}</small></h6>
                                    <h6><small>GSTIN: {order ? order.customerId.gstNo : null}</small></h6>
                                    <h5 className='mt-3'>Order Details</h5>
                                    <h6><small>{order ? order.jobDetails : null}</small></h6>
                                    <h6><small>{order ? order.quantity : null} Copies</small></h6>
                                    <h6><small>Delivery Type:{order ? order.orderType : null} </small></h6>
                                    <h5 className='mt-3'></h5>

                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-8 text-muted fs-14">
                                        Designing Amount
                                    </div>
                                    <div className="col-lg-4 fs-14 fw-semibold">
                                        ₹ {order ? order.designingAmount : null}
                                    </div>
                                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">Paper Amount</div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.paperAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">Print Amount</div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.PrintAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Lamination Amount
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.laminationAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">Die Amount</div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.dieAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Punching Amount
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.punchingAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Pasting Amount
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.pastingAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Rimming Amount
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.rimmingAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Binding Amount
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.bindingAmount : null}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">
                    Packing Amount
                  </div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.packingAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">Sub Total</div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.totalAmount : null}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">Misc</div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.miscAmount : null}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-8 text-muted fs-14">Total</div>
                  <div className="col-lg-4 fs-14 fw-semibold">
                    ₹ {order ? order.sum_total : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <span style={{ fontSize: "11px" }}>* 18% GST Extra</span>
                  </div>
                </div>
              </div>
              {/* :
                                    <p style={{ fontWeight: 500, marginTop: '40px', fontSize: '14px', textAlign: 'center' }}>There is no Receipt to Print</p>
                                : null
                            } */}

              <div className="row mt-3">
                {/* <div className="col-lg-6">
                                      <Button as={NavLink} to='/estimates' id='pillStyle' className='w-100' variant="outline-primary">Cancel</Button>
                                  </div> */}
                {order && order.status === "Estimate-Created" ? (
                  <div className="col-lg-12">
                    <Button
                      onClick={() => confirmorder()}
                      id="pillStyle"
                      className="w-100"
                      variant="primary"
                    >
                      Confirm Order
                    </Button>
                  </div>
                ) : null}
              </div>
              <div className="row ">
                <div className="col-lg-12 ">
                  <Button
                    onClick={() => handleClick()}
                    id="pillStyle"
                    className="w-100 text-center mt-4"
                    variant="primary"
                  >
                    Print Order Confirmation
                  </Button>
                </div>
                {/* <div className="col-lg-5">
                                          <div className="input-group">  
                                              <Nav.Item id="navAccord" className="navLink">
                                                  <Nav.Link as={NavLink} to='/createjob' className="w-100 text-primary text-center mt-4 p-0">Create Job Card</Nav.Link>
                                              </Nav.Item>                            </div>
                                      </div> */}
              </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
      {/* <Modal

                show={printmodalShow}
                onHide={() => setPrintModalShow(false)}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // style={{ width: '35%', marginLeft: '30%' }}
            >

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Order Booking
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Card className=" rounded-4">
                        <CardContent id="capture" ref={payref}>
                                    <section >

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-md-12">
                                                    <div className="d-flex">
                                                        <div className='col-lg-8 d-flex mb-2 mt-5'>
                                                            <img src="./images/aretsLogo.png" alt='branding' className='printmachine' />
                                                            <h3 style={{ fontWeight: '400px' }}>AReTS Graphics
                                                                <p style={{ fontSize: '22px' }} >Everything in Printing</p>
                                                            </h3>
                                                        </div>
                                                        <div className="col-lg-4 mt-5">
                                                            <h6><small>15-4-64, Gowliguda Chaman, <br></br>Hyderabad 500012</small></h6>
                                                            <h6><small>Phone: 040-9393009990</small></h6>
                                                            <h6><small>GSTIN: 36AEYPM2529L1ZR</small></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-md-12 mt-3 mb-5">
                                                    <table>
                                                        <tbody>
                                                            <tr className="col-lg-12">
                                                                <td colspan="7"><span className="ms-2">To,</span><br />
                                                                    <div className="d-flex">
                                                                        <div className="col-lg-9 ms-2">Mridini Graphics (Musheerabad)</div>
                                                                        <div>Bill No:&nbsp;7860</div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="col-lg-9 ms-2">Hyderabad</div>
                                                                        <div>Date: &nbsp; 16/03/2023</div>
                                                                    </div>
                                                                    <br />
                                                                    <span className="ms-2"> Phone: 9349958851</span><br />
                                                                    <br />
                                                                    <span className="ms-2">GST IN:</span><br />
                                                                    <br />
                                                                    <div className="d-flex">
                                                                        <div className="col-lg-9 ms-2">J.Name : Sri Meenakshi Hospital Cover</div>
                                                                        <div>Booked: &nbsp; Krishna</div>
                                                                    </div>
                                                                    <br />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>S.No.</td>
                                                                <td>Description</td>
                                                                <td>HSN Code</td>
                                                                <td>Gst %</td>
                                                                <td>Quantity</td>
                                                                <td>Rate</td>
                                                                <td className="p-2">Amount</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="align-text-top" rowspan="4">1.</td>
                                                                <td className="align-text-top" rowspan="4">
                                                                    <div>Offset Print Labour, size:22X11, Paper:art card300, kl 26, Lamination:glossy one
                                                                        side</div>
                                                                </td>
                                                                <td rowspan="4"></td>
                                                                <td rowspan="4"></td>
                                                                <td className="align-text-top" rowspan="4">576</td>
                                                                <td className="p-2">0.00<br /><br /><br /><br /></td>
                                                                <td className="p-2">2200.00<br /><br /><br /><br /></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="p-2">Total<br />GST</td>
                                                                <td className="p-2">2200.00<br />0.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="p-2">G.Total: <br /> Advance</td>
                                                                <td className="p-2">2200.00 <br /> 0.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="p-2">Balance:</td>
                                                                <td className="p-2">2200.00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </section> 
                        </CardContent>
                    </Card>
                </Modal.Body>
            </Modal> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userdata: state.userdetails,
  };
};

export default connect(mapStateToProps)(Estimates);
