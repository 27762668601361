import store from "../index";
import auth from "../../services/authService";

export var GET_USER_DETAILS = 'GET_USER_DETAILS';

async function get_userdetails() {
	
    try {
        const genmovies = await auth.getuserdetails();
        console.log(genmovies.data.user,'genmovies');
        store.dispatch({ type: GET_USER_DETAILS, payload: genmovies.data });
    } catch (error) {}

}

export default get_userdetails;