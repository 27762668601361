import httpService from "./httpService";

export async function getdeliver(obj) {
	const data = await httpService.post('/delivers/getAll',obj);
	return data;
}
export async function adddeliver(obj) {
	const data = await httpService.post('/delivers/add',obj);
	return data;
}
export async function updateDeliver(obj) {
	const data = await httpService.post('/delivers/updateStatus', obj);
	return data;
}
export async function updateDelivery(obj) {
	const data = await httpService.post('/delivers/update', obj);
	return data;
}

export default {
    getdeliver,
	adddeliver,
	updateDeliver,
	updateDelivery,
}