import Button from "react-bootstrap/Button";

import React from "react";

const ModalSubmit = ({ cancelOnclick, submitOnclick, submitText, ...props}) => {
  return (
    <div className=" mt-3 mb-3 gap-1 d-flex flex-column flex-lg-row">
      {/* <div className="col-lg-2"></div> */}
      <div className="col-lg-6 text-center">
        <Button
          onClick={cancelOnclick}
          id="pillStyle"
          className="w-100"
          variant="outline-primary"
          {...props}
        >
          Cancel
        </Button>
      </div>
      {/* <div className="col-lg-2"></div> */}
      <div className="col-lg-6 text-center">
        <Button
          onClick={submitOnclick}
          id="pillStyle"
          className="w-100"
          variant="primary"
        >
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export default ModalSubmit;
