import React, { useEffect, useState, useRef } from 'react';
import '../../Styles/Orders.css'
import Header from '../header/Header';
import Container from 'react-bootstrap/Container';
import Sidebar from '../Sidenav/Sidebar';
import aretsLogo from '../../Images/aretsLogo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import { NavLink, useNavigate,useLocation } from "react-router-dom";
// https://app.aretsgraphicprint.com/
import Button from 'react-bootstrap/Button';
import uploadCloudImage from '../../Images/uploadCloudImage.png'
import UploadImageIcon from '../../Images/UploadImageIcon.png'
import Multiselect from 'multiselect-react-dropdown';
import { connect } from 'react-redux';
import Switch from "react-switch";
import axios from 'axios'
// import Paper from '@mui/material/Paper';

// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ClientsService from '../../services/ClientsService';
import estimateService from '../../services/estimateService';
import get_userdetails from '../../redux/actions/getuserDetailsAction';
import authService from '../../services/authService';
import { toast } from 'react-toastify';
import { Nav } from 'react-bootstrap';

import { OutlinedInput } from '@mui/material';
import { color } from '@mui/system';
import { red } from '@mui/material/colors';
import showPwdImg from '../../Images/show-password.svg';
import hidePwdImg from '../../Images/hide-password.svg';
import moment from 'moment'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


function EditEstimate(props) {
  const location = useLocation()
  const [ecname, setECname] = useState('');
  const [ephone, setEPhone] = useState('');
  const [efaddress, setEFAddress] = useState('')
  const [eordername, setEOrderName] = useState('');
  const [ejobtype, setEJobType] = useState('');
  const [epapertype, setEPapertype] = useState('');
  const [edesignamount, setEDesignAmount] = useState('');
  const [edesign, setEDesign] = useState('');
  const [ecutsize, setECutSize] = useState();
  const [eflength, setEFlength] = useState('');
  const [efquantity, setEFquantity] = useState('');
  const [efbreadth, setEFbreadth] = useState('');
  const [ecutquantity, setECutQuantity] = useState();
  const [emillquantity, setEMillQuantity] = useState();
  const [emillsize, setEMillsize] = useState('');
  const [emodeofprinting, setEModeofPrinting] = useState('')
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(location.state?.item ? location.state.item.customerId : '');
  const [gsmtypes, setGsmTypes] = useState([]);
  const [user, setUserdata] = useState({});
  const [cname, setCname] = useState('');
  const [cid, setCid] = useState('');
  const [phone, setPhone] = useState(location.state?.item ? location.state.item.customerId.phoneNo : '');
  const [faddress, setFAddress] = useState(location.state?.item ? location.state.item.customerId.city : '')
  const [email, setEmail] = useState(location.state?.item ? location.state.item.customerId.email : '');
  const [gst, setGst] = useState(location.state?.item ? location.state.item.gstNo : '');
  const [size, setSize] = useState('');
  const [ordername, setOrderName] = useState(location.state?.item ? location.state.item.name : '');
  const [ordertype, setOrderType] = useState('');
  const [jobtype, setJobType] = useState(location.state?.item ? location.state.item.orderType : '');
  const [jobRate, setJobRate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [country, setCountry] = useState('');
  const [papertype, setPapertype] = useState('');
  const [bpapertype, setBPaperType] = useState('');
  const [modeofprinting, setModeofPrinting] = useState(location.state?.item ? location.state.item.printing : '')
  const [machinetype, setMachineType] = useState('');
  const [bmachinetype, setBMachineType] = useState({})
  const [machines, setMachines] = useState([]);
  const [gsmoptions, setGsmOptions] = useState([])
  const [finishSizes, setFinishSizes] = useState([])
  const [finishsize, setFinishSize] = useState('');
  const [bfinishsize, setBfinishsize] = useState({})
  const [lamlength, setLamlength] = useState(location.state?.item ? location.state.item.laminationLength : '');
  const [lambreadth, setLambreadth] = useState(location.state?.item ? location.state.item.laminationBreadth : '');
  const [finishoptions, setFinishoptions] = useState([])
  const [laminations, setLaminations] = useState([]);
  const [lamination, setLamination] = useState('');
  const [blamination, setBLamination] = useState({});
  const [extraquantity, setExtraquantity] = useState('');
  const [millsizes, setMillsizes] = useState([]);
  const [millsize, setMillsize] = useState('');
  const [bmillsize, setBMillSize] = useState({});
  const [millerLength, setMillerLength] = useState('');
  const [millerBreadth, setMillerBreadth] = useState('');
  const [printJobTypes, setPrintJobTypes] = useState([]);
  const [printJobType, setPrintJobType] = useState('');
  const [bprintJobType, setBPrintJobType] = useState({});
  const [rate, setRate] = useState(location.state?.item.laminationRate);
  const [punchingTypes,setPunchingTypes] = useState([]);
  const [pastingtypes,setPastingTypes] = useState([]);
  const [punchingType, setPunchingType] = useState(location.state?.item.punching)
  const [punchRate, setPunchRate] = useState(location.state?.item.punchingRate);
  const [punchQuantity, setPunchQuantity] = useState(location.state?.item.punchingQuantity);
  const [pastingtype, setPastingType] = useState(location.state?.item.pasting);
  const [pastquantity, setPastquantity] = useState(location.state?.item.pastingQuantity);
  const [pastRate, setPastRate] = useState(location.state?.item.pastingRate);
  const [pastrimming, setPastRimming] = useState(location.state?.item.rimming);
  const [pastbinding, setPastBinding] = useState(location.state?.item.binding);
  const [markup, setMarkup] = useState(location.state?.item.markupPercentage);
  const [date, setDate] = useState(moment.utc(location.state?.item.expectedDate).format('YYYY-MM-DD'));
  const [extraworkslist, setExtraWorkList] = useState([]);
  const [extraWorks, setExtraWorks] = useState('');
  const [bextraWorks, setBExtraWorks] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [ddoption, setDDoption] = useState(false);
  const [file, setFile] = useState(null);
  const [imloader, setImloader] = useState(null)
  const [sbtndis, setSBtndis] = useState(false)
  const [designamount, setDesignAmount] = useState(location.state?.item ? location.state.item.designingAmount : '');
  const [design, setDesign] = useState(location.state?.item ? location.state.item.design : '');
  const [dieamount, setDieAmount] = useState(location.state?.item ? location.state.item.dieAmount : '');
  const [rimmingamount, setRimmingAmount] = useState('');
  const [bindingamount, setBindingAmount] = useState('');
  const [packingamount, setPackingAmount] = useState(location.state?.item ? location.state.item.packingAmount : '');
  const [miscamount, setMiscAmount] = useState('')
  const [jobdetails, setJobDetails] = useState(location.state?.item ? location.state.item.jobDetails : [ ])
  const [flength, setFlength] = useState(location.state?.item ? location.state.item.finishLength : '');
  const [fbreadth, setFbreadth] = useState(location.state?.item ? location.state.item.finishBreadth : '');
  const [fquantity, setFquantity] = useState(location.state?.item ? location.state.item.quantity : '');
  const [fextraquantity, setFextraquantity] = useState(location.state?.item ? location.state.item.extraquantity : '');
  const [time, setTime] = useState('')
  const [estimatecalc, setEstimatecalc] = useState();
  const [millquantity, setMillQuantity] = useState(location.state?.item ? location.state.item.millquantity : '');
  const [cutquantity, setCutQuantity] = useState(location.state?.item ? location.state.item.cuttingQuantity : '');
  const [cutsize, setCutsize] = useState(location.state?.item ? location.state.item.cuttingSize : '');
  const [cutsizes, setCutsizes] = useState([]);
  const [bcutsize, setBCutSize] = useState({});
  const [printingside, setPrintingside] = useState(location.state?.item ? location.state.item.printing : '');  
  const [modalShow, setModalShow] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [lamquantity, setLamQuantity] = useState(parseInt(fquantity) + parseInt(fextraquantity));
  const [lamrate, setLamRate] = useState('');
  const [punchAmount, setPunchAmount] = useState('')
  const [bindingtype, setBindingType] = useState(location.state?.item ? location.state.item.binding : '');
  const [bindrate, setBindRate] = useState(location.state?.item ? location.state.item.bindingAmount : '');
  const [bindquantity, setBindQuantity] = useState(parseInt(fquantity) + parseInt(fextraquantity));
  const [bindamount, setBindAmount] = useState('');
  const [others, setOthers] = useState('');
  const [otheramount, setOtherAmount] = useState('');
  const [role, setRole] = useState('');
  const [sname, setSname] = useState('');
  const [uname, setUname] = useState('');
  const [order, setOrder] = useState('');
  const [password, setPassword] = useState('');
  const [reEnterpassword, setReEnterPassword] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [uemail, setUEmail] = useState('');
  const [phonecode, setPhonecode] = useState('');
  const [uphone, setUPhone] = useState('');
  const [access, setAccess] = useState([]);
  const [fsize,setfSize] = useState('');
  const [blam,setBlam] = useState('');
  const [moprint,setMoprint] = useState('');
  const [bptype,setBptype] = useState('')
  // const [status, setStatus] = useState('');
  const [city, setCity] = useState('');
  const [states, setStates] = useState('');
  const [clpincode, setCLPincode] = useState('');
  const [gstNo, setGstNo] = useState('');
  // const [clstatus, setCLStatus] = useState('');
  const [clphonecode, setPhoneCode] = useState('');
  const [punchjob,setpunchJob] = useState('');
  const [pastjob,setPastJob] = useState('');
  const multiselectRef = useRef();
  const papermultiselectRef = useRef();
  const finishmultiselectRef = useRef();
  const machinemultiselectRef = useRef();
  const millimultiselectRef = useRef();
  const laminationmultiselectRef = useRef();
  const extraworksmultiselectRef = useRef();
  const printjobmultiselectRef = useRef();
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  var str,
element = document.getElementById('cal_preview');
if (element != null) {
    str = element.value;
}
else {
    str = null;
}
  console.log(location.state.item.cuttingSize, 'cuttingsize');
 

  useEffect(() => {
    getuserdetails();
    getclients()
    getgsmtypes()
    getselclient(); 
    getfinishsizes()
    getmachines();
    getmillsizes();
    getcutsizes();
    getlaminations();
    getextrajobs();
    getjobprinttypes();
    getpunchtypes();
    getpasttypes();
    getpapertypegsm();
    getselmillsizes();
    getselcutsizes();
    getsellamination();
    getselpunchtypes();
    getselpasttypes();
    getseljobtype();
  }, [props.userdata])
  useEffect(() => {
    get_userdetails()
  }, [])
  const getuserdetails = async () => {
    setUserdata(props.userdata.user)
  }
  const getselclient = () => {
    let arr = []
    if(clients && clients.length > 0){
      const rty = clients.map(k => {
        if (k.name === location.state.item.customerName) {
          arr.push(k)
  
        }
      })
      console.log(arr,'arredit');
      setClient(arr)
  
      setCname(arr[0].name);
  
      setCid(arr[0]._id)
    }
    


  }
  const selectemps = (e) => {
    if (e.target.value === 'Create New Client') {
      setModalShow(true)
      console.log(e.target.value, 'e.target.value');
    } else {
      console.log(e.target.selectedOptions[0].dataset.index, 'value');
      const selindex = e.target.selectedOptions[0].dataset.index;
      const selclient = clients[selindex].name
      console.log(selclient, 'selclient');
      setClient(clients[selindex]);
      setFAddress(clients[selindex].city)
      setPhone(clients[selindex].phoneNo)
      setGst(clients[selindex].gstNo)
      setCname(selclient);
      setCid(clients[selindex]._id)
    }

    // setClient(value)
    // setCname(value[0].name);
    // jobdetails.push(value[0].name)
    // setCid(value[0]._id)
  }
  const getpunchtypes = async() => {
    try {
      const der = await estimateService.getpunchingtypes();
      if(der.data.status === 'success'){
          setPunchingTypes(der.data.value)
      }
    } catch (ex) {
      
    }
      
  }
  const getpapertypegsm = () => {
    let arr = [];
    if(gsmoptions && gsmoptions.length > 0) {
      const rtu = gsmoptions.map(l => {
        if (location.state.item.paperType + location.state.item.gsm === l.paperTypegsm) {
          arr.push(l)
        }
      })
      console.log(arr[0],'location.papertype');
      setPapertype(arr[0].paperTypegsm)
      setBPaperType(arr[0])
    }
   
  }
  const getselmillsizes = () => {
    let arr = [];
    if(millsizes && millsizes.length > 0){
      const tre = millsizes.map(p => {
        if (p.length === location.state.item.millerLength && p.breadth === location.state.item.millerBreadth) {
          arr.push(p)
        }
      })
      setMillsize(arr[0].milliText);
      setBMillSize(arr[0])
    }
    
  }
  const getselcutsizes = () => {
    console.log(location.state.item,'location.city');
    let arr = [];
    console.log(cutsizes, 'cutsizes')
    if(cutsizes && cutsizes.length > 0){
      const tre = cutsizes.map(p => {
        if (p.length === location.state.item.cuttingLength && p.breadth === location.state.item.cuttingBreadth) {
          arr.push(p)
        }
      })
  
      console.log(arr,'location.cuttingSize');
      // setCutsize(arr[0].cuttingSize);
      // setBCutSize(arr[0])  
    }
    
  }
  const getselpunchtypes = () => {
    let arr = [];
    console.log(location.state.item,'punchingTypes');
    if((punchingTypes && punchingTypes.length > 0 && location.state.item.punching !== 'Na')  && location.state.item.punching !== ''){
        const ert = punchingTypes.map(l => {
          if(l.type === location.state.item.punching){
              arr.push(l)
          }
        })
        setPunchingType(arr[0].type)
        setPunchRate(arr[0].cost)
      }else{
        setPunchingType('Na');
        setPunchRate(0)
    }
  }
  const getseljobtype = () => {
    let arr = [];
    console.log(printJobTypes,'ordertypes');
    if((printJobTypes && printJobTypes.length > 0 && location.state.item.orderType !== 'Na')  && location.state.item.orderType !== ''){
        const ert = printJobTypes.map(l => {
          if(l.details === location.state.item.orderType){
              arr.push(l)
          }
        })
        setJobType(arr[0].details)
        setJobRate(arr[0].cost)
      }else{
        setJobType('Na');
        setJobRate(0)
    }
  }
  const getselpasttypes = () => {
    let arr = [];
    if(pastingtypes && pastingtypes.length > 0 && location.state.item.pasting !== 'Na' && location.state.item.pasting !== ''){
        const ert = pastingtypes.map(l => {
          if(l.type === location.state.item.pasting){
              arr.push(l)
          }
        })
        setPastingType(arr[0].type)
        setPastRate(arr[0].rate)
      }else{
        setPastingType('Na')
          setPastRate(0)
    }
  }
  const getpasttypes = async() => {
    try {
        const dry = await estimateService.getpastingtypes();
        if(dry.data.status === 'success'){
          setPastingTypes(dry.data.value)
        }
    } catch (ex) {
      
    }
  }
  const selectpapertype = (e) => {
    // setPapertype(value)
    // setBPaperType(value[0])
    console.log(e.target.value, 'value');
    //   const selindex = e.target.selectedOptions[0].dataset.index;
    //   const selclient = gsmoptions[selindex].paperTypegsm
      setPapertype(e.target.value);
      let arr = []
      if(e.target.value){
          const dert = gsmoptions && gsmoptions.map((k,index) => {
              if(k.paperTypegsm === e.target.value){
                  arr.push(k)
              }
          })
      }
      setBPaperType(arr[0])
     let bptype = `Paper:${e.target.value},`
      setBptype(bptype)
    // getjobdet()
 
  }
  const getclients = async () => {

    try {

      const dert = await ClientsService.getclients();
      if (dert.data.status === 'success') {
        console.log(dert.data.value, 'values');
        setClients(dert.data.value);
      }
    } catch (ex) {

    }
  }
  const getjobprinttypes = async () => {
    try {
      const dty = await estimateService.printjobtypes();
      if (dty.data.status === 'success') {
        setPrintJobTypes(dty.data.value)

      }
    } catch (ex) {

    }

  }
  const getgsmtypes = async () => {
    try {
      const dert = await estimateService.getpapergsmAll();
      if (dert.data.status === 'success') {

        if (dert.data.value?.length > 0) {
          let optionss = [];
          dert.data.value.map((k) => {
            const obj = {
              paperTypegsm: k.paperType + k.gsm,
              _id: k._id,
              "paperType": k.paperType,
              "perkgRate": k.perkgRate,
              "gsm": k.gsm,
              "printmincost": k.printmincost,
              "printminImpressionquantity": k.printminImpressionquantity,
              "printextraImpressioncost": k.printextraImpressioncost,
              "printextraImpressionquantity": k.printextraImpressionquantity,
              "isActive": k.isActive,
              "isArchive": k.isArchive,
              "updatedDate": k.updatedDate,
              "createDate": k.createDate,
              "__v": 0
            }
            optionss.push(obj)
          })
          setGsmOptions(optionss)
          setGsmTypes(dert.data.value)
        }

      }
    } catch (ex) {

    }


  }
  const getmachines = async () => {
    try {
      const dert = await estimateService.getmachines();
      if (dert.data.status === 'success') {
        console.log(dert.data.value, 'dert.data.value');
        setMachines(dert.data.value)
      }
    } catch (ex) {

    }
  }
  const getfinishsizes = async () => {
    try {
      const drty = await estimateService.getfinishsize();
      if (drty.data.status === 'success') {
        let options = []
        drty.data.value.map((l, index) => {
          const obj = {
            "_id": l._id,
            "finishText": l.finishText ? l.finishText : 0,
            "length": l.length,
            "breadth": l.breadth,
            "isActive": l.isActive,
            "isArchive": l.isArchive,
            "updatedDate": l.updatedDate,
            "createDate": l.createDate,
            "__v": 0
          }
          options.push(obj)
        })
        setFinishSizes(options)
      }
    } catch (ex) {

    }
  }
  const getmillsizes = async () => {
    try {
      const dert = await estimateService.getmillsizes();
      if (dert.data.status === 'success') {
        console.log(dert.data.value, 'millsizes');
        setMillsizes(dert.data.value)
      }
    } catch (ex) {

    }

  }
  const getcutsizes = async () => {
    try {
      const dert = await estimateService.getcuttingsize();
      if (dert.data.status === 'success') {
        console.log(dert.data.value, 'cutsizes');
        setCutsizes(dert.data.value)
      }
    } catch (ex) {

    }

  }
  const getlaminations = async () => {
    try {
      const drt = await estimateService.getlaminations();
      if (drt.data.status === 'success') {
        console.log(drt.data.value, 'laminations');
        setLaminations(drt.data.value)
      }
    } catch (ex) {

    }

  }
  const getextrajobs = async () => {
    try {
      const dey = await estimateService.extrajobs();
      if (dey.data.status === 'success') {
        setExtraWorkList(dey.data.value)
      }
    } catch (ex) {

    }

  }
  const selectExtraworks = (value) => {
    setExtraWorks(value);
    setBExtraWorks(value[0]);
    // jobdetails.push(value[0].details)
    console.log(value, 'value')
  }
  const selectfinishsize = (value) => {
    setFinishSize(value)
    setBfinishsize(value[0])
    // jobdetails.push(value[0].finishText)
    console.log(value, 'value');
  }
  const selectmachinetype = (value) => {
    setMachineType(value);
    setBMachineType(value[0])
    // jobdetails.push(value[0].details)
    console.log(value, 'value')
  }
  const selectmillsize = (e) => {
    // const selindex = e.target.selectedOptions[0].dataset.index;
    //   const selclient = millsizes[selindex].milliText
    //   console.log(selclient, 'selmillsize');
    setMillsize(e.target.value)
    let arr = []
      if(e.target.value){
          const dert = millsizes && millsizes.map((k,index) => {
              if(k.milliText === e.target.value){
                  arr.push(k)
              }
          })
      }
    setBMillSize(arr[0])
    // jobdetails.push(millsizes[selindex].milliText)
    // console.log(value, 'value')
  }
  const selectcutsize = (e) => {
    // const selindex = e.target.selectedOptions[0].dataset.index;
    //   const selclient = Cutsizes[selindex].CutiText
    //   console.log(selclient, 'selCutsize');
    setCutsize(e.target.value)
    let arr = []
      if(e.target.value){
          const dert = cutsizes && cutsizes.map((k,index) => {
              if(k.cuttingSize === e.target.value){
                  arr.push(k)
              }
          })
      }
      console.log(arr[0],'selectbcutsize');
    setBCutSize(arr[0])
    // jobdetails.push(millsizes[selindex].milliText)
    // console.log(value, 'value')
  }
  const selpunchingtype = (e) =>{
    // const selindex = e.target.selectedOptions[0].dataset.index;
    setPunchingType(e.target.value);
    let arr = [];
    if(e.target.value){
      const erty = punchingTypes && punchingTypes.map((l,index) => {
          if(l.type === e.target.value){
            arr.push(l)
          }
      })
      setPunchRate(arr[0].cost)
    }
    // setPunchRate(punchingTypes[selindex].cost)
    let punchjob = `Punching:${e.target.value}`
    setpunchJob(punchjob)
    getjobdet()
    // jobdetails.push(punchingTypes[selindex].type)
  }
  const seljobtype = (e) => {
    setJobType(e.target.value);
    let arr = [];
    if(e.target.value){
      const grt = printJobTypes && printJobTypes.map((k,index) => {
          if(k.details === e.target.value){
              arr.push(k)
          }
      })
      setJobRate(arr[0].rate)
    }
  //   const selindex = e.target.selectedOptions[0].dataset.index;
  // setPastingType(pastingtypes[selindex].type);
  // setPastRate(pastingtypes[selindex].rate)
  // let pastjob = `Pasting:${e.target.value}`
    // setPastJob(pastjob)
    // const selindex = e.target.selectedOptions[0].dataset.index;
    // // setPrintJobTypes(printJobTypes[selindex].type);
    // setJobRate(printJobTypes[selindex].cost)
    // let printjob = `jobtype:${printJobTypes[selindex].details}`
    // setJobType(printJobTypes[selindex].details)
    // getjobdet()
    // jobdetails.push(punchingTypes[selindex].type)
  }
  const selpasting = (e) => {
    setPastingType(e.target.value);
    let arr = [];
    if(e.target.value){
      const grt = pastingtypes && pastingtypes.map((k,index) => {
          if(k.type === e.target.value){
              arr.push(k)
          }
      })
      setPastRate(arr[0].rate)
    }
  //   const selindex = e.target.selectedOptions[0].dataset.index;
  // setPastingType(pastingtypes[selindex].type);
  // setPastRate(pastingtypes[selindex].rate)
  let pastjob = `Pasting:${e.target.value}`
    setPastJob(pastjob)
  // jobdetails.push(pastingtypes[selindex].type)
  }
  const getsellamination = () => {
     let arr = [];
    if(laminations && laminations.length > 0 && location.state.item.lamination !== 'Na'){
        const ert = laminations.map(l => {
          if(l.details === location.state.item.lamination){
              arr.push(l)
          }
        })
        setLamination(arr[0].type)
        setLamRate(arr[0].rate)
        setBLamination(arr[0])
      }else{
        setLamination('Na')
        setLamRate(0)
        setBLamination('Na')
    }
    // let arr = [];
    // if(laminations && laminations.length > 0){
    //   const kuy = laminations.map(j => {
    //     if (j.details === location.state.item.lamination) {
    //       arr.push(j);
    //     }
    //   })
    //   console.log(arr, 'arr');
    //   setLamination(arr[0].details)
    //   setLamRate(arr[0].cost)
    //   setBLamination(arr[0])
    // }
    
  }
  const selectlamination = (e) => {
    // setLamination(value);
    // setBLamination(value[0])
    
    // const selindex = e.target.selectedOptions[0].dataset.index;
    // const selclient = laminations[selindex].details
    setLamination(e.target.value)
    let arr = []
   if(laminations && laminations.length > 0){
      const dert = laminations.map((k,index) => {
          if(k.details === e.target.value){
              arr.push(k)
          }
      })
   }
   setBLamination(arr[0])
    setLamRate(arr[0].cost)
    let blam = `Lamination:${e.target.value}`
    setBlam(blam)
    getjobdet()
   
  }
  console.log(bpapertype, 'props.userdata')
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const changeHandler = async (event) => {
    console.log('calling');
    await setImloader(true)

    const formData = new FormData();

    formData.append(
      "image",
      event.target.files[0],
    );
    console.log(event.target.files[0], 'event.target.files')
    let res = await axios.post(
      'https://aretsapi.azurewebsites.net/api/common/uploadfile', formData,

      {
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data; ',
          'Accept': '*/*'
        },
      }
    );
    console.log(res.data, 'res.data');
    if (res.data.url) {
      await setImloader(false);

      await setFile(res.data.url);
    }

  };
  const delmg = async () => {
    var filename = file.substring(file.lastIndexOf('/') + 1);
    const obj = {
      url: filename
    }
    const dete = await estimateService.delproimg(obj);
    if (dete.data.status === true) {
      setFile()
      toast.success('File Deleted SuccessFully', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }
  const payref = useRef();
  const [tab, setTab] = useState('Order Confirmation');
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture")).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'in', [13.5, 9]);
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("Order Confirmation.pdf");
    });
  }
  const submit = async () => {
    if (!cname) {
      await setSBtndis(false)
      setECname('Firm Name is Required!');
      toast.error('Select Firm Name', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!faddress) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('Area is Required!');
      toast.error('Enter Area/Street', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!phone) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('Mobile Number is Required!');
      toast.error('Enter Phone Number', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    else if (phone.length < 10 && phone.length > 10) {
      await setSBtndis(false)
      toast.error('Invalid Phone', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!ordername) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('Job Name is Required!');
      toast.error('Enter Job Name', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!jobtype) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('Job Type is Required!');
      toast.error('Select Job Type', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!flength) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('Finish Length is Required!');
      toast.error('Enter Finish Length', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!fbreadth) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('Finish Breadth is Required!');
      toast.error('Enter Finish Breadth', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!fquantity) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('Quantity is Required!');
      toast.error('Enter Finish Quantity', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!papertype) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('paper Type is Required!');
      toast.error('Select Paper Type', {
        position: toast.POSITION.BOTTOM_LEFT
      });

    } else if (!millsize) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('Mill Size is Required!');
      toast.error('Select Mill Sheet Size', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!millquantity) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('');
      setEMillQuantity('Mill Quantity is Required!');
      toast.error('Enter Mill Quantity', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!cutsize) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('');
      setEMillQuantity('');
      setECutSize('Cutting Size is Required!');
      toast.error('Enter Cutting Size', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } else if (!cutquantity) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('');
      setEMillQuantity('');
      setECutSize('');
      setECutQuantity('Cutting Quantity is Required!');
      toast.error('Enter Cutting Quantity', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    else if (!design) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('');
      setEMillQuantity('');
      setECutSize('');
      setECutQuantity('');
      setEDesign('Design By is Required!');
      toast.error('Select Design By', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    else if (!designamount) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('');
      setEMillQuantity('');
      setECutSize('');
      setECutQuantity('');
      setEDesign('');
      setEDesignAmount('Mill Size is Required!');
      toast.error('Enter Design Amount', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    else if (!modeofprinting) {
      await setSBtndis(false)
      setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEPapertype('');
      setEMillsize('');
      setEMillQuantity('');
      setECutSize('');
      setECutQuantity('');
      setEDesign('');
      setEDesignAmount('');
      setEModeofPrinting('Mode OF Printing is Required!');
      toast.error('Select Mode of Printing', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
    // else if (!printingside) {
    //   await setSBtndis(false)
    //   setEModeofPrinting('');
    //   setEPrintingside('Printing Side is Required!');
    //   toast.error('Select Printing Side', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // }
    // else if (!packingamount) {
    //   await setSBtndis(false)
    //   toast.error('Enter Packing Amount', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } 
    // else if (!dieamount) {
    //   await setSBtndis(false)
    //   toast.error('Enter Die Amount', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!lamination) {
    //   await setSBtndis(false)
    //   toast.error('Select Lamination Type', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!lamlength) {
    //   await setSBtndis(false)
    //   toast.error('Enter Lamination Length', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!lambreadth) {
    //   await setSBtndis(false)
    //   toast.error('Enter Lamination Breadth', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!punchingType) {
    //   await setSBtndis(false)
    //   toast.error('Select Punching Type', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!punchQuantity) {
    //   await setSBtndis(false)
    //   toast.error('Enter Punching Quantity', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!punchRate) {
    //   await setSBtndis(false)
    //   toast.error('Enter Punching Rate', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!pastingtype) {
    //   await setSBtndis(false)
    //   toast.error('Select Pasting Type', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!pastquantity) {
    //   await setSBtndis(false)
    //   toast.error('Enter Pasting Quantity', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // } else if (!pastRate) {
    //   await setSBtndis(false)
    //   toast.error('Enter Pasting Rate', {
    //     position: toast.POSITION.BOTTOM_LEFT
    //   });
    // }
      else {
        setECname('');
      setEFAddress('');
      setEPhone('');
      setEOrderName('');
      setEJobType('');
      setEFlength('');
      setEFbreadth('');
      setEFquantity('');
      setEModeofPrinting('');
      setEMillsize('');
      setEMillQuantity('');
      setECutSize('');
      setECutQuantity('');
      setEDesign('');
      setEDesignAmount('');
      setEModeofPrinting('');
      // setEPrintingside('');
      try {
        const obj ={
          "laminationdetails": blamination.details,
          "punchingType": punchingType,
          "pastingType": pastingtype,
          "mechinedetails": modeofprinting,
          // "cuttingLength":parseInt(bcutsize.length),
          // "cuttingLength": parseInt(bcutsize.length) === '' ? 0 : parseInt(bcutsize.length),
          // "cuttingBreadth": parseInt(bcutsize.breadth) === '' ? 0 : parseInt(bcutsize.breadth),
          "cuttingQuantity": parseInt(cutquantity) === '' ? 0 : parseInt(cutquantity),
          "cuttingLength":parseFloat(bcutsize.length),
          "cuttingBreadth":parseFloat(bcutsize.breadth),
          calobj: {
            "designingAmount": parseInt(designamount),
            "dieAmount": dieamount === '' ? 0 : parseInt(dieamount),
            "rimmingAmount": 0,
            "bindingAmount": bindrate === '' ? 0 : parseInt(bindrate),
            "packingAmount": packingamount === '' ? 0 : parseInt(packingamount),
            "paperType": {
              // "sno":2,
              "paperType": bpapertype.paperType,
              "perkgRate": parseInt(bpapertype.perkgRate),
              "gsm": parseInt(bpapertype.gsm),
              "printmincost": parseInt(bpapertype.printmincost),
              "printminImpressionquantity": parseInt(bpapertype.printminImpressionquantity),
              "printextraImpressioncost": parseInt(bpapertype.printextraImpressioncost),
              "printextraImpressionquantity": parseInt(bpapertype.printextraImpressionquantity)
            },
            "quantity":parseInt(fquantity),
            "finishLength":parseInt(flength),
            "finishBreadth":parseInt(fbreadth),
            "extraquantity":parseInt(fextraquantity),
            "millerLength":parseFloat(bmillsize.length),
            "millerBreadth":parseFloat(bmillsize.breadth),
            "laminationLength":parseFloat(lamlength),
            "laminationBreadth":parseFloat(lambreadth),
            "laminationRate":lamrate === '' ? 0 :parseFloat(lamrate),
            "punchingQuantity":parseInt(punchQuantity),
            "punchingRate": punchRate === '' ? 0 : parseInt(punchRate),
            "pastingQuantity":parseInt(pastquantity),
            "pastingRate":pastRate === '' ? 0 : parseInt(pastRate),
             "markup":markup === '' ? 0 : markup,
             miscAmount:0

          }
        }
        console.log(obj,'obj');
        // const obj = {
        //   "calobj": {
        //     "designingAmount": parseInt(designamount),
        //     "dieAmount": parseInt(dieamount),
        //     "rimmingAmount": parseInt(rimmingamount),
        //     "bindingAmount": parseInt(bindingamount),
        //     "packingAmount": parseInt(packingamount),
        //     "paperType": {

        //       "paperType": bpapertype.paperType,
        //       "perkgRate": parseInt(bpapertype.perkgRate),
        //       "gsm": parseInt(bpapertype.gsm),
        //       "printmincost": parseInt(bpapertype.printmincost),
        //       "printminImpressionquantity": parseInt(bpapertype.printminImpressionquantity),
        //       "printextraImpressioncost": parseInt(bpapertype.printextraImpressioncost),
        //       "printextraImpressionquantity": parseInt(bpapertype.printextraImpressionquantity)
        //     },
        //     "quantity": parseInt(fquantity),
        //     "finishLength": parseInt(flength),
        //     "finishBreadth": parseInt(fbreadth),
        //     "extraquantity": parseInt(fextraquantity),
        //     "millerLength": parseInt(bmillsize.length),
        //     "millerBreadth": parseInt(bmillsize.breadth),
        //     "laminationLength": parseInt(lamlength),
        //     "laminationBreadth": parseInt(lambreadth),
        //     "laminationRate": parseFloat(rate),
        //     "punchingQuantity": parseInt(punchQuantity),
        //     "punchingRate": parseInt(punchRate),
        //     "pastingQuantity": parseInt(pastquantity),
        //     "pastingRate": parseInt(pastRate),
        //     "markup": parseInt(markup),
        //     "miscAmount": parseInt(miscamount),
        //   }

        // }
        console.log(obj, 'obj')
        const drty = await estimateService.estimatecalculation(obj)
        console.log(drty.data.data, 'drty.data');
        if (drty.data) {
          setEstimatecalc(drty.data.data)
          console.log(drty.data, 'drty.data');
        }
      } catch (ex) {

      }
    }
  }
  const handleorderType = (e) => {
    setOrderType(e.target.value)
    // jobdetails.push(e.target.value)
  }
  const handlemode = (e) => {
    const selindex = e.target.selectedOptions[0].dataset.index;
    // const selclient = punchingTypes[selindex].type
    console.log(machines[selindex].details,'punchingTypes[selindex].type');
    setModeofPrinting(e.target.value);
    // let moprint = machines[selindex].details
    setMoprint(e.target.value)
  // jobdetails.push(machines[selindex].details)
 
  }
  const handlesubmit = async () => {
    try {
      const obj ={
       "customerName":cname,
      "customerId":cid,
      "mobile":phone,
      "gstNo":gst,
      "email":email,
      "quantity": parseInt(fquantity),
      "printing":modeofprinting,
      "design":design,
      "paperType":bpapertype.paperType,
      "gsm":bpapertype.gsm === null ? 0 : parseInt(bpapertype.gsm),
      "machine":modeofprinting,
      "lamination":blamination.details,
      "punching":punchingType,
      "pasting":pastingtype,
      "binding":bindingtype === '' ? '' : bindingtype,
      "finishLength":parseInt(flength),
      "finishBreadth":parseInt(fbreadth),
      "extraquantity":parseInt(fextraquantity),
      "millerLength":parseInt(bmillsize.length),
      "millerBreadth":parseInt(bmillsize.breadth),
      "cuttingLength":parseInt(bcutsize.length),
      "cuttingBreadth":parseInt(bcutsize.breadth),
      "laminationLength":parseInt(lamlength),
      "laminationBreadth":parseInt(lambreadth),
      "laminationRate":parseInt(lamrate),
      "punchingQuantity":parseInt(punchQuantity),
      "punchingRate":parseInt(punchRate),
      "pastingQuantity":parseInt(pastquantity),
      "pastingRate":parseInt(pastRate),
        "designingAmount": parseInt(estimatecalc.designingAmount),
        "paperAmount": parseInt(estimatecalc.paperAmount),
        "PrintAmount": parseInt(estimatecalc.PrintAmount),
        "laminationAmount": parseInt(estimatecalc.laminationAmount),
        "dieAmount": parseInt(estimatecalc.dieAmount),
        "punchingAmount": parseInt(estimatecalc.punchingAmount),
        "pastingAmount": parseInt(estimatecalc.pastingAmount),
        "rimmingAmount": parseInt(estimatecalc.rimmingAmount),
        "bindingAmount": parseInt(estimatecalc.bindingAmount),
        "packingAmount": parseInt(estimatecalc.packingAmount),
        "totalAmount": parseInt(estimatecalc.totalAmount),
        "miscAmount": parseInt(estimatecalc.miscAmount),
        "sum_total": parseInt(estimatecalc.sum_total),
      "createdBy":user._id,
      "orderType":jobtype,
      "name":ordername,
      "jobDetails":jobdetails.toString(),
      // "cuttingSize":cutsize,
      "cuttingQuantity":cutquantity,
      "_id":location.state.item._id
      }
      console.log(obj,'objcal')
      // const obj = {
      //   "deliveryAddress": address,
      //   "paymentType": paymentType,
      //   "paymentStatus": paymentStatus,
      //   "pinCode": pincode,
      //   "customerName": cname,
      //   "customerId": cid,
      //   "mobile": phone,
      //   "gstNo": gst,
      //   "printJobTypeId": bprintJobType._id,
      //   "email": email,
      //   "quantity": parseInt(quantity),
      //   "printing": modeofprinting,
      //   "design": user.username,
      //   "paperType": bpapertype.paperType,
      //   "gsm": parseInt(bpapertype.gsm),
      //   "machine": bmachinetype.details,
      //   "lamination": blamination.details,
      //   "punching": punchingType,
      //   "pasting": pastingtype,
      //   "rimming": pastrimming,
      //   "binding": pastbinding,
      //   "finishLength": parseInt(bfinishsize.length),
      //   "finishBreadth": parseInt(bfinishsize.breadth),
      //   "extraquantity": parseInt(extraquantity),
      //   "millerLength": parseInt(bmillsize.length),
      //   "millerBreadth": parseInt(bmillsize.breadth),
      //   "laminationLength": parseInt(lamlength),
      //   "laminationBreadth": parseInt(lambreadth),
      //   "laminationRate": parseFloat(rate),
      //   "punchingQuantity": parseInt(punchQuantity),
      //   "punchingRate": parseInt(punchRate),
      //   "pastingQuantity": parseInt(pastquantity),
      //   "pastingRate": parseInt(pastRate),
      //   "markupPercentage": parseInt(markup),
      //   "designingAmount": parseInt(estimatecalc.designingAmount),
      //   "paperAmount": parseInt(estimatecalc.paperAmount),
      //   "PrintAmount": parseInt(estimatecalc.PrintAmount),
      //   "laminationAmount": parseInt(estimatecalc.laminationAmount),
      //   "dieAmount": parseInt(estimatecalc.dieAmount),
      //   "punchingAmount": parseInt(estimatecalc.punchingAmount),
      //   "pastingAmount": parseInt(estimatecalc.pastingAmount),
      //   "rimmingAmount": parseInt(estimatecalc.rimmingAmount),
      //   "bindingAmount": parseInt(estimatecalc.bindingAmount),
      //   "packingAmount": parseInt(estimatecalc.packingAmount),
      //   "totalAmount": parseInt(estimatecalc.totalAmount),
      //   "miscAmount": parseInt(estimatecalc.miscAmount),
      //   "sum_total": parseInt(estimatecalc.sum_total),
      //   "createdBy": user._id,
      //   "expectedDate": date,
      //   "expectedTime": time,
      //   "orderType": jobtype,
      //   "attchement": file,
      //   "homeDelivery": ddoption,
      //   "name": ordername,
      //   "jobDetails": jobdetails.toString()
      // }
      const dort = await estimateService.updateestimation(obj);
      console.log(dort, 'dort');
      if (dort.status === 200) {
        await setSBtndis(false)
        navigate('/estimates')
        toast.success('Estimate upadated Successfully', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
    } catch (ex) {
      await setSBtndis(false)
      console.log(ex.response, 'ex.response');
      toast.error(ex.response.data.message, {
        position: toast.POSITION.BOTTOM_LEFT
      });
    }
  }
  const handledoption = (e) => {
    console.log(e, 'e.target.value');
    setDDoption(e)
  }
  const selectprintjobtype = (value) => {
    setPrintJobType(value)
    setBPrintJobType(value[0])
  }

  const handprintingside = (e) => {
    setPrintingside(e.target.value)
  }
  const createclient = async () => {
    if (!cname) {
        await setSBtndis(false)
        toast.error('Select Firm Name', {
            position: toast.POSITION.BOTTOM_LEFT
        });
    } else if (!phone) {
        await setSBtndis(false)
        toast.error('Enter Phone', {
            position: toast.POSITION.BOTTOM_LEFT
        });
    } else if (!address) {
        await setSBtndis(false)
        toast.error('Enter Address', {
            position: toast.POSITION.BOTTOM_LEFT
        });
    } else if (!city) {
        await setSBtndis(false)
        toast.error('Select City', {
            position: toast.POSITION.BOTTOM_LEFT
        });
    
    } else if (!clpincode) {
        await setSBtndis(false)
        toast.error('Enter Pincode', {
            position: toast.POSITION.BOTTOM_LEFT
        });
    } else if (!gstNo) {
        await setSBtndis(false)
        toast.error('Enter Score', {
            position: toast.POSITION.BOTTOM_LEFT
        });
    
    // } else if (!clstatus) {
    //     await setSBtndis(false)
    //     toast.error('Select Status', {
    //         position: toast.POSITION.BOTTOM_LEFT
    //     });
    } else{
        try {
            const obj = {
                "name": cname,
            "address": address,
            "city": city,
            "phoneNo": phone,
            "pincode": pincode,
            "email": 'email',
            "details": "TEST DETAILS",
            // "active": clstatus,
            "score": "100",
            "gstNo": gstNo,
            "pincode": clpincode,
            "state": "TS",
            }
            const dert = await ClientsService.addclients(obj);
            if (dert.data.status === 'success') {
              getclients();
                toast.success('Client Added SuccessFully', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                navigate('/Clients')
            } else {

            }
        } catch (ex) {
            
        }
    }
}

  const selfleangth = (value) => {
    setFlength(value)
    setLamlength(value)
    let size = `size:${value+'X'+fbreadth},`
    setfSize(size)
  }
  const selfbreadth = (value) => {
    setFbreadth(value)
    setLambreadth(value)
    let size = `size:${flength+'X'+value},`
    setfSize(size)
  }
  const handleaccess = (value) => {
    access.push(value)
  };
  const setfinishqty = (e) => {
    setFquantity(e.target.value)
    const det = fextraquantity === '' ? 0: fextraquantity
    const lamqty = parseInt(e.target.value) + parseInt(det)
   
    console.log(lamqty,'lamqty');
    setLamQuantity(lamqty)
    setPunchQuantity(lamqty)
    setPastquantity(lamqty)
    setBindQuantity(lamqty)
  }
  const setfinishextqty = (e) => {
    setFextraquantity(e.target.value)
    const det = fquantity === '' ? 0: fquantity
    const lamqty = parseInt(det) + parseInt(e.target.value)
    console.log(lamqty,'lamqty');
    setLamQuantity(lamqty)
    setPunchQuantity(lamqty);
    setPastquantity(lamqty)
    setBindQuantity(lamqty)
  }
  const handlejobdet = (value) => {
    setJobDetails(value)
  }
  const getjobdet = () => {
   
    
    // let size = '';
    // let bptype = '';
    // let moprint = '';
    // let blam = '';
    // if(flength && fbreadth){
        
    // }else if(Object.keys(bpapertype).length !== 0){
      
    // }else if(modeofprinting){
      
    // }else if(Object.keys(blamination).length !== 0){
     
    // }
    console.log(jobtype,'jobtype')
    const jobdet = fsize ? `${fsize} ${bptype} ${moprint}, ${blam}, ${punchjob}, ${pastjob}` : jobdetails
    console.log(jobdet, 'jobdet' )
    // const jobdet = {flength && fbreadth ?  `size:${flength+'X'+fbreadth},`:null} {bpapertype ? `Paper:${bpapertype.paperTypegsm},` : null} ${modeofprinting}, {blamination ? `Lamination:${blamination.details}` : null}
    return(
      
      <div style={{ marginBottom: '20px' }} className="form-group col-lg-12 col-md-12 col-12">
                    <div class="input-group">
                      <textarea className='textarea_job' value = {jobdet}  >
                      
                      </textarea>
                    </div>
                  </div>
    )
   
  }
  // 

  console.log(estimatecalc, 'estim');

  return (
    <>
      <Header />
      <Container fluid className='containerr'>
        <Row>
          <Col lg={2} id="sidebar-wrapper" className='d-none d-md-block'>
            <Sidebar />
          </Col>
          <Col lg={6} className="p-3">
            <div>
              <h1 className='fs-3 mx-5 mt-3'>Edit Estimate</h1>
              <Breadcrumb className='mx-5 fs-14'>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/estimates">Estimates</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit Estimate</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4">
              <CardContent>
                <div className="row">
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2">
                        {/* <Multiselect
                          className='fs-10 widthInput'
                          placeholder='Firm Name*'
                          options={clients}
                          id='inputcolor'
                          // selectionLimit={1}
                          singleSelect={true}
                          emptyRecordMsg={'Customer Not Found'}
                          selectedValues={client}
                          defaltValue={client}
                          displayValue="name"
                          ref={multiselectRef}
                          onSelect={(value) => selectemps(value)}
                        /> */}
                        {/* <select value={client} onChange={(e) => selectemps(e)} className="form-select text-muted me-2" id='inputcolor' aria-label="Default select example"
                          placeholder='Firm Name*'>
                          <option hidden value>Firm Name*</option>
                          {clients && clients.map((k, index) => {
                            return (
                              <option key={index} value={k} data-index={index}>{k.name}</option>
                            )
                          })}
                        </select> */}
                        <input
                          type="text"
                          className="form-control required me-2"
                          name="name"
                          id='inputcolor'
                          value={cname}
                          onChange={(e) => setCname(e.target.value)}
                          placeholder="Firm Name"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />

                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{ecname ? ecname : null}</div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control text-muted required"
                          name="address"
                          id='inputcolor'
                          value={faddress}
                          disabled
                          // onChange={(e) => setFAddress(e.target.value)}
                          placeholder="Area*"
                          aria-label="Address"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{efaddress ? efaddress : null}</div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="phone"
                          id='inputcolor'
                          value={phone}
                          disabled
                          // onChange={(e) => setPhone(e.target.value)}
                          placeholder="8023456789*"
                          maxLength={10}
                          pattern="[0-9]{10}"
                          aria-label="phone"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{ephone ? ephone : null}</div>
                    </div>
                  </div>

                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6">
                      <div className="input-group mb-3 pe-2">
                        <input
                          type="text"
                          className="form-control required"
                          name="name"
                          id='inputcolor'
                          value={gst}
                          onChange={(e) => setGst(e.target.value)}
                          placeholder="GST Number"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-3 ">
                        <input
                          type="email"
                          className="form-control text-muted required"
                          name="name"
                          id='inputcolor'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        <input
                          type="text"
                          className="form-control required"
                          name="name"
                          id='inputcolor'
                          placeholder="JOB Name/Title*"
                          value={ordername}
                          onChange={(e) => setOrderName(e.target.value)}
                          aria-label="name"
                          aria-describedby="basic-addon1"
                          required
                        />
                        {/* <select value={ordertype}
                          onChange={(e) => handleorderType(e)}
                          class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                          
                        </select> */}
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{eordername ? eordername : null}</div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group mb-3">
                       
                        {/* <select value={jobtype}
                          onChange={(e) => setJobType(e.target.value)}
                          class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                          <option selected>Job Type</option>
                          <option value="Wedding Cards">Wedding Cards</option>
                          <option value="Art Cards">Art Cards</option>
                          <option value="ID Cards">ID Cards</option> */}
                        {/* </select> */}
                        <select value={jobtype} onChange={(e) => seljobtype(e)} className="form-select text-muted me-2" id='inputcolor' aria-label="Default select example"
                          placeholder='Job Type*'>
                          <option hidden value>Job Type*</option>
                          {printJobTypes && printJobTypes.map((k, index) => {
                            return (
                              <option key={index} value={k.details} data-index={index}>{k.details}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{ejobtype ? ejobtype : null}</div>
                    </div>

                    {/* <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          placeholder="Quantity*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div> */}
                  </div>


                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-3" >
                      <div className="input-group mb-2 me-2 pe-2 mt-2">
                        <p style={{fontWeight:'bold',fontSize:'14px',border:'0px'}} className='form-control'>Finish Size</p>
                        {/* <input
                          type="text"
                          className="form-control required"
                          name="Finish Size"
                          id='inputcolor'
                          disabled
                          placeholder="Finish Size"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={flength}
                          onChange={(e) => selfleangth(e.target.value)}
                          placeholder="Length*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{eflength ? eflength : null}</div>
                    </div>

                    <div className="col-lg-2">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={fbreadth}
                          onChange={(e) => selfbreadth(e.target.value)}
                          placeholder="Breadth*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{efbreadth ? efbreadth : null}</div>
                    </div>
                    <div className="col-lg-2">
                      <div className="input-group mb-3 ms-2">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={fquantity}
                          onChange={(e) => setfinishqty(e)}
                          placeholder="Quantity*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{efquantity ? efquantity : null}</div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-3 pe-3">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={fextraquantity}
                          onChange={(e) => setfinishextqty(e)}
                          placeholder="Extra Quantity*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Paper</small></h6>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        {papertype ? 
                            <select value={papertype} onChange={(e) => selectpapertype(e)} className="form-select text-muted me-2" id='inputcolor' aria-label="Default select example"
                            placeholder='Paper and GSM*'>
                            <option hidden value>Paper and GSM*</option>
                            {gsmoptions && gsmoptions.map((k, index) => {
                              return (
                                <option key={index} value={k.paperTypegsm} data-index={index}>{k.paperTypegsm}</option>
                              )
                            })}
                          </select> : null
                        }
                      
                        {/* <Multiselect
                          placeholder='Paper and GSM*'
                          options={gsmoptions}
                          className='widthInput'
                          id='inputcolor'
                          selectionLimit={1}
                          singleSelect={true}
                          emptyRecordMsg={'Paper Type and GSM Not Found'}
                          selectedValues={papertype}
                          defaltValue={papertype}
                          displayValue={'paperTypegsm'}
                          ref={papermultiselectRef}
                          onSelect={(value) => selectpapertype(value)}
                        /> */}
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{epapertype ? epapertype : null}</div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3">
                        {/* <Multiselect placeholder='Mill Size*'
                       options={millsizes} 
                        id='inputcolor' 
                        selectionLimit={1}
                        singleSelect={true}
                        emptyRecordMsg={'Millsizes Not Found'}
                        selectedValues={millsize} 
                        defaltValue={millsize} 
                        displayValue={'milliText'} 
                        ref={millimultiselectRef} 
                        onSelect={(value) => selectmillsize(value)} /> */}
                        <select value={millsize}  onChange={(e) => selectmillsize(e)} class="form-select text-muted" placeholder='Mode of Printing' id='inputcolor' aria-label="Default select example">
                          <option selected>Mill Size*</option>
                          {millsizes && millsizes.map((k, index) => {
                            return (
                              <option key={index} value={k.milliText} data-index={index}>{k.milliText}</option>
                            )
                          })}
                        </select>

                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{emillsize ? emillsize : null}</div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={millquantity}
                          onChange={(e) => setMillQuantity(e.target.value)}
                          placeholder="Quantity*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{emillquantity ? emillquantity : null}</div>
                    </div>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >

                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3">
                      <select value={cutsize} onChange={(e) => selectcutsize(e)} class="form-select text-muted" placeholder='Mode of Printing' id='inputcolor' aria-label="Default select example">
                          <option selected>Cutting Size*</option>
                          {cutsizes && cutsizes.map((k, index) => {
                            return (
                              <option key={index} value={k.cuttingSize} data-index={index}>{k.cuttingSize}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{ecutsize ? ecutsize : null}</div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={cutquantity}
                          onChange={(e) => setCutQuantity(e.target.value)}
                          placeholder="Quantity*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{ecutquantity ? ecutquantity : null}</div>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Design</small></h6>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        <select
                          value={design}
                          onChange={(e) => setDesign(e.target.value)}
                          type='text'
                          placeholder='design Amount'
                          class="form-select text-muted" id='inputcolor' aria-label="Design Amount">
                          <option value="Design">Design</option>
                          <option value="Design By Arets">Design By Arets</option>
                          <option value="Design by party">Design by party</option>
                        </select>
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{edesign ? edesign : null}</div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-group mb-3">
                        <input
                          type="number"
                          className="form-control required "
                          name="number"
                          id='inputcolor'
                          value={designamount}
                          onChange={(e) => setDesignAmount(e.target.value)}
                          placeholder="Amount"
                          aria-label="design"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{edesignamount ? edesignamount : null}</div>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Printing</small></h6>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        <select value={modeofprinting} onChange={(e) => handlemode(e)} class="form-select text-muted" placeholder='Mode of Printing' id='inputcolor' aria-label="Default select example">
                          <option selected>Mode of Printing*</option>
                          {/* <option value="MSB">MSB</option>
                          <option value="KL-26">KL-26</option>
                          <option value="KL-20">KL-20</option>
                          <option value="Digital">Digital</option> */}
                          {machines && machines.map((k, index) => {
                            return (
                              <option key={index} value={k.details} data-index={index}>{k.details}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className='ms-2' style={{ color: '#FF0000', fontSize: '11px' }}>{emodeofprinting ? emodeofprinting : null}</div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3">
                        <select value={printingside} onChange={(e) => handprintingside(e)} class="form-select text-muted" placeholder='Printing Side' id='inputcolor' aria-label="Default select example">
                          <option selected>Printing Side</option>
                          <option value="OS">OS</option>
                          <option value="BB">BB</option>
                          <option value="FB">FB</option>
                          <option value="OPPG">OPPG</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        {/* <input
                          type="text"
                          className="form-control required"
                          name="quantity"
                          id='inputcolor'
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          placeholder="Quantity*"
                          maxLength={10}
                          aria-label="quantity"
                          aria-describedby="basic-addon1"
                          required
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Lamination</small></h6>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                      <select value={lamination} onChange={(e) => selectlamination(e)} class="form-select text-muted" placeholder='Lamination Type' id='inputcolor' aria-label="Default select example">
                          <option selected>Lamination Type</option>
                          {/* <option value="MSB">MSB</option>
                          <option value="KL-26">KL-26</option>
                          <option value="KL-20">KL-20</option>
                          <option value="Digital">Digital</option> */}
                          {laminations && laminations.map((k, index) => {
                            return (
                              <option key={index} value={k.details} data-index={index}>{k.details}</option>
                            )
                          })}
                        </select>
                        {/* <Multiselect
                          placeholder=' Lamination Type*'
                          options={laminations}
                          id='inputcolor'
                          selectionLimit={1}
                          singleSelect={true}
                          emptyRecordMsg={'Lamination Types Not Found'}
                          selectedValues={lamination}
                          defaltValue={lamination}
                          displayValue={'details'}
                          ref={laminationmultiselectRef}
                          onSelect={(value) => selectlamination(value)}
                        /> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-2">
                        <input
                          type="length"
                          className="form-control required"
                          name="length"
                          id='inputcolor'
                          value={lamlength}
                          onChange={(e) => setLamlength(e.target.value)}
                          placeholder="Length"
                          aria-label="length"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="breadth"
                          className="form-control required"
                          name="breadth"
                          value={lambreadth}
                          onChange={(e) => setLambreadth(e.target.value)}
                          id='inputcolor'
                          placeholder="Breadth"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="rate"
                          id='inputcolor'
                          placeholder="Rate*"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                          aria-label="rate"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        {/* <Multiselect
                          placeholder='Select Lamination Type*'
                          options={laminations}
                          id='inputcolor'
                          selectionLimit={1}
                          singleSelect={true}
                          emptyRecordMsg={'Lamination Types Not Found'}
                          selectedValues={lamination}
                          defaltValue={lamination}
                          displayValue={'details'}
                          ref={laminationmultiselectRef}
                          onSelect={(value) => selectlamination(value)}
                        /> */}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-2">
                        <input
                          type="length"
                          className="form-control required"
                          name="length"
                          id='inputcolor'
                          value={lamquantity}
                          onChange={(e) => setLamQuantity(e.target.value)}
                          placeholder="Quantity"
                          aria-label="length"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="breadth"
                          value={lamrate}
                          onChange={(e) => setLamRate(e.target.value)}
                          id='inputcolor'
                          placeholder="Rate"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    {/* <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="rate"
                          id='inputcolor'
                          placeholder="Rate*"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                          aria-label="rate"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div> */}
                  </div>

                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <h6 className="mt-2"><small>Die</small></h6>
                    <div className='col-lg-9'></div>


                    <div className="col-lg-2">
                      <div className="input-group mb-3 ms-2">
                        <input
                          type="text"
                          className="form-control required"
                          name="breadth"
                          value={dieamount}
                          onChange={(e) => setDieAmount(e.target.value)}
                          id='inputcolor'
                          placeholder="Amount"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Punching</small></h6>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        <select value={punchingType} onChange={(e) => selpunchingtype(e)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                          {/* <option selected>Punching Type*</option>
                          <option value="Full Punching">Full Punching</option>
                          <option value="Half Punching">Half Punching</option>
                          <option value="None">None</option> */}
                          <option selected>Punching Type</option>
                          {punchingTypes && punchingTypes.length > 0 ? 
                              punchingTypes.map((k,index) => {
                                return(
                                  <option key={index} value={k.type} data-index={index}>{k.type}</option>
                                )
                              }) : null
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-2">
                        <input
                          type="length"
                          className="form-control required"
                          name="length"
                          id='inputcolor'
                          value={punchQuantity}
                          onChange={(e) => setPunchQuantity(e.target.value)}
                          placeholder="Quantity"
                          aria-label="length"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="breadth"
                          className="form-control required"
                          name="breadth"
                          value={punchRate}
                          disabled
                          // onChange={(e) => setPunchRate(e.target.value)}
                          id='inputcolor'
                          placeholder="Rate"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Pasting</small></h6>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6">
                      <div className="input-group mb-1 pe-2">
                        <select value={pastingtype} onChange={(e) => selpasting(e)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                          <option selected>Pasting Type</option>
                          {pastingtypes && pastingtypes.length > 0 ? 
                            pastingtypes.map((k,index) => {
                              return(
                                <option key={index} value={k.type} data-index={index}>{k.type}</option>
                              )
                            }) : null
                          }
                          {/* <option value="1">Pasting Required</option>
                          <option value="2">None</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3">
                        <input
                          type="Quantity"
                          className="form-control required"
                          name="Quantity"
                          value={pastquantity}
                          onChange={(e) => setPastquantity(e.target.value)}
                          id='inputcolor'
                          placeholder="Quantity"
                          aria-label="Quantity"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="text"
                          className="form-control required"
                          name="rate"
                          value={pastRate}
                          onChange={(e) => setPastRate(e.target.value)}
                          id='inputcolor'
                          placeholder="Rate"
                          aria-label="rate"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>




                  <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="ml-2"><small>Finishing</small></h6>
                  </div>

                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="input-group mb-2 me-2 pe-2">
                        <select value={bindingtype} onChange={(e) => setBindingType(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                          <option value="Cutting">Cutting</option>
                          <option value="Pinning">Pinning</option>
                          <option value="Rimming">Rimming</option>
                          {/* <option value="Perfect Binding">Perfect Binding</option>
                          <option value="Pinning">Pinning</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-2">
                        <input
                          type="length"
                          className="form-control required"
                          name="length"
                          id='inputcolor'
                          value={bindquantity}
                          onChange={(e) => setBindQuantity(e.target.value)}
                          placeholder="Quantity"
                          aria-label="length"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                    {/* <div  className="col-lg-3">
                    <Button onClick={() => submit()} id='pillStyle' className='pull-right float-right btn-lg w-80 mb-3' variant="primary">Enter</Button>
                    </div> */}
                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="breadth"
                          className="form-control required"
                          name="breadth"
                          value={bindrate}
                          onChange={(e) => setBindRate(e.target.value)}
                          id='inputcolor'
                          placeholder="Rate"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <div className="col-lg-6" >
                      <div className="form-group col-lg-12 col-md-12 col-12">
                    <h6 className="mt-3"><small>Miller</small></h6>
                  </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="input-group mb-2">
                        <input
                          type="length"
                          className="form-control required"
                          name="length"
                          id='inputcolor'
                          value={millerLength}
                          onChange={(e) => setMillerLength(e.target.value)}
                          placeholder="Length*"
                          aria-label="length"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="input-group mb-3 ms-1">
                        <input
                          type="breadth"
                          className="form-control required"
                          name="breadth"
                          value={millerBreadth}
                          onChange={(e) => setMillerBreadth(e.target.value)}
                          id='inputcolor'
                          placeholder="Breadth*"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div> */}


                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    <h6 className="mt-2"><small>Packing & Transport</small></h6>
                    <div className='col-lg-7'></div>


                    <div className="col-lg-2">
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control required"
                          name="breadth"
                          value={packingamount}
                          onChange={(e) => setPackingAmount(e.target.value)}
                          id='inputcolor'
                          placeholder="Amount"
                          aria-label="Breadth"
                          aria-describedby="basic-addon1"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group col-lg-12 col-sm-12 col-md-12 col-12 d-flex'>
                    {/* <h6 className="ml-2"><small>Others</small></h6> */}
                    <div className="input-group mb-2 me-2 pe-2">
                      <div className='col-lg-9'>
                        <div className="input-group mb-3 me-1">
                          <input
                            type="text"
                            className="form-control required"
                            name="breadth"
                            value={others}
                            onChange={(e) => setOthers(e.target.value)}
                            id='inputcolor'
                            placeholder="Others"
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className='col-lg-1'></div>
                      <div className="col-lg-2">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control required"
                            name="breadth"
                            value={otheramount}
                            onChange={(e) => setOtherAmount(e.target.value)}
                            id='inputcolor'
                            placeholder="Amount"
                            aria-label="Breadth"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id='inputcolor'
                            placeholder="Mark up"
                            value={markup}
                            onChange={(e) => setMarkup(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                    </div></div>



                  <div className="form-group col-lg-6 col-md-6 col-12">
                    <div className="row">

                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control required"
                            name="name"
                            id='inputcolor'
                            placeholder="Order Name/Title*"
                            value={ordername}
                            onChange={(e) => setOrderName(e.target.value)}
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div> */}

                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">

                        </div>
                      </div>
                      {/* {user ?
                        <div className="form-group col-lg-12 col-md-12 col-12">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control required"
                              name="name"
                              value={user.username}
                              disabled
                              id='inputcolor'
                              placeholder="Booked By*"
                              aria-label="name"
                              aria-describedby="basic-addon1"
                              required
                            />
                            
                          </div>
                        </div> : null
                      } */}


                      <div className='form-group col-lg-12 col-md-12 col-12'>
                        {/* <div className="row">
                          <div className="col-lg-6"> */}
                        <div className="input-group mb-3">


                        </div>
                        {/* </div>

                        </div> */}
                      </div>






                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="ml-2"><small>Finish Size</small></h6>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input-group mb-2">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                id='inputcolor'
                                value={bpapertype.paperTypegsm}
                                disabled
                                placeholder="Paper*"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control required"
                                name="rate"
                                id='inputcolor'
                                value={extraquantity}
                                onChange={(e) => setExtraquantity(e.target.value)}
                                placeholder="Extra Quantity*"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                   
                  
                  </div> */}

                      {/* second column */}
                      {/* <div className="form-group col-lg-6 col-md-6 col-12">
                    <div className="row"> */}

                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="ml-2"><small>Lamination</small></h6>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="form-group col-lg-12 col-md-12 col-12">
                            <div className="input-group mb-1">
                              <Multiselect
                                placeholder='Select Lamination Type*'
                                options={laminations}
                                id='inputcolor'
                                selectionLimit={1}
                                singleSelect={true}
                                emptyRecordMsg={'Lamination Types Not Found'}
                                selectedValues={lamination}
                                defaltValue={lamination}
                                displayValue={'details'}
                                ref={laminationmultiselectRef}
                                onSelect={(value) => selectlamination(value)}
                              />

                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-group mb-2">
                              <input
                                type="length"
                                className="form-control required"
                                name="length"
                                id='inputcolor'
                                value={lamlength}
                                onChange={(e) => setLamlength(e.target.value)}
                                placeholder="Length*"
                                aria-label="length"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="input-group mb-3">
                              <input
                                type="breadth"
                                className="form-control required"
                                name="breadth"
                                value={lambreadth}
                                onChange={(e) => setLambreadth(e.target.value)}
                                id='inputcolor'
                                placeholder="Breadth*"
                                aria-label="Breadth"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-group mb-2">
                          <input
                            type="text"
                            className="form-control required"
                            name="rate"
                            id='inputcolor'
                            placeholder="Rate*"
                            value={rate}
                            onChange={(e) => setRate(e.target.value)}
                            aria-label="rate"
                            aria-describedby="basic-addon1"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="ml-2"><small>Punching</small></h6>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="row">
                          <div className="form-group col-lg-12 col-md-12 col-12">
                            <div className="input-group mb-1">
                              <select value={punchingType} onChange={(e) => setPunchingType(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                <option selected>Punching Type*</option>
                                <option value="Full Punching">Full Punching</option>
                                <option value="Half Punching">Half Punching</option>
                                <option value="None">None</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-group mb-3">
                              <input
                                type="Quantity"
                                value={punchQuantity}
                                onChange={(e) => setPunchQuantity(e.target.value)}
                                className="form-control required"
                                name="Quantity"
                                id='inputcolor'
                                placeholder="Quantity*"
                                aria-label="Quantity"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control required"
                                name="rate"
                                value={punchRate}
                                onChange={(e) => setPunchRate(e.target.value)}
                                id='inputcolor'
                                placeholder="Rate*"
                                aria-label="rate"
                                aria-describedby="basic-addon1"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}






                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <div className="input-group mb-3">
                          {!file ?
                            <button onClick={handleClick} type="file" class="btn btn-outline-primary w-100">
                              <input type="file" accept="application/pdf, image/jpeg, image/png"
                                ref={hiddenFileInput}
                                onChange={changeHandler}
                                style={{ display: 'none' }}
                              />
                              <img className="menuIcon mb-1 mx-1" src={UploadImageIcon} />
                              <img className="menuIcon mb-1 mx-1" src={uploadCloudImage} />
                              Upload Print File</button> :
                            <div>
                              {file.includes('pdf') ?
                                <div>
                                  <div style={{ cursor: 'pointer' }} onClick={() => delmg()}><span style={{ position: 'absolute', right: 0, top: -12, backgroundColor: 'red', width: 20, height: 20, borderRadius: 100, alignItems: 'center', justifyContent: 'center' }}><i style={{ color: 'white', marginLeft: '4px' }} className='fa fa-times'></i></span></div>
                                  <iframe
                                    id="attachment"

                                    src={file}
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div> :
                                <div>
                                  <div style={{ cursor: 'pointer' }} onClick={() => delmg()}><span style={{ position: 'absolute', left: 68, top: -12, backgroundColor: 'red', width: 20, height: 20, borderRadius: 100, alignItems: 'center', justifyContent: 'center' }}><i style={{ color: 'white', marginLeft: '4px' }} className='fa fa-times'></i></span></div>
                                  <img src={file} style={{ width: 80, height: 80 }} />
                                </div>

                              }
                            </div>
                          }

                        </div>
                      </div> */}
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <div class="form-check form-switch">
                          <Switch width={50} height={20} onChange={(e) => handledoption(e)} className="react-switch" checked={ddoption} uncheckedIcon={false} />
                          <label class="form-check-label text-muted" for="flexSwitchCheckDefault">Door Delivery Option</label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-12">
                  <h6 className=" mt-2 ml-1"><small>Job Details</small></h6>
                </div>
                {getjobdet()}
                <div style={{ textAlign: 'right' }}>
                  <Button onClick={() => submit()} id='pillStyle' className='pull-right float-right btn-lg w-80 mb-3' variant="primary">Calculate</Button>

                </div>
              </CardContent>
            </Card>
          </Col>
          <Col lg={4} style={{ marginTop: '54px' }}>
            <Card className="mt-6 rounded-4" style={{ marginTop: '60px' }}>
              <CardContent id="capture" ref={payref}>
                <div className='d-flex mb-4'>
                  <img
                    src={aretsLogo}
                    alt='branding'
                    className='loginPage-brandImg'
                    onClick={() => window.location.reload()}
                  />
                  <h3 style={{ fontFamily: 'Yeseva One', fontWeight: '400' }}>AReTS Graphics
                    <p style={{ fontFamily: 'Clash Display', fontSize: '22px' }}>Everything in Printing</p>
                  </h3>
                </div>
                {/* <h6><small>John Doe, Hyderabad</small></h6> */}
                <h6><small>15-4-64, Gowliguda Chaman, Hyderabad 500012</small></h6>
                <h6><small>Phone: 040-9393009990</small></h6>
                <h6><small>GSTIN: 36AEYPM2529L1ZR</small></h6>
                <h5 className='mt-3 mb-3'>Client Details</h5>
                <h6><small>Firm Name: {cname}</small></h6>
                <h6><small>Address: {faddress}{address}{pincode}</small></h6>
                <h6><small>{faddress}{address}{pincode}</small></h6>
                <h6><small>Phone: {phone}</small></h6>
                <h6><small>GSTIN: {gst}</small></h6>
                <h4 className='mt-3 mb-4'>Cost Estimate</h4>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Designing
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.designingAmount) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Paper
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.paperAmount) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Printing
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? estimatecalc.PrintAmount : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Lamination
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc && estimatecalc.laminationAmount ? estimatecalc.laminationAmount.toFixed(2) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Die
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.dieAmount) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Punching
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? estimatecalc.punchingAmount : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Pasting
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? estimatecalc.pastingAmount : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Rimming
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.rimmingAmount) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Binding
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.bindingAmount) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Packing
                  </div>
                  <div className="col-lg-3">
                    ₹ {packingamount ? packingamount : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Sub Total
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.sum_total) : 0}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Misc %
                  </div>
                  <div className="col-lg-3">
                    ₹ {estimatecalc ? estimatecalc.miscAmount : 0}
                  </div>
                </div>
                <hr></hr>
                <div className="row mt-2">
                  <div className="col-lg-9 text-muted">
                    Total
                  </div>

                  <div className="col-lg-3">
                    ₹ {estimatecalc ? parseInt(estimatecalc.totalAmount) : 0}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8 text-muted">
                    <span style={{ fontSize: '11px' }}>* 18% GST Extra</span>
                  </div>
                </div>

                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-5">
                      <div className="input-group">
                        <Button as={NavLink} to='/estimates' id='pillStyle' className='w-100' variant="outline-primary">Cancel</Button>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group">
                        <Button onClick={() => handlesubmit()} id='pillStyle' className='w-100' variant="primary">Update Estimate</Button>                         </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-12 col-md-12 col-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-group justify-content-center">
                        {/* <Nav.Item id="navAccord" className="navLink">
                          <Nav.Link onClick={() => savepdf()} className=" text-primary mt-4 p-0">Print Estimate</Nav.Link>
                        </Nav.Item>     */}
                        <Button onClick={() => savepdf()} id='pillStyle' className=' text-primary w-100 mt-4 p-0' variant="outline-light">Print Estimate</Button>
                      </div>
                    </div>
                    {/* <div className="col-lg-5">
                      <div className="input-group">
                        <Nav.Item id="navAccord" className="navLink">
                          <Nav.Link as={NavLink} to='' className="text-primary text-center mt-4 p-0">Send Email</Nav.Link>
                        </Nav.Item>                            </div>
                    </div> */}
                  </div>
                </div>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ width: '35%', marginLeft: '30%' }}
      >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Client                </Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Card className=" rounded-4">
            <CardContent>
            <div className="row ">
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={cname}
                                                        onChange={(e) => setCname(e.target.value)} id='inputcolor'
                                                        placeholder="Firm Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                           

                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="row">
                                                   
                                                    <div className="col-lg-12">
                                                        <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control required"
                                                                name="phone"
                                                                value={phone}
                                                                onChange={(e) => setPhone(e.target.value)}
                                                                id='inputcolor'
                                                                placeholder="8023456789"
                                                                aria-label="phone"
                                                                aria-describedby="basic-addon1"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={address}
                                                        id='inputcolor'
                                                        placeholder="Address"
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={city}
                                                        id='inputcolor'
                                                        placeholder="City"
                                                        onChange={(e) => setCity(e.target.value)}
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                  
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={clpincode}
                                                        id='inputcolor'
                                                        onChange={(e) => setCLPincode(e.target.value)}
                                                        placeholder="Pin Code"
                                                        maxLength={6}
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={gstNo}
                                                        id='inputcolor'
                                                        onChange={(e) => setGstNo(e.target.value)}
                                                        placeholder="GST Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={clstatus} onChange={(e) => setCLStatus(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Status</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>In Active</option>
            
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <Button id='pillStyle' className='w-100' variant="outline-primary">Cancel</Button>
                                                </div>
                                                <div className="col-lg-6">
                                                    <Button onClick={() => createclient()} id='pillStyle' className='w-100' variant="primary">Create Client</Button>
                                                </div>
                                            </div>
                                    </div>

                                    {/* Second Column */}

                                    {/* <div className="form-group col-lg-6 col-md-6 col-12">
                                        <div className="row"> */}

                                           

                                            {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <select value={paymenttype} onChange={(e) => setPaymentType(e.target.value)} class="form-select text-muted" id='inputcolor' aria-label="Default select example">
                                                        <option selected hidden>Payment Type</option>
                                                        <option value="1">Cash</option>
                                                        <option value="1">UPI</option>
                                                        <option value="2">Credit Card</option>
                                                        <option value="3">Debit Card</option>
                                                    </select>
                                                </div>
                                            </div> */}

                                            {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="email"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={bankname}
                                                        id='inputcolor'
                                                        onChange={(e) => setBankName(e.target.value)}
                                                        placeholder="Bank Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={accountnumber}
                                                        id='inputcolor'
                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                        placeholder="Account Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={IFSCcode}
                                                        id='inputcolor'
                                                        onChange={(e) => setIFSC(e.target.value)}
                                                        placeholder="IFSC Code"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}
                                            {/* <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={branchname}
                                                        id='inputcolor'
                                                        onChange={(e) => setBranchName(e.target.value)}
                                                        placeholder="Branch Name"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group col-lg-12 col-md-12 col-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-muted required"
                                                        name="name"
                                                        value={pan}
                                                        id='inputcolor'
                                                        onChange={(e) => setPanNumber(e.target.value)}
                                                        placeholder="PAN Number"
                                                        aria-label="name"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div> */}

                                            
                                        {/* </div>
                                    </div> */}
                                </div>
            </CardContent>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  console.log(state, 'state');
  return {
    userdata: state.userdetails
  };
};

export default connect(mapStateToProps)(EditEstimate);