import httpService from "./httpService";


export async function getclients(obj) {
	
	const data = await httpService.post('/masterData/customers/getAll',obj);
	return data;
}
export async function addclients(obj){

	const data = httpService.post('/masterData/customers/add',obj);
	return data;
}
export async function updateclients(obj){

	const data = httpService.post('/masterData/customers/update',obj);
	return data;
}
export async function deleteClients(obj) {
	const data = await httpService.post('/masterData/customers/delete', obj);
	return data;
}
export default {
	getclients,
	addclients,
	updateclients,
	deleteClients
}