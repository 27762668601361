import React, { useState } from "react";
import aretsLogo from "../../Images/aretsLogo.png";
import "../../Styles/LoginPage.css";
import "../../Styles/JobCards.css";
import imageSvg from "../../Images/imageSvg.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import authService from "../../services/authService";
import { toast } from "react-toastify";
import showPwdImg from "../../Images/show-password.svg";
import hidePwdImg from "../../Images/hide-password.svg";
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import FormControl from '@mui/material/FormControl';
// import FilledInput from '@mui/material/FilledInput';
// import InputLabel from '@mui/material/InputLabel';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btndis, setBtndis] = useState(false);
  // const [passvis, setpass] = useState(true)
  // const [showPassword, setShowPassword] = React.useState(false);
  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const handleChangepass = async (e) => {
  //   await setPassword(e.target.value)

  // }
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  let navigate = useNavigate();
  const handleClick = async () => {
    setBtndis(true);
    if (!email) {
      await setBtndis(false);
      toast.error("Enter Email or UserId", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else if (!password) {
      await setBtndis(false);
      toast.error("Enter Password", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      try {
        const obj = {
          username: email,
          password: password,
        };

        const data = await authService.login(obj);
        console.log(data);
        if (data.data.status === "success") {
          setBtndis(false);
          navigate("/dashboard");
        } else {
          setBtndis(false);
          toast.error("Not a Registered User", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } catch (ex) {
        setBtndis(false);
        if (ex.response.data.status === "failed")
          toast.error(ex.response.data.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
      }
    }
  };
  return (
    <>
      <head>
        <script type="text/javascript">
          function preventBack() {window.history.forward()}
          setTimeout("preventBack()", 0); window.onunload = function () {null};
        </script>
      </head>
      <Container fluid>
        <Row>
          <Col
            lg={3}
            sm={11}
            md={4}
            className="loginBg vh-100 d-none d-md-block"
          >
            <img
              src={imageSvg}
              alt="Printer"
              className="printmachine"
              onClick={() => window.location.reload()}
            />
            <h4 className="mx-5 mt-3 mb-3">What we offer!</h4>
            <ul className="whatwedo mx-3">
              <li>Front Desk Operations</li>
              <li>Floor Management</li>
              <li>Inventory Management</li>
              <li>Job card Tracking</li>
              <li>Printing Status</li>
              <li>Delivery Status</li>
              <li>Payment Invoices</li>
              <li>Analytics</li>
            </ul>
          </Col>
          <Col className="align-items-center justify-content-center d-flex mt-5">
            <Col lg={5} sm={12} md={8}>
              <div className="center mt-5 d-flex ">
                <img
                  src={aretsLogo}
                  alt="branding"
                  className="loginPage-brandImg"
                  onClick={() => window.location.reload()}
                />
                <div>
                  <h3
                    style={{ fontFamily: "Myriad Pro" }}
                    className="loginPage-title"
                  >
                    AReTS Graphics
                  </h3>
                  <p className="ms-3 loginPage-subtitle">
                    Everything In Printing{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-9 col-md-10 col-sm-10">
                <h2 className="mt-4 mb-3 ms-2">Sign In</h2>
                <div className="col-lg-10 col-md-11 col-sm-6 ms-2">
                  <Form.Group className="mb-3 SubmitButton">
                    <Form.Control
                      id="inputbox"
                      value={email}
                      minLength={6}
                      maxLength={24}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email/User ID*"
                    />
                  </Form.Group>
                  <div
                    className="input-group form-group mb-3 pwd-container "
                    id="pwdWidth"
                  >
                    <input
                      name="pwd"
                      className="border-0 SubmitButton"
                      id="inputbox"
                      placeholder="Enter Password*"
                      type={isRevealPwd ? "text" : "password"}
                      value={password}
                      minLength={6}
                      maxLength={24}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  </div>
                  {/* <Form.Group className="mb-3" >
                    <Form.Check type="checkbox" label="Remember Me" />
                  </Form.Group> */}
                  {!btndis ? (
                    <Button
                      onClick={() => handleClick()}
                      className="mb-4 SubmitButton"
                      variant="primary"
                      type="submit"
                      size="md"
                    >
                      Sign In
                    </Button>
                  ) : (
                    <Button
                      className="mb-4 SubmitButton"
                      variant="primary"
                      type="submit"
                      size="md"
                    >
                      Processing...
                    </Button>
                  )}
                </div>
                {/* <h6 className='mx-3 mt-5 mb-5 text-secondary recaptcha'>This site is protected by reCAPTCHA and the <br></br> Google Privacy Policy.</h6> */}
                {/* <ul className='signinmenu'>
             <li as={NavLink} to='/OrderTracking'>Order's Tracking</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <li>FAQ'S</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
             <li>Terms & Conditions</li>
           </ul> */}
                <div className="col-lg-12 col-sm-6 col-md-12">
                  <Nav className="mb-3">
                    <Nav.Item id="navAccord" className="navLink">
                      <Nav.Link
                        as={NavLink}
                        to="/newuserOrderTracking"
                        className="text-primary p-0"
                      >
                        Order Tracking
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="navAccord" className="navLink">
                      <Nav.Link
                        as={NavLink}
                        to="/"
                        className="text-primary p-0"
                      >
                        FAQ'S
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="navAccord" className="navLink">
                      <Nav.Link
                        as={NavLink}
                        to="/"
                        className="text-primary p-0"
                      >
                        Terms & Conditions
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <p className="copyright">
                  Copyright © 2023 -AReTS Graphics. All Rights Reserved
                </p>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginPage;
