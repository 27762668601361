import React, { useEffect, useState, useRef } from "react";
import "../../Styles/Orders.css";
import Header from "../header/Header";
import Container from "react-bootstrap/Container";
import Sidebar from "../Sidenav/Sidebar";
import aretsLogo from "../../Images/aretsLogo.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
// import Paper from '@mui/material/Paper';
import { connect } from "react-redux";

// import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
// import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import Button from "react-bootstrap/Button";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import JobcardsService from "../../services/JobcardsService";
import estimateService from "../../services/estimateService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ModalSubmit from "../modalSubmit";

function JobIDPage(props) {
  const location = useLocation();

  const [jobcards, setjobcards] = useState([]);

  useEffect(() => {
    getjobcards(0, 10);
  }, [props.userdata]);

  const getjobcards = async (skps, rpp) => {
    try {
      const obj = {
        jobId: location.state.item?.orderId,
      };
      const dert = await JobcardsService.getjobcards(obj);
      if (dert.data.status === "success") {
        console.log(dert.data.value, "values");
        setjobcards(dert.data.value);
        // setJobCardsCount(dert.data.count);
      }
    } catch (ex) {}
  };
  const scaleFactor = 210 / 100; // Assuming elementWidth is the width of the "capture" element
  const scaledHeight = 100 * scaleFactor;
  const payref = useRef();
  const [tab, setTab] = useState("Order Confirmation");
  const [payview, setpayview] = useState(true);
  const savepdf = () => {
    html2canvas(document.querySelector("#capture"), { dpi: 300 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, scaledHeight); 
            pdf.save("Job Card Confirmation.pdf");
    });
  };
  console.log(location.state.item, "location.state.item");

  const steps = [
    "Order Confirmed Tue,17th Jan 23",
    "Job Card Created",
    "Printing",
    "Post Printing",
    "Packing",
    "Out for Delivery",
    "Delivered",
  ];
  return (
    <>
      <Header />
      <Container fluid className="containerr">
        <Row>
          <Col lg={2} id="sidebar-wrapper" className="d-none d-md-block">
            <Sidebar />
          </Col>
          <Col lg={9} className="p-3">
            <div>
              <h1 className="fs-3 mx-5 mt-3">
                Job ID:<span className="orderNumColor"> #JID31012301</span>
              </h1>
              <Breadcrumb className="mx-5 fs-14">
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/jobcards">Job Cards</Breadcrumb.Item>
                <Breadcrumb.Item active>Job ID</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card sx={{ minWidth: 275 }} className="mt-4 rounded-4 ms-4">
              <CardContent className="mt-2" id="capture">
              <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 col-md-12">
                                                <div className="d-flex">
                                                  <div className="col-lg-1"></div>
                                                <div className="col-lg-7 mb-2 mt-5">
                              <img
                                src={aretsLogo}
                                alt="branding"
                                className="loginPage-brandImg mb-1"
                                style={{width: '70px', height: '60px', marginLeft : '85px',}}
                              />
                              <h3  style={{  fontSize: '32px', fontWeight: "400px" }}>
                               <span style={{color : '#1896cc'}}> AReTS GRAPHICS</span>
                                <h6 className='ms-lg-4 ' style={{ fontSize: "16px", fontWeight: '400' }}>
                                  EVERYTHING IN PRINTING
                                </h6>
                                </h3>
                            </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <p className='mb-3'><span className="fw-bolder">Date :</span> {location.state?.item?.date}</p>
                                                        <p className='m-0'><span className="fw-bolder">Order ID :</span> {location.state?.item?.orderId}</p>
                                                        <p className='mb-3'><span className="fw-bolder">Job ID :</span> {location.state?.item?.jobId}</p>
                                                        <p className='m-0'><span className="fw-bolder">Firm Name :</span> {location.state?.item?.customerName}</p>
                                                        <p className='mb-3'><span className="fw-bolder">Phone No :</span> {location.state?.item?.orId.mobile}</p>
                                                        <p className='m-0'><span className="fw-bolder">Job Name :</span> {location.state?.item?.jobName}</p>
                                                        <p className='m-0'><span className="fw-bolder">Job Type :</span> {location.state?.item?.orderType}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                <div className="row border-dark border-top border-bottom ">
                  <div className="col-lg-6 border-dark border-end">
                    <h6 className="fw-bolder mt-2">Paper Details:</h6>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Paper with Gsm</small>
                            </div>
                            {/* <div className='text-muted fs-15 fw-semibold'><small>GSM</small></div> */}
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Mill Size</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Mill Quantity</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Cutting Size</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Cutting Qty</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Paper Vendor</small>
                            </div>
                          </Col>
                          <Col lg={6} className="lh-lg">
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.paperGsm} GSM</small>
                            </div>
                            {/* <div className='fw-semibold fs-15'><small>90</small></div> */}
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.orId.estimateId.millerBreadth + " * " + location.state?.item?.orId.estimateId.millerLength}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperQuantity}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperCuttingSize}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperCuttingQty}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperVendor}
                              </small>
                            </div>
                          </Col>
                        </div>
                      </div>
                  </div>
                  <div className="col-lg-6">
                  <h6 className="fw-bolder mt-2">Plate Details:</h6>
                  <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Color Proof</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Plate By</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>No. of Colours</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>No. of Sets</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Plate Vendor</small>
                            </div>
                          </Col>
                          <Col lg={6} className="lh-lg">
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.colorProof}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.agAou}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.colors}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.noOfSets}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.plateBy}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                  </div>
                  </div>

                <div className="row border-dark border-bottom">
                  <div className="col-lg-6 border-dark border-end">
                    <h6 className="fw-bolder mt-2">Printing Details:</h6>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Mode of Printing</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Print Colors</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Printing</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Total Impressions</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Final Sheets</small>
                            </div>
                            {/* <div className="text-muted fs-15 fw-semibold">
                              <small>Comments</small>
                            </div> */}
                          </Col>
                          <Col lg={6} className="lh-lg">
                            <div className="fw-semibold fs-15">
                              <small> {location.state?.item.machine === null
                                  ? ''
                                  : location.state?.item.machine}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small> {location.state?.item.colors === null
                                  ? 0
                                  : location.state?.item.colors}</small>
                            </div>
                            
                            <div className="fw-semibold fs-15">
                              <small> {location.state?.item.orId.printing === null
                                  ? ""
                                  : location.state?.item.orId.printing}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                            <small>{location.state?.item?.orId.cuttingQuantity}</small>
                            </div>
                            <div >
                              <input style={{width : '100px'}} type = 'text'></input>
                            </div>
                           
                          </Col>
                        </div>
                      </div>
                    </div>
                  <div className="col-lg-6">
                  <h6 className="fw-bolder mt-2">Lamination Details:</h6>
                  <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                             <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Type of Lamination</small>
                            </div>
                            <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Size</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                            
                          </Col>
                          <Col lg={6} className="lh-lg">
                            
                            <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.orId.lamination === ""
                                  ? ''
                                  : location.state?.item.orId.lamination}</small>
                            </div>
                            <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item?.lSheet}</small>
                            </div>
                            
                           
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.lQuantity}</small>
                            </div>
                            
                          </Col>
                        </div>
                      </div>
                  </div>
                  </div>
                <div className="row border-dark border-bottom">
                  <div className="col-lg-6 border-dark border-end">
                    <h6 className="fw-bolder mt-2">Pasting Details:</h6>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Pasting Type</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                            
                          </Col>
                          <Col lg={6} className="lh-lg">
                             <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.pastingType === ""
                                  ? ''
                                  : location.state?.item.pastingType}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.pastingQuantity}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  <div className="col-lg-6">
                  <h6 className="fw-bolder mt-2">Punching Details:</h6>
                  <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Punching</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                           
                          </Col>
                          <Col lg={6} className="lh-lg">
                            <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.pType === ""
                                  ? ''
                                  : location.state?.item.pType}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.pQuantity}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                  </div>
                  </div>
                <div className="row border-dark border-bottom">
                  <div className="col-lg-6 border-dark border-end">
                    <h6 className="fw-bolder mt-2">Finishing Details:</h6>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Finishing Type</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                            
                          </Col>
                          <Col lg={6} className="lh-lg">
                            <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.finishingType === ""
                                  ? ''
                                  : location.state?.item.finishingType}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.finishingQuantity}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  <div className="col-lg-6">
                  <h6 className="fw-bolder mt-2">Transport Details:</h6>
                  <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            {/* <div className='text-muted fs-15 fw-semibold'><small>Media Type</small></div> */}
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Transport Name</small>
                            </div>
                          </Col>
                          <Col lg={6} className="lh-lg">
                            {/* <div className='fw-semibold fs-15'><small>Sticker</small></div> */}
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item.transName === ""
                                  ? ''
                                  : location.state?.item.transName}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                  </div>
                  </div>










                  {/* <div className=" col-lg-6 col-md-4 col-12 ">
                    <div className="row"> */}
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Plate Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={4} className="">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Color Proof</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Plate By</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>No. of Colours</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>No. of Sets</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Plate Vendor</small>
                            </div>
                          </Col>
                          <Col lg={8}>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.colorProof}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.agAou}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.colors}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.noOfSets}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.plateBy}</small>
                            </div>
                          </Col>
                        </div>
                      </div> */}
                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Paper Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Paper with Gsm</small>
                            </div> */}
                            {/* <div className='text-muted fs-15 fw-semibold'><small>GSM</small></div> */}
                            {/* <div className="text-muted fs-15 fw-semibold">
                              <small>Mill Size</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Mill Quantity</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Cutting Size</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Cutting Qty</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Paper Vendor</small>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.paperGsm} GSM</small>
                            </div> */}
                            {/* <div className='fw-semibold fs-15'><small>90</small></div> */}
                            {/* <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.orId.estimateId.millerBreadth + " * " + location.state?.item?.orId.estimateId.millerLength}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperQuantity}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperCuttingSize}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperCuttingQty}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.paperVendor}
                              </small>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Printing Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Mode of Printing</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Print Colors</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Printing</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Total Impressions</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Final Sheets</small>
                            </div>
                           
                          </Col>
                          <Col lg={6} className="lh-lg">
                            <div className="fw-semibold fs-15">
                              <small> {location.state?.item.machine === null
                                  ? '______'
                                  : location.state?.item.machine}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small> {location.state?.item.colors === null
                                  ? 0
                                  : location.state?.item.colors}</small>
                            </div>
                            
                            <div className="fw-semibold fs-15">
                              <small> {location.state?.item.orId.printing === null
                                  ? "_____"
                                  : location.state?.item.orId.printing}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                            <small>{location.state?.item?.orId.cuttingQuantity}</small>
                            </div>
                            <div >
                              <input style={{width : '100px'}} type = 'text'></input>
                            </div>
                           
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                    </div>
                  </div> */}

                  {/* Second Column */}
                  {/* <div className=" col-lg-6 col-md-4 col-12">
                    <div className="row"> */}
                      {/* <div className="col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Post printinng Strip</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="">
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Order ID</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Job ID</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Firm Name</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Job Name</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Job Date</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Phone</small>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="fw-semibold text-right fs-15">
                              <small>{location.state?.item?.orderId}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.jobId}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.customerName}
                              </small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.jobName}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.date}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item?.orId.customerId.phoneNo}
                              </small>
                            </div>
                          </Col>
                        </div>
                      </div> */}
                 

                      {/* <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Lamination Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className=""> */}
                            {/* <div className='text-muted fs-15 fw-semibold'><small>Media Type</small></div> */}
                            {/* <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Type of Lamination</small>
                            </div>
                            <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Size</small>
                            </div> */}
                            {/* <div className="text-muted fs-15 fw-semibold">
                              <small>Length</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Breadth</small>
                            </div> */}
                            {/* <div className="text-muted fs-15 fw-semibold">
                              <small>SQ.in/Sheet</small>
                            </div> */}
                            {/* <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                            
                          </Col>
                          <Col lg={6} className=""> */}
                            {/* <div className='fw-semibold fs-15'><small>Sticker</small></div> */}
                            
                            {/* <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.orId.lamination === ""
                                  ? '_______'
                                  : location.state?.item.orId.lamination}</small>
                            </div>
                            <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item?.lSheet}</small>
                            </div> */}
                            {/* <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.lLength}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.lBreadth}</small>
                            </div> */}
                            {/* <div className="fw-semibold fs-15">
                              <small>
                                {location.state?.item.lSheet === null
                                  ? 0
                                  : location.state?.item.lSheet}
                              </small>
                            </div> */}
                            {/* <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.lQuantity}</small>
                            </div>
                            
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Pasting Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className=""> */}
                            {/* <div className='text-muted fs-15 fw-semibold'><small>Media Type</small></div> */}
                            {/* <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Pasting Type</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div> */}
                            
                          {/* </Col>
                          <Col lg={6} className=""> */}
                            {/* <div className='fw-semibold fs-15'><small>Sticker</small></div> */}
                            {/* <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.pastingType === ""
                                  ? '_______'
                                  : location.state?.item.pastingType}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.pastingQuantity}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Punching Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className=""> */}
                            {/* <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Punching</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                           
                          </Col>
                          <Col lg={6} className="">
                            <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.pType === ""
                                  ? '_______'
                                  : location.state?.item.pType}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.pQuantity}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Finishing Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className=""> */}
                            {/* <div className='text-muted fs-15 fw-semibold'><small>Media Type</small></div> */}
                            {/* <div className="text-muted mb-1 fs-15 fw-semibold">
                              <small>Finishing Type</small>
                            </div>
                            <div className="text-muted fs-15 fw-semibold">
                              <small>Quantity</small>
                            </div>
                            
                          </Col>
                          <Col lg={6} className=""> */}
                            {/* <div className='fw-semibold fs-15'><small>Sticker</small></div> */}
                            {/* <div className="fw-semibold mb-1 fs-15">
                              <small>{location.state?.item.finishingType === ""
                                  ? '_______'
                                  : location.state?.item.finishingType}</small>
                            </div>
                            <div className="fw-semibold fs-15">
                              <small>{location.state?.item?.finishingQuantity}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                      <div className="form-group col-lg-12 col-md-12 col-12">
                        <h6 className="mx-3 boxSizing">
                          <small>Transport Details</small>
                        </h6>
                      </div>
                      <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <Col lg={6} className="lh-lg"> */}
                            {/* <div className='text-muted fs-15 fw-semibold'><small>Media Type</small></div> */}
                            {/* <div className="text-muted fs-15 fw-semibold">
                              <small>Transport Name</small>
                            </div>
                          </Col>
                          <Col lg={6} className="lh-lg"> */}
                            {/* <div className='fw-semibold fs-15'><small>Sticker</small></div> */}
                            {/* <div className="fw-semibold fs-15">
                              <small>{location.state?.item.transName === ""
                                  ? '_______'
                                  : location.state?.item.transName}</small>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <hr className="text-muted mt-3"></hr>
                    </div>
                  </div> */}
              </CardContent>

              <ModalSubmit
                as={NavLink}
                to="/jobcards"
                submitOnclick={() => savepdf()}
                submitText={"Print JobCard"}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    userdata: state.userdetails,
  };
};
export default connect(mapStateToProps)(JobIDPage);
