import httpService from "./httpService";


export async function getjobcards(obj) {
	
	const data = await httpService.post('/estimate/jobsGetAll',obj);
	return data;
}
export async function addjobcards(obj) {
	
	const data = await httpService.post('/estimate/addjob',obj);
	return data;
}
export async function addindent(obj) {
	
	const data = await httpService.post('/materialIndents/add',obj);
	return data;
}
export async function updatejobcards(obj) {
	
	const data = await httpService.post('/estimate/updatejob',obj);
	return data;
}
export async function updateindent(obj) {
	
	const data = await httpService.post('/materialIndents/update',obj);
	return data;
}
export async function getordersById(id) {
	
	const data = await httpService.get(`/estimate/getclientOrders/${id}`);
	return data;
}

export default {
	getjobcards,
	addjobcards,
	getordersById,
	addindent,
	updatejobcards,
	updateindent
}