import httpService from "./httpService";



export async function getPrintJobType(obj) {
	
	const data = await httpService.post('/masterData/printJobTypes/getAll',obj);
	return data;
}
export async function addPrintJobType(obj) {
	
	const data = await httpService.post('/masterData/printJobTypes/add',obj);
	return data;
}
export async function getPaperType(obj) {
	
	const data = await httpService.post('/masterData/paperTypes/getAll',obj);
	return data;
}
export async function addpapertype(obj) {
	
	const data = await httpService.post('/masterData/paperTypes/add',obj);
	return data;
}
export async function getMachines(obj) {
	
	const data = await httpService.post('/masterData/machines/getAll',obj);
	return data;
}
export async function addmachine(obj) {
	
	const data = await httpService.post('/masterData/machines/add',obj);
	return data;
}
export async function getPriceLists(obj) {
	
	const data = await httpService.post('/masterData/priceLists/getAll',obj);
	return data;
}
export async function addpricelist(obj) {
	
	const data = await httpService.post('/masterData/priceLists/add',obj);
	return data;
}

export async function getExtraJobs(obj) {
	
	const data = await httpService.post('/masterData/extraJobs/getAll',obj);
	return data;
}
export async function addExtraJobs(obj) {
	
	const data = await httpService.post('/masterData/extraJobs/add',obj);
	return data;
}
export async function getfinishsize(obj) {

	const data = await httpService.post('/newMaster/finishSize/getAll',obj);
	return data;
}
export async function addfinishsize(obj) {

	const data = await httpService.post('/newMaster/finishSize/add',obj);
	return data;
}
export async function getmillsizes(obj) {
	const data = await httpService.post('/newMaster/millisize/getAll',obj);
	return data;
}
export async function gettransport(obj) {
	const data = await httpService.post('/newMaster/tranport/getAll',obj);
	return data;
}
export async function addmillisize(obj) {
	const data = await httpService.post('/newMaster/millisize/add',obj);
	return data;
}
export async function addordertypes(obj) {
	const data = await httpService.post('/newMaster/orderType/add',obj);
	return data;
}
export async function addpapergsm(obj) {
	const data = await httpService.post('/newMaster/papergsm/add',obj);
	return data;
}
export async function addpunching(obj) {
	const data = await httpService.post('/newMaster/punching/add',obj);
	return data;
}
export async function addpasting(obj) {
	const data = await httpService.post('/newMaster/pasting/add',obj);
	return data;
}
export async function addcutting(obj) {
	const data = await httpService.post('/newMaster/cuttingsize/add',obj);
	return data;
}
export async function addtransport(obj) {
	const data = await httpService.post('/newMaster/tranport/add',obj);
	return data;
}
export async function updatemillisize(obj) {
	const data = await httpService.post('/newMaster/millisize/update', obj);
	return data;
}
export async function updatefinishsize(obj) {
	const data = await httpService.post('/newMaster/finishSize/update', obj);
	return data;
}
export async function updateordertype(obj) {
	const data = await httpService.post('/newMaster/orderType/update', obj);
	return data;
}
export async function updatepapergsm(obj) {
	const data = await httpService.post('/newMaster/papergsm/update', obj);
	return data;
}
export async function updatelamination(obj) {
	const data = await httpService.post('/masterData/laminations/update', obj);
	return data;
}
export async function updateextrajob(obj) {
	const data = await httpService.post('/masterData/extraJobs/update', obj);
	return data;
}
export async function updatepricelist(obj) {
	const data = await httpService.post('/masterData/priceLists/update', obj);
	return data;
}
export async function updatemachine(obj) {
	const data = await httpService.post('/masterData/machines/update', obj);
	return data;
}
export async function updateprintjobtype(obj) {
	const data = await httpService.post('/masterData/printJobTypes/update', obj);
	return data;
}
export async function updatepapertype(obj) {
	const data = await httpService.post('/masterData/paperTypes/updated', obj);
	return data;
}
export async function updatepunching(obj) {
	const data = await httpService.post('/newMaster/punching/update', obj);
	return data;
}
export async function updatepasting(obj) {
	const data = await httpService.post('/newMaster/pasting/update', obj);
	return data;
}
export async function updatecutting(obj) {
	const data = await httpService.post('/newMaster/cuttingSize/update', obj);
	return data;
}
export async function updatetransport(obj) {
	const data = await httpService.post('/newMaster/tranport/update', obj);
	return data;
}
export async function deletecutting(obj) {
	const data = await httpService.post('/newMaster/cuttingsize/delete', obj);
	return data;
}
export async function deleteJobType(obj) {
	const data = await httpService.post('/masterData/printjobtypes/delete', obj);
	return data;
}
export async function deleteLamination(obj) {
	const data = await httpService.post('/masterData/laminations/delete', obj);
	return data;
}
export async function deleteMillSize(obj) {
	const data = await httpService.post('/newMaster/millisize/delete', obj);
	return data;
}
export async function deleteModeOfPrinting(obj) {
	const data = await httpService.post('/masterData/machines/delete', obj);
	return data;
}
export async function deletePaperGsm(obj) {
	const data = await httpService.post('/newMaster/papergsm/delete', obj);
	return data;
}
export async function deletePunching(obj) {
	const data = await httpService.post('/newMaster/punching/delete', obj);
	return data;
}
export async function deletePasting(obj) {
	const data = await httpService.post('/newMaster/pasting/delete', obj);
	return data;
}
export async function deletetransport(obj) {
	const data = await httpService.post('/newMaster/tranport/updateStatus', obj);
	return data;
}
export default {
    getPrintJobType,
    getPaperType,
	getMachines,
	getPriceLists,
	addpricelist,
	getExtraJobs,
	addPrintJobType,
	addpapertype,
	addmachine,
	addExtraJobs,
	getfinishsize,
	getmillsizes,
	addmillisize,	
	addfinishsize,
	addordertypes,
	addpapergsm,
	updatemillisize,
	updatefinishsize,
	updateordertype,
	updatepapergsm,
	updatelamination,
	updateextrajob,
	updatepricelist,
	updatemachine,
	updateprintjobtype,
	updatepapertype,
	addpunching,
	updatepunching,
	addpasting,
	updatepasting,
	addcutting,
	updatecutting,
	deletecutting,
	deleteJobType,
	deleteLamination,
	deleteMillSize,
	deleteModeOfPrinting,
	deletePaperGsm,
	deletePunching,
	deletePasting,
	gettransport,
	addtransport,
	updatetransport,
	deletetransport
}

