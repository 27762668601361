import httpService from "./httpService";


export async function login(obj) {
	
	const data = await httpService.post('/users/login',obj);
    if(data.data.status === 'success'){
       
        localStorage.setItem('logindata',data.data.token)
    }
	return data;
}
export async function getorders(obj) {
	
	const data = await httpService.post('/estimate/orderGetAll',obj);
	return data;
}
// export async function getExcel(obj) {
	
// 	const data = await httpService.post('/orderBookings/jsontoexcel',obj);
// 	return data;
// }
export async function getestimates(obj) {
	
	const data = await httpService.post('estimate/getAll',obj);
	return data;
}
export async function getusers(obj) {
	
	const data = await httpService.post('/users/getAll',obj);
	return data;
}
export async function addusers(obj) {
	
	const data = await httpService.post('/users/register',obj);
	return data;
}
export async function updateuser(obj) {
	
	const data = await httpService.post('/users/update',obj);
	return data;
}
export async function getuserdetails() {
	
	const data = await httpService.get('/users/getUserDetails');
	return data;
}



export default {
	login,
    getorders,
	getestimates,
	getusers,
	getuserdetails,
	addusers,
	updateuser

}

