import React, { useEffect, useState, useRef } from "react";
import UserHeader from '../header/userHeader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../../Styles/JobCards.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import aretsLogo from '../../Images/aretsLogo.png';
import UserSigninImage from '../../Images/UserSigninImage.png'
import estimateService from '../../services/estimateService';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from '../../services/authService';



const NewUserOrderTracking = () => {
    const navigate = useNavigate();
    const[orderId,setorderId]=useState('');
    const[phone,setPhone]=useState('');
    const[order,setOrder]=useState('');
    const [btndis, setBtndis] = useState(false);
    useEffect(() => {
        getOrders();
      }, [])
     
    
    const getOrders = async () => {
        try {
          const dty = await authService.getorders();
          if (dty.data.status === "success") {
            setOrder(dty.data.value);
          }
        } catch (ex) {}
      };
    const trackorder = async  () => {
        console.log(order, 'orderdataa');

    if (!orderId) {
        await setBtndis(false)
        toast.error('Invalid OrderId ', {
          position: toast.POSITION.BOTTOM_LEFT
        });
    //   } else if (!phone) {
    //     await setBtndis(false)
    //     toast.error('Enter Mobile Number', {
    //       position: toast.POSITION.BOTTOM_LEFT
    //     });
      
    //   }else if (order.includes(phone)) {
    //     // The order ID format is incorrect
    //     await setBtndis(false);
    //     toast.error('Invalid Mobile Number', {
    //       position: toast.POSITION.BOTTOM_LEFT
    //     });
      } else { 
      
    try {
        const obj ={
            "orderId":orderId,
            "phone":phone
        }
        const data =await estimateService.getOrdertracking(obj);
        if(data.data.value.length === 0){
            console.log(data.data,'orderssss');
            toast.error('Invalid OrderID or Mobile Number', {
                position: toast.POSITION.BOTTOM_LEFT
              });
                console.log(data.data, 'data.data');
              }
              else {
                navigate('/UserOrderTracking',{state:{item:data.data}});
                toast.success('Order Tracked Successfully', {
                  position: toast.POSITION.BOTTOM_LEFT
                });
              }
        }
        
     catch (ex) {
        console.log(ex.response, "ex.response");
        toast.error(ex.response.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
    }
   
}}
const handlePhone = (event) => {
    const { value } = event.target;
    setPhone(value.replace(/[^0-9.*!,-]/g, ''));
    };


    return (
        <>
            <UserHeader />
            <Container fluid className='containerr'>
                <Row>
                    <Col lg={12} sm={12} className='containerr'>
                        <div>
                            <h1 className='fs-3 mt-5 text-center'>Track your Order</h1>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex'>
                    <Col lg={6} sm={12}>
                        <img className="trackPageImage mx-1 d-none d-md-block" src={UserSigninImage} />
                    </Col>
                    <Col lg={5}>
                        <Card className="mt-4 rounded-4 " style={{ marginTop: '60px' }}>
                            <CardContent>
                                <Col className='align-items-center justify-content-center'>
                                    <div className='d-flex mb-4 mt-3 col-lg-12 col-sm-12 userloginBrand mb-5'>
                                        <div className='Col-lg-6 col-sm-12 justify-content-center d-flex'>
                                        <img
                                            src={aretsLogo}
                                            alt='branding'
                                            className='loginPage-brandImg'
                                            onClick={() => window.location.reload()}
                                        />
                                        <div className='mt-2'>
                                        <h3 style={{ fontFamily: 'Yeseva One', fontWeight:'400', fontSize: '22px' }}>AReTS Graphics
                                            <p style={{ fontFamily: 'Clash Display', fontSize: '16px' }}>Everything in Printing</p>
                                        </h3>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-7 col-sm-4 userloginForm">
                                            <div className="row">
                                                <div className="form-group col-lg-12 col-md-12">
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control text-muted required"
                                                            name="name"
                                                            id='inputcolorr'
                                                            placeholder="Enter Your OrderID*"
                                                            aria-label="name"
                                                            aria-describedby="basic-addon1"
                                                            value={orderId}
                                                            onChange={(e) => setorderId(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-lg-12 col-md-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                    type="text"
                                                                    className="form-control required"
                                                                    name="phone"
                                                                    id='inputcolorr'
                                                                    placeholder="Mobile Number"
                                                                    maxLength={10}
                                                                    aria-label="phone"
                                                                    aria-describedby="basic-addon1"
                                                                    value={phone}
                                                                    onChange={(e) => handlePhone(e)}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                       
                                                       <Button onClick={() => trackorder()} className=' SubmitButton' variant="primary" type="submit" size='md'>
                                                            Track Order
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={11} md={3} sm={6} className='mt-5'>
                                    <h6 style={{ fontSize: '14px' }} className='fw-bold'>Instructions for Tracking Your Order Using a  Code:</h6>
                                    <ol className='fs-10 mx-3 fw-normal p-0'>
                                        <li>Open the QR code scanning app on your smartphone or tablet.</li>
                                        <li>Point your device's camera at the QR code on your order confirmation or shipping label.</li>
                                        <li>Once the QR code is scanned, your device will automatically open the order tracking website or page.</li>
                                        <li>You will be prompted to enter your order number and email address to view the status of your order.</li>
                                        <li>You can also check the status of your order by clicking on the "Track Order" button on the website.</li>
                                        <li>The status of your order will be displayed, including information such as the current location of your package and an estimated delivery date.</li>
                                        <li>You can also receive updates on your order status via email or text message by subscribing to the order tracking service.</li>
                                        <li>Track your order regularly and if there is any issue please contact customer service for assistance.</li>
                                    </ol>
                                </Col>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col lg={1}></Col>
                </Row>
            </Container >
            {/* <Container fluid className='containerr'>
                <Row>
                    <Col lg={10} sm={8} className='containerr'>
                        <div>
                            <h1 className='fs-3 mx-5 mt-5 text-end'>Track your Order</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} sm={11} md={4} className=' vh-100'>
                        <img className="trackPageImage mx-1" src={UserSigninImage} />
                    </Col>
                    <Col className='align-items-center justify-content-center d-flex'>
                        <Col lg={8} sm={12} md={8} xs={12} >
                            <Card className="mt-4 rounded-4" style={{ marginTop: '60px' }}>
                                <div className='center mt-5 d-flex '>
                                    <img
                                        src={aretsLogo}
                                        alt='branding'
                                        className='loginPage-brandImg'
                                        onClick={() => window.location.reload()}
                                    />
                                    <div>
                                        <h3 className='loginPage-title'>AReTS Graphics</h3>
                                        <p className='mx-3 loginPage-subtitle'>Everything In Printing </p>
                                    </div>
                                </div>
                                <div className="form-group col-lg-6 col-md-6 col-12">
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control text-muted required"
                                                name="name"
                                                id='inputcolor'
                                                placeholder="Job Name*"
                                                aria-label="name"
                                                aria-describedby="basic-addon1"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="code"
                                                        className="form-control required"
                                                        id='inputcolor'
                                                        name="code"
                                                        placeholder="+91"
                                                        aria-label="code"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control required"
                                                        name="phone"
                                                        id='inputcolor'
                                                        placeholder="9876543210"
                                                        aria-label="phone"
                                                        aria-describedby="basic-addon1"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Col>
                </Row>
            </Container> */}
        </>
    )
}

export default NewUserOrderTracking;
